package net.gorillagroove.review

import net.gorillagroove.api.ReviewSourceId
import net.gorillagroove.track.*
import net.gorillagroove.user.UserService
import net.gorillagroove.util.GGLog.logCrit
import net.gorillagroove.util.GGLog.logError
import net.gorillagroove.util.findIndex

class ReviewSession(
    var allReviewSources: Map<ReviewSourceId, ReviewSource>,
    var sourcesNeedingReview: MutableList<ReviewSource>,
    var reviewSourceToTrackCount: MutableMap<ReviewSourceId, Int>,
    var visibleTracks: MutableList<Track> = mutableListOf(),
) {
    var activeSource: ReviewSource? = null
        internal set

    fun setNextActiveSource() {
        NowPlayingService.currentTrack?.takeIf { it.inReview }?.let { currentlyPlayingReviewTrack ->
            val reviewSourceId = currentlyPlayingReviewTrack.reviewSourceId!!
            if ((reviewSourceToTrackCount[reviewSourceId] ?: 0) > 0) {
                setActiveSource(reviewSourceId)
                return
            }
        }

        val sourcesByType = sourcesNeedingReview.groupBy { it.sourceType }
        // This is the priority in which we automatically show queues: user -> artist -> youtube
        val sourceToUse = listOf(ReviewSourceType.USER_RECOMMEND, ReviewSourceType.ARTIST, ReviewSourceType.YOUTUBE_CHANNEL).map { sourceType ->
            return@map sourcesByType[sourceType]?.firstOrNull()
        }.filterNotNull().firstOrNull()

        if (sourceToUse != null) {
            setActiveSource(sourceToUse.id)
        } else {
            activeSource = null
        }
    }

    fun setActiveSource(newSourceId: ReviewSourceId) {
        val newActiveSource = allReviewSources.getValue(newSourceId)
        activeSource = newActiveSource

        visibleTracks = ReviewQueueService.getTracksNeedingReviewOnSource(newActiveSource.id).toMutableList()
        reviewSourceToTrackCount[newSourceId] = visibleTracks.size // Just make sure it's up-to-date since we know the real size anyway

        // Though it shouldn't really happen, it's possible that our local state is out of sync because of background things changing tracks.
        // If there ended up not being tracks to review for this source, try the next one
        if (visibleTracks.isEmpty()) {
            setNextActiveSource()
        }
    }

    internal fun playNextAfterReview(tracks: Collection<Track>, wasPlaying: Boolean) {
        val tracksBySource = tracks.groupBy { it.reviewSourceId!! }

        tracksBySource.forEach { (source, tracks) ->
            val newTrackCount = reviewSourceToTrackCount.getValue(source) - tracks.size
            reviewSourceToTrackCount[source] = newTrackCount

            if (newTrackCount == 0) {
                sourcesNeedingReview.removeAll { it.id == source }
            }
        }

        fun removeAndPlayNext() {
            val reviewedIds = tracks.map { it.id }.toSet()
            val index = NowPlayingService.tracks.findIndex { reviewedIds.contains(it.id) }
            if (index != null) {
                NowPlayingService.removeTracksByIndex(listOf(index))
            }
        }

        val activeSourceId = activeSource?.id
        if (activeSourceId != null && tracksBySource.containsKey(activeSourceId)) {
            val tracksOnSource = tracksBySource.getValue(activeSourceId).map { it.id }.toSet()
            visibleTracks.removeAll { tracksOnSource.contains(it.id) }

            if (visibleTracks.isEmpty()) {
                setNextActiveSource()
                if (visibleTracks.isNotEmpty()) {
                    NowPlayingService.setNowPlayingTracks(
                        tracks = visibleTracks,
                        playFromIndex = if (wasPlaying) 0 else null,
                    )
                }
            } else {
                removeAndPlayNext()
            }
        } else {
            removeAndPlayNext()
        }
    }

    fun getSortedVisibleTracks(
        sort: List<TrackSortItem> = listOf(TrackSortItem(TrackColumn.SORT_IDENTIFIER, SortDirection.ASC))
    ): List<Track> {
        return TrackSort.sortTracks(visibleTracks, sort)
    }

    // This method exists for iOS convenience. The map of counts is converted to Swift in a strange manner.
    fun getCountForSource(sourceId: ReviewSourceId): Int {
        return reviewSourceToTrackCount[sourceId] ?: 0
    }

    // If UserA owns a track and it makes its way into your review queue, you need to know HOW it made it there.
    // It used to be that the only way it could get there is if UserA put it there themselves. But now it can get
    // there from UserB recommending it, or you recommending it to yourself. If it ended up in your queue by
    // any other means than UserA adding it, we should put a little disclaimer about its origins.
    fun getReviewOwnershipNote(track: Track): String? {
        // We should already have the source in-memory. So avoid the DB hit for this.
        val reviewSource = allReviewSources[track.reviewSourceId!!] ?: run {
            logCrit("A track is having its ownership note checked without the source being found!")
            ReviewQueueService.findById(track.reviewSourceId) ?: run {
                logError("In more bad news, the source wasn't found on-device at all")
                return null
            }
        }

        // Only UserRecommend sources have the need for this. The others are all added by automatic processes.
        if (reviewSource.sourceType != ReviewSourceType.USER_RECOMMEND) {
            return null
        }

        // All legacy recommended tracks are null here. New ones should have it, though.
        val recommendedBy = track.recommendedBy ?: return null

        // Check ourselves first as that is easy to do.
        if (recommendedBy == UserService.requireCurrentUserId()) {
            return "You recommended this track to yourself for review"
        }

        val sourceUserId = reviewSource.sourceUserId ?: run {
            logCrit("No sourceUserId found on USER_RECOMMEND ReviewSource! ${reviewSource.id.value}")
            return null
        }

        // Now we need to know if the person recommending it is the "owner" of the review source.
        // If they are, do nothing special as that is the expected scenario.
        if (reviewSource.sourceUserId == recommendedBy) {
            return null
        }

        // If we get here, then it means that someone recommended us a Track from somebody else's library.
        // Load their user records so we can display the appropriate names and then do so.
        val recommenderName = UserService.findById(recommendedBy)?.name ?: "Unknown"
        val ownerName = UserService.findById(sourceUserId)?.name ?: "Unknown"

        return "This track was recommended to you by $recommenderName, not $ownerName"
    }
}
