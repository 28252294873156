package net.gorillagroove

import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch

@Suppress("VARIABLE_IN_SINGLETON_WITHOUT_THREAD_LOCAL")
internal object GGCommonInternal {
    var isIntegrationTesting = false

    // This is hella dumb, but I have serious issues with test stability when I save stuff to the
    // indexDb in JS tests everywhere, so I turn it off for tests. But this test is testing to make
    // sure that this actually works, so I turn integration testing off just for this test. I'm lazy.
    // I'm tired of dealing with this. It's been like 5 hours of fuckery. I'm done.
    var integrationTestDatabaseSave = false

    // A lot of our actions invoke handlers when something happens. Like we sync some data,
    // we invoke a handler to let the client know that the data changed. This can cause problems
    // where if we invoke the code synchronously, errors on the client can trip up our own code.
    // In addition, if the client does something heavy, it can delay our own internal actions.
    // So we want to invoke a client-provided callback async. However, if we're integration testing,
    // we DON'T want to do it async as that makes testing far more annoying.
    fun doAsyncIfProduction(handler: () -> Unit) {
        if (isIntegrationTesting) {
            handler()
        } else {
            CoroutineScope(Dispatchers.Main).launch {
                handler()
            }
        }
    }
}
