import net.gorillagroove.track.NowPlayingService

object MediaSessionService {
    private val mediaSession by lazy {
        // mediaSession is not defined on the Kotlin navigator class at this time....
        js("""window.navigator.mediaSession""") as MediaSession
    }

    fun setupMediaSession() {
        mediaSession.setActionHandler("play") { NowPlayingService.resume() }
        mediaSession.setActionHandler("pause") { NowPlayingService.pause() }
        mediaSession.setActionHandler("stop") { NowPlayingService.pause() }
        mediaSession.setActionHandler("previoustrack") { NowPlayingService.playPrevious() }
        mediaSession.setActionHandler("nexttrack") { NowPlayingService.playNext() }
    }

    fun updateMediaSession() {
        val track = NowPlayingService.currentTrack ?: return

        mediaSession.metadata = MediaMetadata(jsObject {
            title = track.name
            artist = track.artist
            album = track.album
            artwork = arrayOf(
                jsObject<Any> {
                    src = "https://gorillagroove.net/assets/unknown-art.jpg"
                }
            )
        })
    }

    // The art stuff doesn't really seem to work, at least on MacOS's media part.
    // It seems to only sort of accept my "unknown-art" URL. Sometimes it does. Sometimes it doesn't.
    // It also doesn't seem to accept blob URLs, though the internet seems to think that it can.
    // I am just pushing it as it is, since at worst it does nothing. But it might work on non-Mac.
    fun updateArt(url: String) {
        val track = NowPlayingService.currentTrack ?: return

        mediaSession.metadata = MediaMetadata(jsObject {
            title = track.name
            artist = track.artist
            album = track.album
            artwork = arrayOf(
                jsObject<Any> {
                    src = url
                }
            )
        })
    }
}

inline fun <T> jsObject(init: dynamic.() -> Unit): T {
    val o = js("{}")
    init(o)
    return o as T
}

external class MediaSession {
    var metadata: MediaMetadata?

    fun setActionHandler(type: String, callback: () -> Unit)
}

external class MediaMetadata(init: MediaMetadataInit) {
    var title: String
    var artist: String
    var album: String
    var artwork: Array<Any>
}

external interface MediaMetadataInit {
    var title: String?
    var artist: String?
    var album: String?
}
