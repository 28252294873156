@file:Suppress("FunctionName")

package components

import Dialog
import Toast
import kotlinx.browser.document
import kotlinx.html.*
import kotlinx.html.dom.append
import kotlinx.html.dom.create
import kotlinx.html.js.div
import kotlinx.html.js.onClickFunction
import net.gorillagroove.api.UserId
import net.gorillagroove.playlist.*
import net.gorillagroove.user.UserService
import net.gorillagroove.util.GGLog
import net.gorillagroove.util.GGLog.logError
import onSubmitSuspend
import org.w3c.dom.*

fun AddPlaylist() = document.create.div {
    id = "add-playlist"

    h3 {
        + "Add Playlist"
    }

    form {
        onSubmitSuspend = onSubmit@ {
            if (isActionButtonUpdating("add-playlist-save")) {
                return@onSubmit
            }

            val input = document.getElementById("add-playlist-name") as HTMLInputElement
            val name = input.value
            if (name.isNotBlank()) {
                try {
                    actionButtonChangeState("add-playlist-save", isUpdating = true)

                    PlaylistService.createPlaylist(name)

                    Toast.success("Created playlist '$name'")

                    LeftNav.updatePlaylists()

                    Dialog.remove()
                } catch (e: Exception) {
                    GGLog.logError("Failed to create playlist!", e)
                    Toast.error("Failed to create playlist")

                    actionButtonChangeState("add-playlist-save", isUpdating = false)
                }
            }
        }

        label {
            // I wanted to have this just say "Name", but if you do that, then 1password will
            // try to autofill it with your own name. I tried several things to get it to not
            // do that, and none of them worked. So I begrudgingly changed this to something else.
            +"Playlist name"

            input(InputType.text, classes = "ml-8") {
                id = "add-playlist-name"

                autoComplete = false
            }
        }

        div("mt-12") {
            ActionButton(id = "add-playlist-save", text = "Save")
        }
    }
}

fun EditPlaylist(playlist: Playlist) = document.create.div {
    id = "edit-playlist"

    val ownPermission = PlaylistService.findPlaylistUser(
        userId = UserService.requireCurrentUserId(),
        playlistId = playlist.id
    )!!.ownershipType
    val playlistUsers = PlaylistService.getUsersOnPlaylist(playlist.id)

    h3 {
        + "Edit '${playlist.name}'"
    }

    label {
        form {
            onSubmitSuspend = onSubmit@ {
                if (isActionButtonUpdating("edit-playlist-save")) {
                    return@onSubmit
                }

                val input = document.getElementById("edit-playlist-name") as HTMLInputElement
                val name = input.value
                if (name.isNotBlank()) {
                    try {
                        actionButtonChangeState("edit-playlist-save", isUpdating = true)

                        PlaylistService.updatePlaylist(playlist.id, name)

                        Toast.success("Updated playlist '$name'")

                        LeftNav.updatePlaylists()

                        Dialog.remove()
                    } catch (e: Exception) {
                        GGLog.logError("Failed to update playlist!", e)
                        Toast.error("Failed to update playlist")

                        actionButtonChangeState("edit-playlist-save", isUpdating = false)
                    }
                }
            }

            label {
                // I wanted to have this just say "Name", but if you do that, then 1password will
                // try to autofill it with your own name. I tried several things to get it to not
                // do that, and none of them worked. So I begrudgingly changed this to something else.
                +"Playlist name"

                input(InputType.text, classes = "ml-8") {
                    id = "edit-playlist-name"

                    autoComplete = false
                }
            }

            div("mt-12 space-between") {
                val deleteText = if (ownPermission == PlaylistOwnershipType.OWNER) "Delete" else "Leave"

                ActionButton(id = "delete-playlist", text = deleteText, type = ButtonType.button, actionType = ActionButtonType.DESTRUCTIVE) { event ->
                    event.stopPropagation()

                    actionButtonChangeState("delete-playlist", isUpdating = true)

                    try {
                        PlaylistService.deletePlaylist(playlist.id)
                    } catch (e: Exception) {
                        GGLog.logError("Failed to delete playlist!", e)
                        Toast.error("Failed to delete playlist")

                        actionButtonChangeState("delete-playlist", isUpdating = false)
                    }

                    if (ownPermission == PlaylistOwnershipType.OWNER) {
                        Toast.success("Playlist deleted")
                    } else {
                        Toast.success("Left playlist")
                    }

                    LeftNav.updatePlaylists()
                    Dialog.remove()
                }

                button(type = ButtonType.button, classes = "flat") {
                    + "Edit Permissions"

                    onClickFunction = {
                        Dialog.remove()
                        Dialog.show(EditPlaylistPermissions(playlist, playlistUsers, ownPermission))
                    }
                }

                ActionButton(id = "edit-playlist-save", text = "Save")
            }
        }
    }
}

private fun EditPlaylistPermissions(
    playlist: Playlist,
    playlistUsers: List<PlaylistUser>,
    ownPermission: PlaylistOwnershipType,
) = document.create.div {
    id = "edit-playlist-permission"

    h3 {
        + "'${playlist.name}' Permissions"
    }

    table("data-table") {
        thead {
            tr {
                th(classes = "full-width") {
                    + "User"
                }
                th {
                    + "Permission"
                }

                if (ownPermission == PlaylistOwnershipType.OWNER) {
                    th { }
                }
            }
        }
        tbody {
            val users = UserService.findByIds(playlistUsers.map { it.userId }).associateBy { it.id }

            playlistUsers.forEach { playlistUser ->
                tr {
                    attributes["user-id"] = playlistUser.userId.value.toString()

                    td {
                        + users.getValue(playlistUser.userId).name
                    }

                    if (ownPermission == PlaylistOwnershipType.OWNER && playlistUser.userId != UserService.requireCurrentUserId()) {
                        td {
                            select("permission-select") {
                                PlaylistOwnershipType.getSelectableTypes().forEach { type ->
                                    option {
                                        value = type.name
                                        +type.displayName

                                        selected = type == playlistUser.ownershipType
                                    }
                                }
                            }
                        }
                    } else {
                        td {
                            +playlistUser.ownershipType.displayName
                        }
                    }
                }
            }
        }
    }

    div("space-between mt-12") {
        button(classes = "flat") {
            id = "add-playlist-user-button"

            +"Add user"

            i("fa-solid fa-plus ml-6")

            onClickFunction = {
                addRow()
            }
        }

        ActionButton("playlist-permission-save", "Save") {
            val table = document.querySelector("#edit-playlist-permission tbody") as HTMLElement

            val params = table.childNodes.asList().mapNotNull { row ->
                row as HTMLElement

                val userSelect = row.querySelector(".user-select") as HTMLSelectElement?

                val userId = if (userSelect != null) {
                    UserId(userSelect.value.toLong())
                } else {
                    UserId(row.attributes["user-id"]!!.value.toLong())
                }

                val permissionSelect = row.querySelector(".permission-select") as HTMLSelectElement?
                    ?:  return@mapNotNull null

                val type = PlaylistOwnershipType.valueOf(permissionSelect.value)

                UserWithPlaylistOwnership(userId, type)
            }

            if (params.isEmpty()) {
                return@ActionButton
            }

            actionButtonChangeState("playlist-permission-save", isUpdating = true)

            try {
                PlaylistService.editUserPermission(playlist.id, params)
            } catch (e: Exception) {
                actionButtonChangeState("playlist-permission-save", isUpdating = false)

                GGLog.logError("Failed to update permissions!", e)
                Toast.error("Failed to update permissions")
                return@ActionButton
            }

            Toast.success("Permissions updated")

            Dialog.remove()
        }
    }
}

private fun addRow() {
    val table = document.querySelector("#edit-playlist-permission tbody") as HTMLElement

    table.append {
        tr {
            td {
                select("user-select") {
                    // TODO need to remove users already on the playlist
                    UserService.findOtherUsers().forEach { user ->
                        option {
                            value = user.id.value.toString()
                            + user.name
                        }
                    }
                }
            }

            td {
                select("permission-select") {
                    PlaylistOwnershipType.getSelectableTypes().forEach { type ->
                        option {
                            value = type.name
                            +type.displayName

                            selected = type == PlaylistOwnershipType.READER
                        }
                    }
                }
            }
        }
    }
}
