package net.gorillagroove.db

import com.squareup.sqldelight.ColumnAdapter
import kotlin.Long
import kotlin.String
import kotlinx.datetime.Instant
import net.gorillagroove.api.ReviewSourceId
import net.gorillagroove.api.UserId
import net.gorillagroove.review.RawReviewSourceType
import net.gorillagroove.sync.RawOfflineAvailabilityType

public data class DbReviewSource(
  public val id: ReviewSourceId,
  public val sourceType: RawReviewSourceType,
  public val displayName: String,
  public val offlineAvailability: RawOfflineAvailabilityType,
  public val updatedAt: Instant,
  public val sourceUserId: UserId?
) {
  public override fun toString(): String = """
  |DbReviewSource [
  |  id: $id
  |  sourceType: $sourceType
  |  displayName: $displayName
  |  offlineAvailability: $offlineAvailability
  |  updatedAt: $updatedAt
  |  sourceUserId: $sourceUserId
  |]
  """.trimMargin()

  public class Adapter(
    public val idAdapter: ColumnAdapter<ReviewSourceId, Long>,
    public val sourceTypeAdapter: ColumnAdapter<RawReviewSourceType, String>,
    public val offlineAvailabilityAdapter: ColumnAdapter<RawOfflineAvailabilityType, String>,
    public val updatedAtAdapter: ColumnAdapter<Instant, Long>,
    public val sourceUserIdAdapter: ColumnAdapter<UserId, Long>
  )
}
