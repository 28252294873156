package net.gorillagroove.user

import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import net.gorillagroove.api.Api
import net.gorillagroove.api.DeviceId
import net.gorillagroove.api.UserId
import net.gorillagroove.db.Database.userDao
import net.gorillagroove.db.many
import net.gorillagroove.hardware.DeviceType
import net.gorillagroove.hardware.DeviceUtil
import net.gorillagroove.hardware.RawDeviceType
import net.gorillagroove.localstorage.CurrentUserStore
import net.gorillagroove.localstorage.NotSignedInException
import net.gorillagroove.track.RemoteNowListeningState
import net.gorillagroove.util.GGLog.logError

object DeviceService {
    fun getCurrentDeviceId(): String {
        return DeviceUtil.getDeviceId()
    }

    @Throws(Throwable::class)
    suspend fun findAll(): GetDeviceResponseData {
        return try {
            val response = Api.get<DeviceGetResponse>("device")

            val allDevices = response
                .items
                .map { it.toDevice() }
                .sortedByDescending { it.lastListenedOn }

            GetDeviceResponseData(
                currentDevice = response.currentDevice.toDevice(),
                allDevices = allDevices,
            )
        } catch (e: Exception) {
            logError("Failed to get devices!", e)
            throw e
        }
    }

    @Throws(Throwable::class)
    suspend fun renameDevice(id: DeviceId, newName: String): Device {
        val request = UpdateDeviceRequest(newName)
        return try {
            Api.put<DeviceResponse>("device/update/${id.value}", request).toDevice()
        } catch (e: Exception) {
            logError("Failed to rename device!", e)
            throw e
        }
    }

    @Throws(Throwable::class)
    suspend fun mergeDevices(id: DeviceId, targetId: DeviceId) {
        val request = MergeDeviceRequest(id = id, targetId = targetId)
        try {
            Api.put<Unit>("device/merge", request)
        } catch (e: Exception) {
            logError("Failed to merge devices!", e)
            throw e
        }
    }

    @Throws(Throwable::class)
    suspend fun archiveDevice(id: DeviceId) {
        // I apparently wrote this endpoint such that you could un-archive something if you wanted to.
        // I've never written a UI that lets you actually do this, and I have no intention to start now.
        // But that is why this parameter of "true" is hardcoded into this request....
        val request = ArchiveDeviceRequest(archived = true)
        try {
            Api.put<Unit>("device/archive/${id.value}", request)
        } catch (e: Exception) {
            logError("Failed to archive device!", e)
            throw e
        }
    }
}

@Serializable
internal data class DeviceGetResponse(val currentDevice: DeviceResponse, val items: List<DeviceResponse>)

@Serializable
internal data class DeviceResponse(
    val id: DeviceId,
    val userId: Long,
    val userName: String,
    val deviceType: RawDeviceType,
    val deviceId: String,
    val deviceName: String,
    val applicationVersion: String,
    val lastIp: String,
    val additionalData: String? = null,
    val partyEnabledUntil: Instant? = null,
    val createdAt: Instant,
    val updatedAt: Instant,
    val lastListenedOn: Instant,
) {
    fun toDevice() = Device(
        id = id,
        userId = userId,
        username = userName,
        deviceType = deviceType.asEnumeratedType(),
        deviceId = deviceId,
        deviceName = deviceName,
        applicationVersion = applicationVersion,
        lastIp = lastIp,
        additionalData = additionalData,
        partyEnabledUntil = partyEnabledUntil,
        createdAt = createdAt,
        updatedAt = updatedAt,
        lastListenedOn = lastListenedOn,
    )
}

data class Device(
    val id: DeviceId,
    val userId: Long,
    val username: String,
    val deviceType: DeviceType,
    val deviceId: String,
    val deviceName: String,
    val applicationVersion: String,
    val lastIp: String,
    val additionalData: String? = null,
    val partyEnabledUntil: Instant? = null,
    val createdAt: Instant,
    val updatedAt: Instant,
    val lastListenedOn: Instant,
)

data class GetDeviceResponseData(
    val currentDevice: Device,
    val allDevices: List<Device>,
)

@Serializable
internal data class UpdateDeviceRequest(val deviceName: String)

@Serializable
internal data class MergeDeviceRequest(
    val id: DeviceId,
    val targetId: DeviceId,
)

@Serializable
internal data class ArchiveDeviceRequest(val archived: Boolean)
