package net.gorillagroove.track

import kotlinx.coroutines.*
import net.gorillagroove.GGCommonInternal
import net.gorillagroove.api.BadRequestException
import net.gorillagroove.authentication.AuthService
import net.gorillagroove.db.Database.trackDao
import net.gorillagroove.db.DbTrack
import net.gorillagroove.db.many
import net.gorillagroove.db.one
import net.gorillagroove.hardware.DeviceUtil
import net.gorillagroove.hardware.PlatformDeviceUtil
import net.gorillagroove.localstorage.LocalStorage
import net.gorillagroove.reporting.DialogEventBus
import net.gorillagroove.reporting.DialogEventData
import net.gorillagroove.sync.OfflineAvailabilityType
import net.gorillagroove.sync.SyncCoordinator
import net.gorillagroove.util.Formatter.toReadableByteString
import net.gorillagroove.util.GGLog.logCrit
import net.gorillagroove.util.GGLog.logDebug
import net.gorillagroove.util.GGLog.logError
import net.gorillagroove.util.GGLog.logInfo
import net.gorillagroove.util.GGLog.logWarn
import net.gorillagroove.util.Lock
import net.gorillagroove.util.SettingType
import net.gorillagroove.util.Settings
import net.gorillagroove.util.use

internal const val STORAGE_WARNING_KEY = "storage-warning"

@Suppress("VARIABLE_IN_SINGLETON_WITHOUT_THREAD_LOCAL")
object OfflineModeService {
    var offlineStorageMode: OfflineStorageMode
        get() {
            val default = if (DeviceUtil.getDeviceType().isMobile) OfflineStorageMode.WIFI else OfflineStorageMode.ALWAYS
            val code = Settings.getInt(SettingType.OFFLINE_STORAGE_MODE, default.storageCode)
            return OfflineStorageMode.findByCode(code)
        }
        set(value) {
            Settings.setInt(SettingType.OFFLINE_STORAGE_MODE, value.storageCode)
        }

    private val coroutineScope = CoroutineScope(Dispatchers.Default)
    private var downloadJob: Job? = null
    internal var purgeJob: Job? = null

    var offlineModeEnabled
        get() = Settings.getBoolean(SettingType.OFFLINE_MODE_ENABLED, false)
        set(value) {
            Settings.setBoolean(SettingType.OFFLINE_MODE_ENABLED, value)
            if (value) {
                NowPlayingService.removeUncachedTracks()
            } else {
                if (!GGCommonInternal.isIntegrationTesting) {
                    SyncCoordinator.syncAsync()
                }
            }

            broadcastOfflineModeChange(value)
        }

    var automaticOfflineModeOnPoorConnection
        get() = Settings.getBoolean(SettingType.AUTOMATIC_OFFLINE_MODE, false)
        set(value) {
            Settings.setBoolean(SettingType.AUTOMATIC_OFFLINE_MODE, value)
        }

    var offlineStorageEnabled: Boolean
        get() = Settings.getBoolean(SettingType.OFFLINE_STORAGE_ENABLED, true)
        set(enabled) {
            Settings.setBoolean(SettingType.OFFLINE_STORAGE_ENABLED, enabled)
            if (!enabled) {
                downloadJob?.cancel()
                purgeJob = coroutineScope.launch {
                    downloadJob?.join()
                    lock.use { downloadJob = null }
                    cleanUpCachedTracks()
                }
            }
        }

    var allowedStorageSetting: Long
        get() = Settings.getLong(SettingType.MAXIMUM_OFFLINE_STORAGE_BYTES, 5_000_000_000L)
        set(value) {
            Settings.setLong(SettingType.MAXIMUM_OFFLINE_STORAGE_BYTES, value)
            LocalStorage.writeBoolean(STORAGE_WARNING_KEY, false)

            broadcastTrackCacheChange()

            broadcastStorageWarningIfNeeded()
        }

    private val allowedStorageBytes: Long get() {
        return if (offlineStorageEnabled) {
            allowedStorageSetting
        } else {
            0L
        }
    }

    var checkWiFiConnection: () -> Boolean = {
        logError("WiFi connectivity has not been overridden by the host program. " +
                "Override OfflineModeService.checkWifiConnection() to customize this or suppress this error. " +
                "Defaulting to WiFi being found.")
        true
    }

    fun getTotalCachedBytes(): Long {
        return trackDao.getCachedTrackSizeBytes(
            audioCacheFormat = PlatformDeviceUtil.getDefaultAudioFormat().name,
            offlineAvailabilityType = null
        ).one().bytes ?: 0
    }

    fun downloadAlwaysOfflineTracks(): Job {
        lock.use {
            if (downloadJob?.isCompleted == true || downloadJob?.isCancelled == true) {
                downloadJob = null
            }

            if (downloadJob != null) {
                logInfo("Offline download already in progress. Not starting another")
                return downloadJob!!
            }

            downloadJob = downloadAlwaysOfflineTracksInternal()
            return downloadJob!!
        }
    }

    private fun downloadAlwaysOfflineTracksInternal() = coroutineScope.launch {
        if (!offlineStorageEnabled || offlineStorageMode == OfflineStorageMode.NEVER || offlineModeEnabled) {
            if (!offlineStorageEnabled) {
                this@OfflineModeService.logDebug("Offline storage was not enabled. Not downloading 'AVAILABLE_OFFLINE' tracks")
            }
            if (offlineModeEnabled) {
                this@OfflineModeService.logDebug("Offline mode is enabled. Not downloading 'AVAILABLE_OFFLINE' tracks")
            }
            if (offlineStorageMode == OfflineStorageMode.NEVER) {
                this@OfflineModeService.logDebug("Offline storage mode is set to 'NEVER'. Not downloading 'AVAILABLE_OFFLINE' tracks")
            }

            cleanUpCachedTracks()
            return@launch
        }

        if (offlineStorageMode == OfflineStorageMode.WIFI && !checkWiFiConnection()) {
            this@OfflineModeService.logDebug("Offline storage mode is set to 'WIFI' and there is no WiFi connection. Not downloading 'AVAILABLE_OFFLINE' tracks")
            cleanUpCachedTracks()
            return@launch
        }

        this@OfflineModeService.logDebug("Downloading 'AVAILABLE_OFFLINE' tracks if needed")

        val tracksNeedingCache = trackDao.getTracksNeedingCached().many()
        val totalRequiredBytes = trackDao.getTotalBytesRequiredForFullCache(PlatformDeviceUtil.getDefaultAudioFormat().name).one().bytes ?: 0

        var byteLimit = Long.MAX_VALUE
        if (allowedStorageBytes < totalRequiredBytes) {
            this@OfflineModeService.logWarn("Insufficient storage was allocated to store all 'AVAILABLE_OFFLINE' tracks. Allowed storage: $allowedStorageBytes. Required storage: $totalRequiredBytes")
            broadcastStorageWarning(allowedStorageBytes, totalRequiredBytes)

            // If we don't have enough room to download everything, figure out how many bytes we've used on ALWAYS_AVAILABLE music and fill in the gaps
            val existingAlwaysAvailableBytes = trackDao.getCachedTrackSizeBytes(
                audioCacheFormat = PlatformDeviceUtil.getDefaultAudioFormat().name,
                offlineAvailabilityType = OfflineAvailabilityType.AVAILABLE_OFFLINE.toRawType()
            ).one().bytes ?: 0
            byteLimit = allowedStorageBytes - existingAlwaysAvailableBytes
        }

        // If we don't have enough storage space to download all AVAILABLE_OFFLINE music, only take them until we run out of space
        val tracksToFetch = tracksNeedingCache.filter { track ->
            val canDownload = track.bytesNeedingDownload < byteLimit
            if (canDownload) {
                byteLimit -= track.bytesNeedingDownload
            }
            canDownload
        }

        tracksToFetch.forEach {
            downloadTrack(it)
            yield()
        }

        cleanUpCachedTracks()
    }

    private fun broadcastStorageWarningIfNeeded() {
        val totalRequiredBytes = trackDao.getTotalBytesRequiredForFullCache(PlatformDeviceUtil.getDefaultAudioFormat().name).one().bytes ?: 0
        if (totalRequiredBytes > allowedStorageBytes) {
            broadcastStorageWarning(allowedStorageBytes, totalRequiredBytes)
        }
    }

    fun getCachedBytes(offlineAvailabilityType: OfflineAvailabilityType? = null): Long {
        return trackDao.getCachedTrackSizeBytes(
            audioCacheFormat = PlatformDeviceUtil.getDefaultAudioFormat().name,
            offlineAvailabilityType = offlineAvailabilityType?.toRawType()
        ).one().bytes ?: 0
    }

    // Just so there is no spam potential if they're tweaking storage. Not saved anywhere outside of memory
    private var alertDialogBroadcastThisSession = false

    private fun broadcastStorageWarning(allowedStorage: Long, totalRequiredBytes: Long) {
        if (LocalStorage.readBoolean(STORAGE_WARNING_KEY, false) || alertDialogBroadcastThisSession) {
            logDebug("Storage warning was already seen. Not broadcasting it")
            return
        }

        LocalStorage.writeBoolean(STORAGE_WARNING_KEY, true)
        alertDialogBroadcastThisSession = true

        val dialogEvent = DialogEventData(
            title = "Insufficient Storage Configured",
            message = "You have ${allowedStorage.toReadableByteString()} storage allocated for offline music, but have ${totalRequiredBytes.toReadableByteString()} of music marked 'Available Offline'. " +
                    "Not all of your music will be able to be downloaded.\n\nYou can change your storage from Settings, or select 'Quick fix' to increase your storage limit to {${totalRequiredBytes.toReadableByteString()}}",
            yesText = "Quick fix",
            noText = "Do nothing",
            yesAction = {
                logInfo("User opted to quick fix their storage setting")
                allowedStorageSetting = totalRequiredBytes
            },
            noAction = {
                logInfo("User ignored cache limit being exceeded")
            }
        )

        DialogEventBus.broadcast(dialogEvent)
    }

    internal suspend fun cleanUpCachedTracks() {
        logDebug("Checking if tracks need to be purged from cache")
        val allowedStorage = allowedStorageBytes

        val usedStorage = getTotalCachedBytes()

        // No need to clean anything up if we aren't over our cap
        if (allowedStorage > usedStorage) {
            logDebug("No track purge necessary")
            return
        }

        var bytesToPurge = usedStorage - allowedStorage

        logInfo("Cache is over-full! Need to purge ${bytesToPurge.toReadableByteString()}")

        // We are over our cap. First purge tracks that are not marked "AVAILABLE_OFFLINE"
        // They are ordered by least recency. So we can iterate and purge until we have purged enough.
        bytesToPurge = purgeCache(bytesToPurge, OfflineAvailabilityType.NORMAL)

        broadcastTrackCacheChange()

        if (bytesToPurge < 0) {
            logInfo("Cache has been reigned in")
            return
        }

        // We are now in a situation where the user has not given us enough storage to store everything marked "AVAILABLE_OFFLINE".
        // We cannot honor these tracks and the storage setting, and must purge the tracks.
        purgeCache(bytesToPurge, OfflineAvailabilityType.AVAILABLE_OFFLINE)

        broadcastTrackCacheChange()

        logInfo("Cache has been reigned in")
    }

    private suspend fun purgeCache(
        startingBytesToPurge: Long,
        offlineAvailabilityType: OfflineAvailabilityType
    ): Long {
        var bytesToPurge = startingBytesToPurge

        val cachedTracks = trackDao.getCachedTrackByOfflineTypeSortedByOldestStarted(offlineAvailabilityType.toRawType()).many()
        cachedTracks.forEach { cachedTrack ->
            if (bytesToPurge > 0) {
                logDebug("Purging cache for $offlineAvailabilityType track: ${cachedTrack.id.value}")

                TrackCacheService.deleteAllCacheOnDisk(cachedTrack.id)
                val bytesRemoved = cachedTrack.totalCachedBytes

                cachedTrack.copy(
                    audioCachedAt = null,
                    artCachedAt = null,
                    thumbnailCachedAt = null,
                ).also { trackDao.upsert(it) }

                bytesToPurge -= bytesRemoved
            }
        }

        return bytesToPurge
    }

    private suspend fun downloadTrack(track: DbTrack) {
        logInfo("Starting download of '${OfflineAvailabilityType.AVAILABLE_OFFLINE}' track with ID: ${track.id.value}")
        val id = track.apiId ?: run {
            logCrit("Tried to download a track with no API ID!")
            return
        }

        val trackLinks = try {
            TrackService.getTrackLinksLive(id)
        } catch (e: Exception) {
            if (e is CancellationException) {
                throw e
            }

            logError("Failed to get track links for offline download!", e)

            // This shouldn't be possible anymore, but I had some bad data on my account from when I was not properly
            // cleaning up Tracks on multi-user playlists after the playlist was removed. But this seems like fairly
            // safe code to just leave around in case another bug comes up later.
            if (e is BadRequestException && e.response.status == 404 && !track.isOwnTrack()) {
                logInfo("Offline track download error was from a 404 for a Track that is not ours. Deleting it")
                trackDao.delete(track.id)
            }
            return
        }

        if (!AuthService.isAuthenticated()) {
            logWarn("No longer authenticated after downloading track data. Not saving it")
            return
        }

        var bytesChanged = TrackCacheService.cacheTrack(track.id, trackLinks.audioLink!!, trackLinks.audioLinkType)

        trackLinks.albumArtLinkPng?.let { artLink ->
            bytesChanged += TrackCacheService.cacheTrack(track.id, artLink, TrackLinkType.ART_PNG)
        }
        trackLinks.thumbnailArtLinkPng?.let { artLink ->
            bytesChanged += TrackCacheService.cacheTrack(track.id, artLink, TrackLinkType.THUMBNAIL_PNG)
        }

        broadcastTrackCacheChange()
        logInfo("Finished download of '${OfflineAvailabilityType.AVAILABLE_OFFLINE}' track with ID: ${track.id.value}")
    }

    fun abortDownload() {
        downloadJob?.cancel()
    }

    private val cacheEventHandlers = mutableMapOf<Int, TrackCacheEventHandler>()
    private val offlineModeHandlers = mutableMapOf<Int, OfflineModeHandler>()
    private var handlerId: Int = 0
    private val lock: Lock = Lock()

    fun registerCacheEventHandler(handler: TrackCacheEventHandler): Int = lock.use {
        val id = ++handlerId
        cacheEventHandlers[id] = handler
        return id
    }

    fun registerOfflineModeHandler(handler: OfflineModeHandler): Int = lock.use {
        val id = ++handlerId
        offlineModeHandlers[id] = handler
        return id
    }

    fun unregisterCacheEventHandler(handlerId: Int) = lock.use {
        cacheEventHandlers.remove(handlerId)
    }
    fun unregisterOfflineModeHandler(handlerId: Int) = lock.use {
        offlineModeHandlers.remove(handlerId)
    }

    private fun broadcastTrackCacheChange() = lock.use {
        cacheEventHandlers.values.forEach { it(TrackCacheChangeEvent) }
    }
    private fun broadcastOfflineModeChange(enabled: Boolean) = lock.use {
        offlineModeHandlers.values.forEach { it(enabled) }
    }
}

enum class OfflineStorageMode(val displayName: String, val storageCode: Int) {
    ALWAYS("Always", 0),
    WIFI("On Wi-Fi", 1),
    NEVER("Never", 2);

    companion object {
        fun findByCode(code: Int): OfflineStorageMode {
            return entries.find { it.storageCode == code }
                ?: throw IllegalArgumentException("No OfflineStorageMode found for code $code!")
        }

        fun findByDisplayName(displayName: String): OfflineStorageMode {
            return entries.find { it.displayName == displayName }
                ?: throw IllegalArgumentException("No OfflineStorageMode found for displayName $displayName!")
        }
    }
}

typealias TrackCacheEventHandler = (TrackCacheChangeEvent) -> Unit
typealias OfflineModeHandler = (Boolean) -> Unit

// Just in case I feel like adding properties to this later, I'm going to broadcast it.
object TrackCacheChangeEvent
