package net.gorillagroove.track

import kotlinx.coroutines.*
import net.gorillagroove.GGCommonInternal
import net.gorillagroove.api.*
import net.gorillagroove.api.NowListeningRequest
import net.gorillagroove.authentication.AuthService
import net.gorillagroove.sync.SyncCoordinator
import net.gorillagroove.util.CoroutineUtil.CancelledJob
import net.gorillagroove.util.GGLog.logDebug
import net.gorillagroove.track.NowPlayingEventType.*
import net.gorillagroove.user.UserService
import net.gorillagroove.util.*
import net.gorillagroove.util.GGLog.logError
import net.gorillagroove.util.GGLog.logInfo
import net.gorillagroove.util.Lock
import net.gorillagroove.util.SettingType
import net.gorillagroove.util.TimeUtil.now
import net.gorillagroove.util.Timer
import net.gorillagroove.util.use
import kotlin.time.Duration.Companion.milliseconds
import kotlin.time.Duration.Companion.minutes
import kotlin.time.Duration.Companion.seconds

object NowListeningService {
    private val lock: Lock = Lock()

    private val internalNowListeningData = mutableMapOf<UserId, MutableMap<DeviceId, NowListeningSocketResponse>>()
    val nowListeningData get() = lock.use { internalNowListeningData.toMap() }

    private var emitNowPlayingNeeded = false
    internal fun setNowPlayingNeeded() = lock.use {
        emitNowPlayingNeeded = true
        attemptSendListenState()
    }

    // Performs a keep-alive on the socket, basically
    private val playEventTimer = Timer(period = 30.seconds) {
        if (NowPlayingService.isPlaying) {
            val timeSinceLastTimeAdjustment = now() - NowPlayingService.lastTimeAdjustment
            if (timeSinceLastTimeAdjustment.inWholeSeconds > 20) {
                logError("The playback state appears to have become desynced. No time update recorded in $timeSinceLastTimeAdjustment. Pausing playback")
                NowPlayingService.pause()
            } else {
                attemptSendListenState()
                PlaySessionService.saveLocalPlaybackStateAsync()
            }
        }
    }

    // Socket stop-playing messages can get missed sometimes if like, a laptop lid is closed.
    // For some reason a proper socket disconnect message isn't triggered. Probably a bug on my end.
    // But periodically removing stale stuff isn't really a bad idea anyway.
    @Suppress("unused")
    val staleDataPruningTimer = Timer(period = 2.minutes) {
        val refreshNeeded = pruneStaleListeningState()
        if (refreshNeeded) {
            ApiSocket.broadcastNowPlayingRefresh()
        }
    }

    internal fun registerForNowPlayingChanges() {
        NowPlayingService.registerEventHandler { event ->
            when (event.type) {
                ALL_TRACKS_CHANGED, TRACKS_REMOVED, TRACKS_ADDED_LAST, TRACKS_ADDED_NEXT, TRACK_ORDER_SHUFFLED -> {
                    lock.use {
                        emitNowPlayingNeeded = true
                    }
                }
                else -> {}
            }
            when (event.type) {
                PLAYBACK_STARTED, PLAYBACK_RESUMED, CURRENT_TRACK_CHANGED, ALL_TRACKS_CHANGED -> {
                    // We often send multiple events when one "thing" happens.
                    // Debounce it so we don't send an unnecessary amount of network traffic.
                    debounce(this, 250.milliseconds) {
                        attemptSendListenState()
                        PlaySessionService.saveLocalPlaybackStateAsync()
                    }
                    playEventTimer.start()
                }
                PLAYBACK_STOPPED, PLAYBACK_PAUSED -> {
                    playEventTimer.stop()

                    // For resuming playback on another device, we ideally want the latest information before we disconnect.
                    PlaySessionService.saveLocalPlaybackStateAsync()
                    sendCurrentListenState()

                    CoroutineScope(Dispatchers.Default).launch {
                        delay(1.seconds)

                        if (!ApiSocket.keepSocketAlive) {
                            ApiSocket.disconnect()
                        } else {
                            attemptSendListenState()
                        }
                    }
                }
                else -> {}
            }
        }
    }

    private fun attemptSendListenState() {
        if (OfflineModeService.offlineModeEnabled || GGCommonInternal.isIntegrationTesting || !AuthService.isAuthenticated()) {
            return
        }

        logDebug("Attempting to send listen data...")

        if (ApiSocket.isActive && ApiSocket.isConnected) {
            sendCurrentListenState()
        } else {
            ApiSocket.connect { success ->
                if (success) {
                    sendCurrentListenState()
                }
            }
        }
    }

    internal fun processNowListeningResponse(response: NowListeningSocketResponse) = lock.use {
        val userDevices = internalNowListeningData.getOrPut(response.userId) { mutableMapOf() }
        userDevices[response.deviceId] = response
    }

    internal fun sendCurrentListenState(): Job {
        if (!ApiSocket.isActive || !ApiSocket.isConnected) {
            logDebug("Did not send current listen state as socket is not active")
            return CancelledJob()
        }

        val nowPlayingTrackIds = lock.use {
            if (emitNowPlayingNeeded) {
                emitNowPlayingNeeded = false
                NowPlayingService.currentNowPlayingTracks.mapNotNull { it.track.apiId }
            } else {
                null
            }
        }

        logDebug("About to send the listen state")
        val request = NowListeningRequest(
            currentTime = NowPlayingService.currentTime,
            trackId = NowPlayingService.currentTrack?.apiId,
            trackIndex = NowPlayingService.currentIndex,
            isShuffling = NowPlayingService.isShuffling,
            isRepeating = NowPlayingService.isRepeating,
            isPlaying = NowPlayingService.isPlaying,
            nowPlayingTrackIds = nowPlayingTrackIds,
            lastPlayback = NowPlayingService.getLastPlayedTimestamp().takeIf { it > 0 },
            clientTime = now().toEpochMilliseconds(),
            playbackResumeCandidate = PlaySessionService.playbackResumeFromDevice,
        )
        return ApiSocket.sendMessage(request).also { job ->
            // If we failed to send this message, and that message was necessary to update trackIds,
            // then restore the flag so that way we send it again.
            job.invokeOnCompletion { exception ->
                if (exception != null && nowPlayingTrackIds != null) {
                    logInfo("Restoring emitNowPlayingNeeded flag")
                    lock.use {
                        emitNowPlayingNeeded = true
                    }
                }
            }
        }
    }

    fun getListeningStateForUser(userId: UserId): NowListeningSocketResponse? {
        val state = nowListeningData[userId]
        // It is technically possible for a user to have multiple devices playing.
        // This is niche and weird enough that I'm not gonna bother dealing with it.
        return state?.values?.firstOrNull { it.trackData != null }
    }

    private fun clearOtherUserListeningState() = lock.use {
        val ownId = UserService.getCurrentUserId() ?: return@use
        val removedUsers = internalNowListeningData.keys - ownId
        removedUsers.forEach { internalNowListeningData.remove(it) }
    }

    // I am considering a listen stale if it's over 90 seconds old.
    // We should be sending a keep-alive message every 30 seconds so this should be fairly generous.
    internal fun pruneStaleListeningState(): Boolean = lock.use {
        var somethingRemoved = false

        val removeTime = (now() - 90.seconds).toEpochMilliseconds()
        internalNowListeningData.removeWhere { _, state ->
            val removed = state.removeWhere { _, data ->
                data.lastTimeUpdate < removeTime
            }
            if (removed.isNotEmpty()) {
                somethingRemoved = true
            }

            state.isEmpty()
        }

        somethingRemoved
    }

    var privateListeningEnabled: Boolean
        get() = Settings.getBoolean(SettingType.PRIVATE_LISTENING, false)
        set(value) {
            Settings.setBoolean(SettingType.PRIVATE_LISTENING, value)

            // Because this is a privacy-focused, server-side setting, sync immediately
            if (!GGCommonInternal.isIntegrationTesting) {
                SyncCoordinator.syncAsync()
            }

            if (value) {
                clearOtherUserListeningState()
                ApiSocket.broadcastNowPlayingRefresh()
            }
        }

    internal fun clear() {
        internalNowListeningData.clear()
    }
}
