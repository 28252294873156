package net.gorillagroove.sync.strategies

import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import net.gorillagroove.api.Api
import net.gorillagroove.api.ReviewSourceId
import net.gorillagroove.api.UserId
import net.gorillagroove.db.*
import net.gorillagroove.db.Database.reviewSourceDao
import net.gorillagroove.review.RawReviewSourceType
import net.gorillagroove.review.ReviewQueueService
import net.gorillagroove.sync.*
import net.gorillagroove.util.GGLog.logInfo

object ReviewSourceSyncStrategy : SyncDownStrategy {
    override val syncType: SyncableEntity = SyncableEntity.REVIEW_SOURCE

    override suspend fun syncDown(syncStatus: DbSyncStatus, onPageSyncedHandler: PageSyncHandler) {
        fetchSyncEntities<ReviewSourceResponse>(syncType, syncStatus, onPageSyncedHandler) { changeSet ->
            Database.db.transaction {
                changeSet.newAndModified.forEach {
                    reviewSourceDao.upsert(it.asReviewSource())
                }
                changeSet.removed.forEach {
                    reviewSourceDao.delete(ReviewSourceId(it))
                }
            }

            // We should only sync if there was something TO sync (or it was first sync I guess).
            // So, any time we sync this, we should invalidate our session.
            ReviewQueueService.invalidateSession()
        }
    }

    internal suspend fun syncById(ids: Collection<ReviewSourceId>) {
        val queryParam = mapOf("ids" to ids.map { it.value })
        val responses: List<ReviewSourceResponse> = Api.get("review-queue/ids", queryParam)
        Database.db.transaction {
            responses.forEach { response ->
                val source = response.asReviewSource()
                logInfo("Inserting review source with ID: ${source.id.value}")
                reviewSourceDao.upsert(source)
            }
        }
    }
}

@Serializable
data class ReviewSourceResponse(
    val id: ReviewSourceId,
    val offlineAvailabilityType: RawOfflineAvailabilityType,
    val sourceType: RawReviewSourceType,
    val displayName: String,
    val updatedAt: Instant,
    val sourceUserId: UserId?,
) {
    fun asReviewSource() = DbReviewSource(
        id = id,
        offlineAvailability = offlineAvailabilityType,
        sourceType = sourceType,
        displayName = displayName,
        updatedAt = updatedAt,
        sourceUserId = sourceUserId,
    )
}
