import components.*
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.launch
import kotlin.reflect.KFunction0
import net.gorillagroove.authentication.AuthService
import net.gorillagroove.sync.SyncCoordinator
import net.gorillagroove.util.GGLog
import net.gorillagroove.util.GGLog.logError
import net.gorillagroove.util.GGLog.logInfo
import org.w3c.dom.Element
import org.w3c.dom.HTMLElement
import org.w3c.dom.url.URLSearchParams

object PageRouter {

    private val body get() = document.getElementById("body")!!

    private var currentRoot: Element? = null
    var currentViewMode: ViewMode = ViewMode.MY_LIBRARY
        private set

    var currentLibraryViewMode: LibraryViewMode = LibraryViewMode.TRACK
        private set

    var currentPathVariables: Map<String, String> = emptyMap()
        private set

    init {
        // FIXME I think I might need to not navigate if it changed from the same state to the same state.
        //  If you manually enter a URL then hit back, it seems to not work anymore?
        window.onpopstate = {
            navigateTo(window.location.pathname)
        }
    }

    fun navigateTo(rawPath: String) = mainScope.launch {
        // FIXME make this better, my guy
        var path = if (!SyncCoordinator.hasFirstSynced() && AuthService.isAuthenticated() && rawPath == "/") "/first-sync" else rawPath

        val routeEntry = RouterLibrary.getRouteEntry(path)

        path += generateDefaultQueryString(routeEntry)
        currentPathVariables = if (routeEntry.hasPathVariables) {
            routeEntry.getParameters(path)
        } else {
            emptyMap()
        }

        GGLog.logInfo("Navigating to path: $path")

        if (currentRoot != null) {
            val id = currentRoot!!.id
            GGLog.logInfo("Going to remove node: $id")
        }
        currentRoot?.remove()
        currentRoot = null

        window.history.pushState(null, "", path)

        val newRoot = routeEntry.rootNode.invoke()
        body.append(newRoot)

        currentRoot = newRoot
        GGLog.logInfo("New root has ID: ${newRoot.id}")
    }

    private fun generateDefaultQueryString(entry: RouteEntry): String {
        val params = URLSearchParams(window.location.search)
        entry.defaultParams.forEach { (key, value) ->
            if (!params.has(key)) {
                params.append(key, value)
            }
        }

        if (params.has("view")) {
            currentViewMode = try {
                ViewMode.valueOf(params.get("view")!!)
            } catch (e: Exception) {
                GGLog.logError("Could not parse view mode from query String")
                ViewMode.MY_LIBRARY
            }
        }

        if (params.has("library_view_mode")) {
            currentLibraryViewMode = try {
                LibraryViewMode.valueOf(params.get("library_view_mode")!!)
            } catch (e: Exception) {
                GGLog.logError("Could not parse library view mode from query String")
                LibraryViewMode.TRACK
            }
        }

        LeftNav.updateViewMode()

        val queryString = params.toString()
        return if (queryString.isBlank()) queryString else "?$queryString"
    }

    fun setViewMode(mode: ViewMode, additionalProperties: Map<String, Any> = emptyMap()) {
        val previousMode = currentViewMode
        val previousQueryString = URLSearchParams(window.location.search).toString().takeIf { it.isNotBlank() }

        currentViewMode = mode

        val params = URLSearchParams(window.location.search)
        // These don't hurt anything, but they make the URL more ugly if not needed.
        params.delete("USER_ID")
        params.delete("PLAYLIST_ID")

        val props = additionalProperties + ("view" to mode.name)
        props.forEach { (key, value) ->
            if (params.has(key)) {
                params.delete(key)
            }
            params.set(key, value.toString())
        }

        val path = window.location.pathname
        val queryString = params.toString().takeIf { it.isNotBlank() }

        if (previousQueryString != queryString) {
            GGLog.logInfo("Changing view mode from: $previousMode, to: $mode")

            val finalUri = listOfNotNull(path, queryString).joinToString("?")

            window.history.replaceState(null, "", finalUri)

            LeftNav.updateViewMode()

            SiteLayout.renderMainContent(previousMode, currentViewMode)
        }
    }

    fun setLibraryViewMode(mode: LibraryViewMode) {
        val previousQueryString = URLSearchParams(window.location.search).toString().takeIf { it.isNotBlank() }

        currentLibraryViewMode = mode

        val params = URLSearchParams(window.location.search)
        params.set("library_view_mode", mode.name)

        val path = window.location.pathname
        val queryString = params.toString().takeIf { it.isNotBlank() }

        if (previousQueryString != queryString) {
            GGLog.logInfo("Changing library view mode from to: $mode")

            val finalUri = listOfNotNull(path, queryString).joinToString("?")

            window.history.replaceState(null, "", finalUri)

            TrackTable.updateLibraryViewMode()
        }

        LibraryHeader.updateFromViewTypeChange()
    }

    fun getQueryParam(param: String): String? {
        val params = URLSearchParams(window.location.search)
        return params.get(param)
    }

    fun removeQueryParam(param: String) {
        val params = URLSearchParams(window.location.search)
        params.delete(param)

        val queryString = params.toString().takeIf { it.isNotBlank() }

        val finalUri = if (queryString != null) {
            window.location.pathname + "?$queryString"
        } else {
            window.location.pathname
        }

        window.history.replaceState(null, "", finalUri)
    }
}

private object RouterLibrary {
    private val routes = listOf(
        RouteEntry("/login", ::Login),
        RouteEntry("/first-sync", ::FirstSync),
        RouteEntry("/privacy-policy", ::PrivacyPolicy),
        RouteEntry("/site-in-review", ::SiteYearInReview),
        RouteEntry("/year-in-review", ::UserYearInReview),
        RouteEntry("/password-reset/:key", ::PasswordReset),
        RouteEntry("/create-account/:key", ::AccountCreation),
        RouteEntry("/track-link/:trackId", ::TrackLinkPage),
        RouteEntry("/", SiteLayout.renderRef, defaultParams = mapOf("view" to ViewMode.MY_LIBRARY.name)),
    )

    private val standardRoutes = routes
        .filterNot { it.hasPathVariables }
        .associateBy { it.route }

    private val variableRoutes = routes.filter { it.hasPathVariables }

    fun getRouteEntry(path: String): RouteEntry {
        val pathWithoutParams = path.split("?").first()

        return standardRoutes[pathWithoutParams]
            ?: variableRoutes.find { it.matches(pathWithoutParams) }
            ?: standardRoutes.getValue("/")
    }
}

private class RouteEntry(
    val route: String,
    val rootNode: KFunction0<HTMLElement>,
    val defaultParams: Map<String, String> = emptyMap(),
) {
    // These are the indexes of the path segment that contain a path variable.
    // e.g. /password-reset/:key will have this be = [Pair("key", 1)]
    private val variableSegments = route
        .trimStart('/')
        .split("/")
        .mapIndexedNotNull { index, segment ->
            if (segment.startsWith(":")) {
                segment.trimStart(':') to index
            } else {
                null
            }
        }

    val hasPathVariables = variableSegments.isNotEmpty()

    // Turns something like "/password-reset/:key/cool" into "/password-reset/.*/cool"
    private val regex = route.trimStart('/')
        .split("/")
        .mapIndexed { index, pathSegment ->
            if (variableSegments.any { it.second == index }) {
                ".*"
            } else {
                pathSegment
            }
        }.joinToString("/")
        .let { "/$it" }
        .toRegex()

    fun getParameters(url: String): Map<String, String> {
        val pathWithoutParams = url.split("?").first()
        if (variableSegments.isEmpty()) {
            return emptyMap()
        }

        val parts = pathWithoutParams.trimStart('/').split("/")

        return variableSegments.associate { (variableName, index) ->
            variableName to parts[index]
        }
    }

    fun matches(url: String): Boolean {
        return regex.matches(url)
    }
}

enum class ViewMode(
    val hidesSortId: Boolean = true,
    val hidesSectionChevron: Boolean? = false,
    val hidesLibraryViewMode: Boolean = false,
    val forcesTrackView: Boolean = false,
    val hasFilterOptions: Boolean = false,
    val isTrackView: Boolean = true,
) {
    MY_LIBRARY(hasFilterOptions = true, hidesSectionChevron = null),
    NOW_PLAYING(hidesSortId = false, hidesSectionChevron = true, hidesLibraryViewMode = true, forcesTrackView = true),
    USERS(hasFilterOptions = true, hidesSectionChevron = null),
    PLAYLISTS(hidesSortId = false, hidesSectionChevron = true, hidesLibraryViewMode = true, forcesTrackView = true),
    REVIEW_QUEUE(hidesLibraryViewMode = true, hidesSectionChevron = false),
    SPOTIFY_SEARCH(isTrackView = false),
    SETTINGS(isTrackView = false),
    TRACK_HISTORY(isTrackView = false),
    DEVICE_MANAGEMENT(isTrackView = false),
    PLAY_SESSION(isTrackView = false),
    DOWNLOAD_SERVER(isTrackView = false),
}

enum class LibraryViewMode(
    val displayName: String,
    val hidesSectionChevron: Boolean = false,
) {
    TRACK("All", hidesSectionChevron = true),
    ARTIST("Artist"),
    ALBUM("Album"),
}
