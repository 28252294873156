package net.gorillagroove.util

import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import kotlinx.datetime.Instant
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import net.gorillagroove.GGCommonInternal
import net.gorillagroove.track.Track
import net.gorillagroove.util.GGLog.logWarn
import net.gorillagroove.util.SettingType.*
import kotlin.math.log10
import kotlin.math.pow
import kotlin.math.roundToInt
import kotlin.math.roundToLong

typealias TrackFormatChangeHandler = () -> Unit

object Formatter {
    private val lock: Lock = Lock()

    private val handlers = mutableMapOf<Int, TrackFormatChangeHandler>()

    var userDateFormat: String
        get() = Settings.getString(DATE_FORMAT, "D/M/YYYY")
        // I could validate this, but the library could be out of date, and I could allow more things to be put in here
        // as time goes on. I am fine with just keeping it stupid. It's pretty low-impact if something goes wrong.
        set(value) = Settings.setString(DATE_FORMAT, value)

    var featuringDelimiterFormat: String
        get() = Settings.getString(TRACK_DISPLAY_FEATURING_DELIMITER, "ft.")
        set(value) {
            Settings.setString(TRACK_DISPLAY_FEATURING_DELIMITER, value)
            emitEvent()
        }

    var trackDisplayFormat: String
        get() = Settings.getString(TRACK_DISPLAY_FORMAT,"{name} - {artist}")
        set(value) {
            Settings.setString(TRACK_DISPLAY_FORMAT, value)
            emitEvent()
        }

    var trackNameDisplayFormat: String
        get() = Settings.getString(TRACK_NAME_DISPLAY_FORMAT, "{name}")
        set(value) {
            Settings.setString(TRACK_NAME_DISPLAY_FORMAT, value)
            emitEvent()
        }

    var trackArtistDisplayFormat: String
        get() = Settings.getString(TRACK_ARTIST_DISPLAY_FORMAT, "{artist} {feat} {featuring}")
        set(value) {
            Settings.setString(TRACK_ARTIST_DISPLAY_FORMAT, value)
            emitEvent()
        }

    internal fun Instant.toUserFormat(timeZone: TimeZone = TimeZone.currentSystemDefault()): String {
        return this.toFormat(userDateFormat, timeZone)
    }

    internal fun Instant.toFormat(format: String, timeZone: TimeZone = TimeZone.currentSystemDefault()): String {
        val date = this.toLocalDateTime(timeZone)
        return format.replace("DD", date.dayOfMonth.zeroPadded())
            .replace("D", date.dayOfMonth.toString())
            .replace("MM", date.monthNumber.zeroPadded())
            .replace("M", date.monthNumber.toString())
            .replace("YYYY", date.year.toString())
            .replace("YY", date.year.toString().takeLast(2))
    }

    private fun Int.zeroPadded(): String {
        return if (this >= 10) this.toString() else "0$this"
    }

    fun Long.toReadableByteString(): String {
        if (this < 1000) {
            return "$this B"
        }

        val kb = this / 1000.0
        if (kb < 1000) {
            return "${kb.toSignificantFigures(3)} KB"
        }

        val mb = kb / 1000.0
        if (mb < 1000) {
            return "${mb.toSignificantFigures(3)} MB"
        }

        val gb = mb / 1000.0
        if (gb < 1000) {
            return "${gb.toSignificantFigures(3)} GB"
        }

        val tb = gb / 1000.0
        return "${tb.toSignificantFigures(3)} TB"
    }

    // For iOS that can't easily use extension functions
    fun getReadableByteString(num: Long): String {
        return num.toReadableByteString()
    }

    // Would be made easier whenever this is implemented: https://youtrack.jetbrains.com/issue/KT-21644
    // This function is kind of a lie in that it probably isn't the scientific definition of significant figures.
    // But what it will do is the following:
    // 1.123.toSignificantFigures(3) => 1.12
    // 10.123.toSignificantFigures(3) => 10.1
    // 105.823.toSignificantFigures(3) => 106
    internal fun Double.toSignificantFigures(digits: Int): String {
        // My implementation is probably highly naive. But hey, it works on any platform that has MATH.
        val nonDecimalDigits = (log10(this) + 1).roundToInt()

        if (nonDecimalDigits >= digits) {
            return this.roundToLong().toString()
        }

        val nonDecimalComponent = this.toLong()

        // Converts 614.123 into 0.123
        val decimalComponent = this - nonDecimalComponent

        // Now that we have just the decimals, we can shift it over by the number of additional digits we need.
        // Then we can round it, and jam it onto the end of our non-decimal component.
        // So if we want 2 more figures, 0.123 becomes 12.3, then we round it to just 12.
        val decimalsToKeep = digits - nonDecimalDigits
        val decimalAsLong = (decimalComponent * 10.0.pow(decimalsToKeep)).roundToLong()

        return "$nonDecimalComponent.$decimalAsLong"
    }

    fun getTrackArtistDisplayString(track: Track): String {
        return replaceTrackStrings(track.name, track.artist, track.featuring, artistMode = true)
    }

    private fun getTrackArtistDisplayString(name: String, artist: String, featuring: String): String {
        return replaceTrackStrings(name, artist, featuring, artistMode = true)
    }

    fun getTrackNameDisplayString(track: Track): String {
        return replaceTrackStrings(track.name, track.artist, track.featuring, artistMode = false)
    }

    private fun getTrackNameDisplayString(name: String, artist: String, featuring: String): String {
        return replaceTrackStrings(name, artist, featuring, artistMode = false)
    }

    private fun replaceTrackStrings(name: String, artist: String, featuring: String, artistMode: Boolean): String {
        // If there's no featured artist, then we don't want to display "ft."
        val delimiterToUse = if (featuring.isBlank()) "" else featuringDelimiterFormat

        return (if (artistMode) trackArtistDisplayFormat else trackNameDisplayFormat)
            .replace(if (artistMode) "{artist}" else "{name}", if (artistMode) artist else name)
            .replace("{featuring}", featuring)
            .replace("{feat}", delimiterToUse)
            .trim()
    }

    /**
     * Used by settings menus on the clients to show what their choices will look like
     */
    fun generateExampleDisplayString(): String {
        val track = Track(name = "Set Yourself Free", artist = "Tiësto", featuring = "Krewella")
        return getPlayingTrackDisplayString(track)
    }

    fun getPlayingTrackDisplayString(track: Track): String {
        return getPlayingTrackDisplayString(track.name, track.artist, track.featuring)
    }

    fun getPlayingTrackDisplayString(name: String, artist: String, featuring: String): String {
        return trackDisplayFormat.replace("{name}", getTrackNameDisplayString(name, artist, featuring))
            .replace("{artist}", getTrackArtistDisplayString(name, artist, featuring))
            .trim()
    }

    fun formatToUserDateFormat(instant: Instant): String {
        return instant.toUserFormat()
    }

    /**
     * The provided String must parse to an Instant
     */
    fun formatToUserDateFormat(date: String): String {
        return Instant.parse(date).toUserFormat()
    }

    fun getDurationDisplay(track: Track) = getDurationDisplayFromSeconds(track.length)

    fun getDurationDisplayFromSeconds(seconds: Int): String {
        val hours = seconds / 3600
        val minutes = (seconds / 60) % 60
        val secondsPart = seconds % 60

        return if (hours == 0) {
            "$minutes:${secondsPart.toZeroPaddedString()}"
        } else {
            "$hours:${minutes.toZeroPaddedString()}:${secondsPart.toZeroPaddedString()}"
        }
    }

    private fun Int.toZeroPaddedString(): String {
        return if (this < 10) "0$this" else this.toString()
    }

    internal fun Instant.toTimeAgoString(): String {
        val difference = TimeUtil.now() - this

        fun Long.toPluralizedLabel(label: String): String {
            return "$this $label" + if (this == 1L) "" else "s"
        }

        val timeString = if (difference.inWholeSeconds < 10) {
            return "Just now"
        } else if (difference.inWholeSeconds < 60) {
            difference.inWholeSeconds.toPluralizedLabel("second")
        } else if (difference.inWholeMinutes < 60) {
            difference.inWholeMinutes.toPluralizedLabel("minute")
        } else if (difference.inWholeHours < 24) {
            difference.inWholeHours.toPluralizedLabel("hour")
        } else {
            difference.inWholeDays.toPluralizedLabel("day")
        }

        return "$timeString ago"
    }

    fun registerEventHandler(handler: TrackFormatChangeHandler): Int {
        // For some ungodly reason, I used to have a "handlerId: Int = 0" that I just incremented, but it
        // was getting messed up by some KotlinJS bug where it would lose its value for no reason. I have
        // pivoted to instead doing this maxOrNull thing and it seems like it's just as good. But this is
        // why it is the way that it is. Maybe when I can update the Kotlin version I can do the other way.
        return lock.use {
            val id = (handlers.keys.maxOrNull() ?: 0) + 1
            handlers[id] = handler
            id
        }
    }

    fun unregisterEventHandler(handlerId: Int) {
        handlers.remove(handlerId)
    }

    private fun emitEvent() {
        if (handlers.isEmpty()) {
            logWarn("No TrackFormatChangeHandlers registered!")
        }

        // If we are integration testing, then don't put this inside of a coroutine.
        // The async nature makes things difficult to test, but we need the callbacks to happen
        // inside a coroutine otherwise as anything in the callback that also invokes
        // more NowPlayingService stuff can hit a lock and create a deadlock
        if (GGCommonInternal.isIntegrationTesting) {
            handlers.values.forEach { it() }
        } else {
            // Drop into a coroutine so that we are no longer inside any locks while evaluating the callbacks.
            // Otherwise, if a callback is doing something that ALSO hits a lock, we will deadlock.
            CoroutineScope(Dispatchers.Main).launch {
                handlers.values.forEach { it() }
            }
        }
    }
}

fun Double.round(decimals: Int): String {
    var multiplier = 1.0
    repeat(decimals) { multiplier *= 10 }
    return (kotlin.math.round(this * multiplier) / multiplier).toString()
}
