package net.gorillagroove.track

import io.ktor.http.*
import kotlinx.serialization.Serializable
import net.gorillagroove.api.Api
import net.gorillagroove.api.TrackApiId
import net.gorillagroove.api.getQueryParam
import net.gorillagroove.db.Database
import net.gorillagroove.discovery.MetadataResponseDTO
import net.gorillagroove.discovery.MetadataSearchResponse
import net.gorillagroove.discovery.MultiTrackResponse
import net.gorillagroove.sync.OfflineAvailabilityType
import net.gorillagroove.sync.strategies.TrackResponse
import net.gorillagroove.util.Formatter.toReadableByteString
import net.gorillagroove.util.GGLog.logInfo
import net.gorillagroove.util.GGLog.logWarn
import net.gorillagroove.util.toJsonString
import kotlin.reflect.KProperty1
import kotlin.reflect.KClass

/**
 * So the way this is intended to work, is the client provides a list of all tracks being edited.
 * We then pass back the MetadataUpdateState, and the client will use that to populate all form fields.
 * When a form field is updated, the MetadataProperty needs to be updated in the MetadataUpdateState to
 * reflect the change. Once the user submits, call updateMetadata() on the MetadataUpdateState instance.
 * Only properties that were changed will be updated, and the others will be ignored in order to allow
 * bulk changes to a single (or multiple) field without affecting the others.
 */
object MetadataService {
    fun createMetadataUpdateState(tracks: List<Track>): MetadataUpdateState {
        if (tracks.isEmpty()) {
            throw IllegalArgumentException("Cannot create MetadataUpdateState from empty Track collection")
        }

        val properties = MetadataProperty.entries.associateWith { property ->
            val firstProperty = property.get(tracks.first())
            val allSame = tracks.all { property.get(it) == firstProperty }
            return@associateWith if (allSame) firstProperty else null
        } + (MetadataProperty.ALBUM_ART_URL to null)

        return MetadataUpdateState(
            originalTracks = tracks,
            originalProperties = properties,
        )
    }

    @Throws(Throwable::class)
    suspend fun getMetadataForTrackDetails(
        name: String,
        artist: String,
        length: Int,
    ): MetadataResponseDTO? {
        require(name.isNotBlank()) { "'name' must not be empty" }
        require(artist.isNotBlank()) { "'artist' must not be empty" }
        require(length > 0) { "'length' must be greater than 0" }

        val url = "search/spotify/artist/$artist/name/$name/length/$length"
        return Api.get<MetadataSearchResponse>(url).items.firstOrNull()
    }

    @Throws(Throwable::class)
    suspend fun updateSingleItem(
        track: Track,
        metadataProperty: MetadataProperty,
        value: String,
    ) {
        val state = createMetadataUpdateState(listOf(track))
        state.properties[metadataProperty] = value

        state.updateMetadata()
    }

    internal fun coercePropertyStringToApiFormat(property: MetadataProperty, value: String): Any {
        return if (property.type == Int::class) {
            // The API specifically treats nulls as no-ops, so in order to null out an int, we pass it as -1
            if (value.isBlank()) -1 else value.toInt()
        } else if (property.type == Boolean::class) {
            value.toBooleanStrict()
        } else {
            value.trim()
        }
    }

    @Throws(Throwable::class)
    suspend fun getYouTubeMetadataPreview(url: String): MetadataPreviewResponse {
        val videoId = Url(url).getQueryParam("v")
            ?: throw IllegalArgumentException("No query param 'v' found in YouTube URL '$url'!")

        return Api.get<MetadataPreviewResponse>("track/metadata-preview/youtube/$videoId")
    }

    @Throws(Throwable::class)
    suspend fun populateNewMetadata(
        tracks: List<Track>,
        changeAlbum: MetadataOverrideType = MetadataOverrideType.NEVER,
        changeAlbumArt: MetadataOverrideType = MetadataOverrideType.NEVER,
        changeReleaseYear: MetadataOverrideType = MetadataOverrideType.NEVER,
        changeTrackNumber: MetadataOverrideType = MetadataOverrideType.NEVER,
    ): DataUpdateResult {
        val request = MetadataUpdateRequest(
            trackIds = tracks.mapNotNull { it.apiId },
            changeAlbum = changeAlbum,
            changeAlbumArt = changeAlbumArt,
            changeReleaseYear = changeReleaseYear,
            changeTrackNumber = changeTrackNumber,
        )

        val response = Api.post<DataUpdateResponse>("track/data-update-request", request)

        val apiIds = response.successfulUpdates.map { it.id }.toSet()
        val trackApiIdToLocalId = TrackService.findLocalIdForApiId(apiIds)

        val successfulDbTracks = response.successfulUpdates.map { trackResponse ->
            val dbTrack = trackResponse.asTrack(trackApiIdToLocalId)
            TrackService.save(dbTrack)
            return@map dbTrack
        }

        Database.forceSave()

        val failedTracks = if (response.failedUpdateIds.isNotEmpty()) {
            val failedTrackIds = response.failedUpdateIds.toSet()
            tracks.filter { failedTrackIds.contains(it.apiId) }
        } else emptyList()

        val successfulTracks = successfulDbTracks.toTracks()
        if (successfulDbTracks.isNotEmpty()) {
            TrackService.broadcastTrackChange(successfulTracks, ChangeType.UPDATED)
        }

        return DataUpdateResult(
            successfulUpdates = successfulTracks,
            failedUpdates = failedTracks,
        )
    }
}

class MetadataUpdateState(
    private val originalTracks: List<Track>,
    private val originalProperties: Map<MetadataProperty, String?>,
    internal val properties: MutableMap<MetadataProperty, String?> = originalProperties.toMutableMap(),
    var newBinaryImageData: ByteArray? = null
) {
    // I probably should make a custom delegate for these to reduce copy-paste. But I'm just not in a fancy mood.
    var name: String?
        get() = properties.getValue(MetadataProperty.NAME)
        set(value) { properties[MetadataProperty.NAME] = value }

    var artist: String?
        get() = properties.getValue(MetadataProperty.ARTIST)
        set(value) { properties[MetadataProperty.ARTIST] = value }

    var featuring: String?
        get() = properties.getValue(MetadataProperty.FEATURING)
        set(value) { properties[MetadataProperty.FEATURING] = value }

    var album: String?
        get() = properties.getValue(MetadataProperty.ALBUM)
        set(value) { properties[MetadataProperty.ALBUM] = value }

    var genre: String?
        get() = properties.getValue(MetadataProperty.GENRE)
        set(value) { properties[MetadataProperty.GENRE] = value }

    var trackNumber: Int?
        get() = properties.getValue(MetadataProperty.TRACK_NUMBER)?.toIntOrNull()
        set(value) { properties[MetadataProperty.TRACK_NUMBER] = value?.toString() ?: "" }

    var releaseYear: Int?
        get() = properties.getValue(MetadataProperty.RELEASE_YEAR)?.toIntOrNull()
        set(value) { properties[MetadataProperty.RELEASE_YEAR] = value?.toString() ?: "" }

    var note: String?
        get() = properties.getValue(MetadataProperty.NOTE)
        set(value) { properties[MetadataProperty.NOTE] = value }

    var offlineAvailability: OfflineAvailabilityType?
        get() = properties.getValue(MetadataProperty.OFFLINE_AVAILABILITY)?.let { OfflineAvailabilityType.valueOf(it) }
        set(value) { properties[MetadataProperty.OFFLINE_AVAILABILITY] = value?.name }

    var hidden: Boolean?
        get() = properties.getValue(MetadataProperty.HIDDEN)?.toBoolean()
        set(value) { properties[MetadataProperty.HIDDEN] = value?.toString() }

    var private: Boolean?
        get() = properties.getValue(MetadataProperty.PRIVATE)?.toBoolean()
        set(value) { properties[MetadataProperty.PRIVATE] = value?.toString() }

    var cropToSquare: Boolean?
        get() = properties.getValue(MetadataProperty.CROP_ART_TO_SQUARE)?.toBoolean()
        set(value) { properties[MetadataProperty.CROP_ART_TO_SQUARE] = value?.toString() }

    var albumArtUrl: String?
        get() = properties.getValue(MetadataProperty.ALBUM_ART_URL)
        set(value) { properties[MetadataProperty.ALBUM_ART_URL] = value }

    val isAlbumEdited get() = isEdited(MetadataProperty.ALBUM)
    val isGenreEdited get() = isEdited(MetadataProperty.GENRE)
    val isTrackNumberEdited get() = isEdited(MetadataProperty.TRACK_NUMBER)
    val isReleaseYearEdited get() = isEdited(MetadataProperty.RELEASE_YEAR)

    val cachedByteString: String by lazy {
        originalTracks.sumOf { it.cachedBytes }.toLong().toReadableByteString()
    }

    val isCachedString: String by lazy {
        var oneCached = false
        var allCached = true
        originalTracks.forEach { track ->
            if (track.audioCachedAt == null) {
                allCached = false
            } else {
                oneCached = true
            }
        }

        if (allCached) {
            "Yes"
        } else if (oneCached) {
            "Some"
        } else {
            "No"
        }
    }

    val addedToLibraryString: String by lazy {
        val formattedAdded = originalTracks.first().formattedAddedToLibrary

        if (originalTracks.all { it.formattedAddedToLibrary == formattedAdded }) {
            formattedAdded
        } else {
            ""
        }
    }

    val lastPlayedString: String by lazy {
        val formattedLastPlayed = originalTracks.first().formattedLastPlayed

        if (originalTracks.all { it.formattedLastPlayed == formattedLastPlayed }) {
            formattedLastPlayed
        } else {
            ""
        }
    }

    private fun isEdited(property: MetadataProperty): Boolean {
        return properties.getValue(property) != originalProperties.getValue(property)
    }

    @Throws(Throwable::class)
    suspend fun updateMetadata() {
        val changedProperties = mutableMapOf<String, Any>()
        properties.forEach { (key, newValue) ->
            val oldValue = originalProperties.getValue(key)
            if (newValue == oldValue) {
                return@forEach
            }

            // Null is used to represent data where multiple tracks had conflicting data.
            // If we send up a null value, we are basically telling the API to ignore that property
            // and not do anything with it. It won't be changed.
            // So if a value is changed from a real value to a null one, that is pretty unexpected
            // and, AFAIK, represents an error on the part of the consumer as it does nothing.
            if (newValue == null) {
                logWarn("Property $key wasn't null ($oldValue) but was set to a null value. This is unexpected and has no meaning.")
                return@forEach
            }

            if (key == MetadataProperty.ALBUM_ART_URL && newBinaryImageData != null) {
                throw IllegalArgumentException("Both the albumArtUrl and binaryImageData cannot be specified. Choose one or the other")
            }

            changedProperties[key.apiName] = MetadataService.coercePropertyStringToApiFormat(key, newValue)
        }

        if (changedProperties.isEmpty() && newBinaryImageData == null) {
            logInfo("No metadata change was made. Not sending update request")
            return
        }

        // TODO make this work for local-only track data
        changedProperties["trackIds"] = originalTracks.mapNotNull { it.apiId?.value }

        val tracks: MultiTrackResponse = Api.complexUpload(
            url = "track",
            method = HttpMethod.Put,
            uploadObjects = buildList {
                newBinaryImageData?.let { imageData ->
                    add(Api.BinaryUploadObject(
                        dataKey = "albumArt",
                        filename = "albumArt",
                        data = imageData,
                    ))
                }
                add(Api.JsonUploadObject(
                    dataKey = "updateTrackJson",
                    data = changedProperties.toJsonString()
                ))
            },
        )

        val dbTracks = tracks.items.map { response ->
            response.asTrack().also { TrackService.save(it) }
        }

        TrackService.broadcastTrackChange(dbTracks.toTracks(), ChangeType.UPDATED)
    }

    @Throws(Throwable::class)
    suspend fun requestNewMetadata() {
        require(originalTracks.size == 1) { "populateWithMetadata cannot be called with more than one Track" }

        val newData = MetadataService.getMetadataForTrackDetails(
            // Using !! here because these values should be properly set if we only have one track.
            // Nulls are only used if there's more than one.
            name = properties.getValue(MetadataProperty.NAME)!!,
            artist = properties.getValue(MetadataProperty.ARTIST)!!,
            length = originalTracks.first().length
        ) ?: return

        properties[MetadataProperty.ALBUM] = newData.album
        properties[MetadataProperty.TRACK_NUMBER] = newData.trackNumber.toString()
        properties[MetadataProperty.RELEASE_YEAR] = newData.releaseYear.toString()
        newData.albumArtLink?.let { properties[MetadataProperty.ALBUM_ART_URL] = it }
    }

    suspend fun loadAlbumArt(): ByteArray? {
        // There is (currently) no way to group album art together for multiple tracks, because each one stores
        // their art separately in S3. I doubt I will ever feel like changing that, so it will probably always
        // be the case that we don't see the album art preview if more than one Track is selected. If more
        // than one track is selected, then "originalTrack" is null here.
        if (originalTracks.size > 1) {
            return null
        }

        return TrackService.getTrackByteData(originalTracks.first(), TrackLinkType.ART_PNG)
    }

    override fun toString(): String {
        return "MetadataUpdateState(properties=$properties)"
    }
}

enum class MetadataProperty(
    val apiName: String,
    internal val kProperty: KProperty1<Track, Any?>? = null,
    internal val type: KClass<*> = String::class
) {
    NAME("name", Track::name),
    ARTIST("artist", Track::artist),
    FEATURING("featuring", Track::featuring),
    ALBUM("album", Track::album),
    GENRE("genre", Track::genre),
    TRACK_NUMBER("trackNumber", Track::trackNumber, Int::class),
    RELEASE_YEAR("releaseYear", Track::releaseYear, Int::class),
    NOTE("note", Track::note),
    OFFLINE_AVAILABILITY("offlineAvailability", Track::offlineAvailability),
    HIDDEN("hidden", Track::isHidden, Boolean::class),
    PRIVATE("private", Track::isPrivate, Boolean::class),
    CROP_ART_TO_SQUARE("cropArtToSquare"),
    ALBUM_ART_URL("albumArtUrl"),
    ;

    internal fun get(track: Track): String {
        return this.kProperty?.get(track)?.toString() ?: ""
    }
}

@Serializable
data class MetadataPreviewResponse(
    val name: String,
    val artist: String? = null,
    val album: String? = null,
    val releaseYear: Int? = null,
    val trackNumber: Int? = null,
    val albumArtUrl: String? = null,
)

@Serializable
internal data class MetadataUpdateRequest(
    val trackIds: List<TrackApiId>,

    val changeAlbum: MetadataOverrideType = MetadataOverrideType.NEVER,
    val changeAlbumArt: MetadataOverrideType = MetadataOverrideType.NEVER,
    val changeReleaseYear: MetadataOverrideType = MetadataOverrideType.NEVER,
    val changeTrackNumber: MetadataOverrideType = MetadataOverrideType.NEVER
)

@Serializable
internal data class DataUpdateResponse(
    val successfulUpdates: List<TrackResponse>,
    val failedUpdateIds: List<TrackApiId>,
)

@Serializable
enum class MetadataOverrideType {
    ALWAYS, IF_EMPTY, NEVER
}

data class DataUpdateResult(
    val successfulUpdates: List<Track>,
    val failedUpdates: List<Track>,
)
