package net.gorillagroove.track

import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import net.gorillagroove.api.Api
import net.gorillagroove.api.TrackHistoryId
import net.gorillagroove.api.UserId
import net.gorillagroove.sync.strategies.TrackResponse
import net.gorillagroove.util.GGLog.logInfo

object TrackHistoryService {
    @Throws(Throwable::class)
    suspend fun getHistory(
        startDate: Instant,
        endDate: Instant,
        userId: UserId?,
    ): List<TrackHistory> {
        val params = mapOf(
            "startDate" to startDate.toEpochMilliseconds(),
            "endDate" to endDate.toEpochMilliseconds(),
            "userId" to userId?.value,
        )
        val history = Api.get<TrackHistoryResponse>("track-history/v2", params).items

        val apiIds = history.map { it.track.id }.toSet()
        val trackApiIdToLocalId = TrackService.findLocalIdForApiId(apiIds)

        return history.map { historyResponseItem ->
            TrackHistory(
                id = historyResponseItem.id,
                track = historyResponseItem.track.asTrack(trackApiIdToLocalId).toTrack(),
                listenedDate = historyResponseItem.listenedDate,
                deviceName = historyResponseItem.deviceName,
            )
        }
    }

    @Throws(Throwable::class)
    suspend fun deleteHistory(id: TrackHistoryId) {
        logInfo("Deleting track history with ID: ${id.value}")

        return Api.delete<Unit>("track-history/${id.value}")
    }
}

@Serializable
internal data class TrackHistoryResponse(
    val items: List<TrackHistoryResponseItem>
)

@Serializable
internal data class TrackHistoryResponseItem(
    val id: TrackHistoryId,
    val track: TrackResponse,
    val listenedDate: Instant,
    val deviceName: String?,
    val localListenDate: String,
)

data class TrackHistory(
    val id: TrackHistoryId,
    val track: Track,
    val listenedDate: Instant,
    val deviceName: String?,

    // I am excluding this for now because I'm not entirely sure how I want to represent it.
    // Currently, no UI cares to display this information
//    val localListenDate: String,
)
