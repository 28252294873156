package net.gorillagroove.db

import com.squareup.sqldelight.Query
import com.squareup.sqldelight.db.SqlDriver
import net.gorillagroove.hardware.DeviceType
import net.gorillagroove.hardware.DeviceUtil
import net.gorillagroove.playlist.RawPlaylistOwnershipTypeAdapter
import net.gorillagroove.review.RawReviewSourceTypeAdapter
import net.gorillagroove.sync.RawOfflineAvailabilityTypeAdapter
import net.gorillagroove.sync.RawUserFavoriteTypeAdapter
import net.gorillagroove.user.permission.RawUserPermissionTypeAdapter
import net.gorillagroove.util.GGLog
import net.gorillagroove.util.debounce
import kotlin.time.Duration.Companion.seconds

internal expect object PlatformDatabase {
    suspend fun createDriver(): SqlDriver

    fun getDbAsByteArray(): DbByteArrays

    suspend fun close(driver: SqlDriver, forceSave: Boolean)

    suspend fun delete()

    suspend fun forceSave()
}

internal class DbByteArrays(val dbContent: ByteArray, val walContent: ByteArray? = null)

@Suppress("VARIABLE_IN_SINGLETON_WITHOUT_THREAD_LOCAL")
object Database {
    private var database: GGDatabase? = null
    private var driver: SqlDriver? = null

    internal const val DATABASE_NAME = "groove.db"

    suspend fun initialize() {
        val (database, driver) = createDatabase()
        this.database = database
        this.driver = driver

        GGLog.init()
    }

    internal fun getDbAsByteArray() = PlatformDatabase.getDbAsByteArray()

    internal suspend fun delete() {
        driver?.close()
        PlatformDatabase.delete()
        close(forceSave = false)
    }

    internal suspend fun close(forceSave: Boolean) {
        driver?.let { PlatformDatabase.close(it, forceSave) }
        database = null
        driver = null
    }

    internal val db: GGDatabase get() = database ?: throw IllegalStateException("Database must be initialized prior to access")

    internal fun<T> transactionWithReturn(function: () -> T): T {
        var returnedValue: T? = null
        db.transaction {
            returnedValue = function()
        }

        return returnedValue!!
    }

    fun forceSave() {
        // I'm prolly gonna regret this, but a lot of save actions can be chained and it is a bit
        // expensive to export and re-init the DB. So try to batch things together.
        debounce(this, 2.seconds) {
            PlatformDatabase.forceSave()

            // The current JS sql driver breaks the database after you export and save it....
            // So you have to re-init it every time you do save.
            if (DeviceUtil.getDeviceType() == DeviceType.WEB) {
                initialize()
            }
        }
    }

    val isInitialized: Boolean get() = database != null

    internal val userDao get() = db.dbUserQueries
    internal val trackDao get() = db.dbTrackQueries
    internal val playlistDao get() = db.dbPlaylistQueries
    internal val playlistTrackDao get() = db.dbPlaylistTrackQueries
    internal val playlistUserDao get() = db.dbPlaylistUserQueries
    internal val syncStatusDao get() = db.dbSyncStatusQueries
    internal val failedListenDao get() = db.dbFailedListenQueries
    internal val reviewSourceDao get() = db.dbReviewSourceQueries
    internal val logLineDao get() = db.dbLogLineQueries
    internal val userSettingDao get() = db.dbUserSettingQueries
    internal val userFavoriteDao get() = db.dbUserFavoriteQueries
    internal val userPermissionDao get() = db.dbUserPermissionQueries
}

private suspend fun createDatabase(): Pair<GGDatabase, SqlDriver> {
    val driver = PlatformDatabase.createDriver()
    val database = GGDatabase(
        driver,
        DbSyncStatusAdapter = DbSyncStatus.Adapter(SyncStatusIdAdapter, InstantAdapter, InstantAdapter),
        DbFailedListenAdapter = DbFailedListen.Adapter(FailedListenIdAdapter, TrackApiIdAdapter, InstantAdapter),
        // This is kind of stupid, isn't it? Just repeat InstantAdapter 7 times? No library is perfect I guess.
        DbTrackAdapter = DbTrack.Adapter(TrackIdAdapter, TrackApiIdAdapter, UserIdAdapter, ReviewSourceIdAdapter, InstantAdapter, InstantAdapter, InstantAdapter, InstantAdapter, InstantAdapter, RawOfflineAvailabilityTypeAdapter, InstantAdapter, InstantAdapter, UserIdAdapter),
        DbUserAdapter = DbUser.Adapter(UserIdAdapter, InstantAdapter, InstantAdapter),
        DbPlaylistAdapter = DbPlaylist.Adapter(PlaylistIdAdapter, InstantAdapter, InstantAdapter),
        DbPlaylistTrackAdapter = DbPlaylistTrack.Adapter(PlaylistTrackIdAdapter, PlaylistIdAdapter, TrackIdAdapter, InstantAdapter),
        DbReviewSourceAdapter = DbReviewSource.Adapter(ReviewSourceIdAdapter, RawReviewSourceTypeAdapter, RawOfflineAvailabilityTypeAdapter, InstantAdapter, UserIdAdapter),
        DbPlaylistUserAdapter = DbPlaylistUser.Adapter(PlaylistUserIdAdapter, PlaylistIdAdapter, UserIdAdapter, RawPlaylistOwnershipTypeAdapter, InstantAdapter, InstantAdapter),
        DbLogLineAdapter = DbLogLine.Adapter(LogLineIdAdapter, InstantAdapter),
        DbUserSettingAdapter = DbUserSetting.Adapter(UserSettingIdAdapter, UserSettingApiIdAdapter, InstantAdapter),
        DbUserFavoriteAdapter = DbUserFavorite.Adapter(UserFavoriteIdAdapter, RawUserFavoriteTypeAdapter, InstantAdapter, InstantAdapter),
        DbUserPermissionAdapter = DbUserPermission.Adapter(UserPermissionIdAdapter, RawUserPermissionTypeAdapter, InstantAdapter),
    )

    driver.execute(null, "PRAGMA foreign_keys = ON;", 0)

    return (database to driver)
}

// The normal functions for this are just so wordy...
fun<T : Any> Query<T>.one(): T = this.executeAsOne()
fun<T : Any> Query<T>.oneOrNull(): T? = this.executeAsOneOrNull()
fun<T : Any> Query<T>.many(): List<T> = this.executeAsList()
