package net.gorillagroove

import net.gorillagroove.authentication.VersionService
import net.gorillagroove.db.Database

object GGCommon {
    suspend fun initialize(ggVersion: String) {
        VersionService.currentDeviceVersion = ggVersion
        Database.initialize()
    }
}
