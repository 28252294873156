package net.gorillagroove.sync

import kotlinx.coroutines.*
import kotlinx.coroutines.sync.Mutex
import kotlinx.coroutines.sync.withLock
import kotlinx.datetime.Clock.System.now
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import net.gorillagroove.GGCommonInternal.doAsyncIfProduction
import net.gorillagroove.api.Api
import net.gorillagroove.api.SyncStatusId
import net.gorillagroove.api.isBenignException
import net.gorillagroove.api.isNoInternetException
import net.gorillagroove.authentication.VersionService
import net.gorillagroove.db.Database
import net.gorillagroove.db.Database.syncStatusDao
import net.gorillagroove.db.DbSyncStatus
import net.gorillagroove.db.many
import net.gorillagroove.db.one
import net.gorillagroove.localstorage.LocalStorage
import net.gorillagroove.sync.SyncDirection.*
import net.gorillagroove.sync.SyncPriority.*
import net.gorillagroove.sync.SyncResultStatus.*
import net.gorillagroove.sync.strategies.*
import net.gorillagroove.track.MarkListenedService
import net.gorillagroove.util.*
import net.gorillagroove.util.CoroutineUtil.CancelledJob
import net.gorillagroove.util.Formatter.toTimeAgoString
import net.gorillagroove.util.GGLog.logCrit
import net.gorillagroove.util.GGLog.logDebug
import net.gorillagroove.util.GGLog.logError
import net.gorillagroove.util.GGLog.logInfo
import net.gorillagroove.util.GGLog.logWarn
import net.gorillagroove.util.Lock
import net.gorillagroove.util.use
import kotlin.coroutines.resume
import kotlin.coroutines.resumeWithException
import kotlin.time.Duration
import kotlin.time.Duration.Companion.minutes

// A single network request is done
typealias PageSyncHandler = ((SyncableEntity, Double) -> Unit)
// The entire sync is done
typealias SyncCompleteHandler = ((SyncResults) -> Unit)
// The sync starts or stops
typealias SyncLifecycleChangeHandler = (syncing: Boolean) -> Unit
// An entity type finished syncing. So if we synced 3 pages of Tracks, only emits after all 3 pages.
typealias PassiveSyncChangeHandler = (SyncableEntity) -> Unit

@Suppress("VARIABLE_IN_SINGLETON_WITHOUT_THREAD_LOCAL")
object SyncCoordinator {
    // Supposedly 'Default' will use a background thread on iOS
    private val coroutineScope = CoroutineScope(Dispatchers.Default)

    private var activeJob: Job? = null
    private var lastSyncStartTime: Instant? = null
    private var numFalseStarts = 0
    private var syncAborting = false

    // I used to use this lock for a lot of stuff in this sync to make it possible to multi-thread
    // the sync, but I hit IllegalMonitorStateExceptions on Android. Idk why. But it doesn't seem
    // worth solving since this sync is only single-threaded right now, and it realistically never needs
    // to be fancier than that. So just make sure you don't get too ambitious with this in the future.
    private val lock: Lock = Lock()

    private val lmtLock: Mutex = Mutex()
    private val currentLmt = mutableMapOf<String, Instant>()

    private val taskQueue = TaskQueue()

    // I'd love to get this at compile time. There is an issue being worked on for 1.7, though
    // it might be initially JVM-only. However, there are other linked tasks for JS and Native.
    // Maybe they will come in 1.8, and we can get rid of this point of maintenance?
    // If it relies on the kotlin.reflect library it isn't worth it. That library is too large,
    // and this shared library is loaded onto mobile apps and browsers. File size matters.
    // https://youtrack.jetbrains.com/issue/KT-25871
    private val allProcessors: List<SyncStrategy> = listOf(
        TrackSyncStrategy,
        UserSyncStrategy,
        PlaylistSyncStrategy,
        PlaylistTrackSyncStrategy,
        PlaylistUserSyncStrategy,
        ReviewSourceSyncStrategy,
        UserSettingSyncStrategy,
        UserFavoriteSyncStrategy,
        UserPermissionSyncStrategy,
    )

    private val entityTypeToStrategy = allProcessors.associateBy { it.syncType }

    private val syncResultHandlers: MutableList<Pair<SyncResults, ((SyncResults) -> Unit)>> = mutableListOf()

    private val passiveSyncHandlers = mutableMapOf<Int, PassiveSyncChangeHandler>()

    internal const val HAS_FIRST_SYNCED_KEY = "HAS_FIRST_SYNCED"

    fun hasFirstSynced(): Boolean {
        // I used to store this in LocalStorage, but because of complications with the frontend where the
        // database can be deleted by the end-user independent of local storage, I opted to store the key
        // inside of the database to make it more difficult for them to get out of sync.
        LocalStorage.readBoolean(HAS_FIRST_SYNCED_KEY)?.let { hasFirstSyncedLocalStorage ->
            Settings.setBoolean(SettingType.HAS_FIRST_SYNCED, hasFirstSyncedLocalStorage)
            LocalStorage.delete(HAS_FIRST_SYNCED_KEY)
        }
        return Settings.getBoolean(SettingType.HAS_FIRST_SYNCED, false)
    }

    fun isSyncing(): Boolean {
        return taskQueue.hasActiveTasks && taskQueue.hasPendingTasks
    }

    private var syncLifecycleChangeHandler: SyncLifecycleChangeHandler? = null
    fun registerSyncLifecycleChangeHandler(handler: SyncLifecycleChangeHandler) {
        syncLifecycleChangeHandler = handler
    }

    suspend fun sync(
        types: Set<SyncableEntity> = SyncableEntity.entries.toSet(),
        priority: SyncPriority = STANDARD,
        direction: SyncDirection = BOTH,
        throttle: Duration? = null,
        onPageSyncedHandler: PageSyncHandler? = null,
    ): SyncResults {
        return suspendCancellableCoroutine { continuation ->
            val job = syncAsync(types, priority, direction, throttle, onPageSyncedHandler) { results ->
                continuation.resume(results)
            }
            job.invokeOnCompletion { throwable ->
                if (throwable != null && continuation.isActive) {
                    continuation.resumeWithException(throwable)
                }
            }
        }
    }

    @Suppress("MemberVisibilityCanBePrivate")
    fun syncAsync(
        types: Set<SyncableEntity> = SyncableEntity.entries.toSet(),
        priority: SyncPriority = STANDARD,
        direction: SyncDirection = BOTH,
        throttle: Duration? = null,
        onPageSyncedHandler: PageSyncHandler? = null,
        syncCompleteHandler: SyncCompleteHandler? = null
    ): Job {
        // A client may wish to only sync every so often. If they are within a defined interval since
        // the last time a sync was performed, abort it to save on client resources.
        if (throttle != null) {
            val lastStart = lastSyncStartTime
            if (lastStart != null) {
                if (lastStart + throttle > now()) {
                    logInfo("Sync was performed too recently. Skipping sync request")
                    syncCompleteHandler?.invoke(AbortedSyncResult(types))
                    return CancelledJob()
                }
            }
        }

        val tasks = types.map { type ->
            SyncTask(
                type = type,
                syncDirection = direction,
                explicitPriority = priority
            )
        }

        // We want to notify consumers when the tasks that they asked to be synced have finished.
        // These handlers do not need to be invoked on sync end. They can be invoked earlier.
        lock.use {
            taskQueue.addTasks(tasks)
            if (syncCompleteHandler != null) {
                syncResultHandlers.add(
                    SyncResults(types) to syncCompleteHandler
                )
            }
        }

        return lock.use {
            if (activeJob == null) {
                lastSyncStartTime = now()
                numFalseStarts = 0

                return@use coroutineScope.launch {
                    this@SyncCoordinator.logInfo("Starting sync")

                    syncLifecycleChangeHandler?.invoke(true)

                    VersionService.postDeviceVersionIfNeeded()

                    val syncStatuses = syncStatusDao.findAll()
                        .many()
                        .associateBy { SyncableEntity.valueOf(it.syncType) }
                        .toMutableMap()

                    // The sync status might not yet exist if this is first use, or if we have added
                    // a new type of sync as a migration. Create it and save it if this is the case
                    SyncableEntity.entries.forEach { type ->
                        if (syncStatuses[type] == null) {
                            val newSyncStatus = DbSyncStatus(
                                id = SyncStatusId(0),
                                syncType = type.name,
                                lastSuccessfulFullSync = Instant.fromEpochMilliseconds(0),
                                lastSyncedDown = Instant.fromEpochSeconds(0),
                            )
                            val newId = Database.transactionWithReturn {
                                syncStatusDao.insert(newSyncStatus)
                                syncStatusDao.lastInsertRowId().one()
                            }
                            syncStatuses[type] = newSyncStatus.copy(id = SyncStatusId(newId))
                        }
                    }

                    // Single-threaded for now
                    while (taskQueue.hasPendingTasks) {
                        if (syncAborting) {
                            return@launch
                        }

                        val task = taskQueue.startTask() ?: run {
                            this@SyncCoordinator.logInfo("No more tasks to start. Ending sync")
                            endSync()
                            return@launch
                        }

                        processStatusChange(task, PENDING)

                        val syncStatus = syncStatuses[task.type]
                        if (syncStatus == null) {
                            this@SyncCoordinator.logCrit("No sync status found for sync entity '${task.type}'!")
                            continue
                        }

                        val strategy = entityTypeToStrategy[task.type]
                        if (strategy == null) {
                            this@SyncCoordinator.logCrit("No sync strategy configured to handle sync entity '${task.type}'!")
                            processStatusChange(task, FAILURE)
                            continue
                        }

                        if (task.syncDirection.includes(DOWN) && strategy is SyncDownStrategy) {
                            try {
                                val updatedStatus = syncDown(strategy, task, syncStatus, onPageSyncedHandler)
                                if (updatedStatus != null) {
                                    syncStatuses[SyncableEntity.valueOf(updatedStatus.syncType)] = updatedStatus
                                } else {
                                    // If the sync down wasn't successful, then let's not attempt the sync up.
                                    continue
                                }
                            } catch (e: Exception) {
                                if (e.isNoInternetException()) {
                                    this@SyncCoordinator.logError("Sync failed from lack of internet")
                                    processStatusChange(task, FAILURE)
                                    abortSync()

                                    return@launch
                                } else {
                                    this@SyncCoordinator.logError("Sync down failed from non-benign reason!", e)
                                    processStatusChange(task, FAILURE)

                                    continue
                                }
                            }
                        }

                        if (task.syncDirection.includes(UP) && strategy is SyncUpStrategy) {
                            try {
                                strategy.syncUp()
                            } catch (e: Exception) {
                                this@SyncCoordinator.logError("Failed to sync up ${task.type}!", e)
                                processStatusChange(task, FAILURE)
                                continue
                            }
                        }

                        processStatusChange(task, SUCCESS, syncStatus)
                    }

                    // Check the pending tasks again as we were not in a lock last time.
                    // This is currently pointless in this single-threaded loop, but it could
                    // be a problem if we add a 2nd thread to the mix.
                    if (!taskQueue.hasPendingTasks) {
                        this@SyncCoordinator.logDebug("No more tasks to start. Ending sync")
                        endSync()
                    }
                    return@launch
                }.also { activeJob = it }
            } else {
                numFalseStarts++

                val syncStartTime = lastSyncStartTime ?: run {
                    SyncCoordinator.logCrit("syncStartTime was null despite the sync being running!")
                    now()
                }

                val difference = now() - syncStartTime
                if (numFalseStarts > 3 && difference > 10.minutes) {
                    logCrit("Sync appears to have hung! Failed starts: $numFalseStarts. Minutes hung: ${difference.inWholeMinutes}")
                    abortSync()
                    return CancelledJob()
                }

                activeJob!!
            }
        }
    }

    private suspend fun syncDown(
        strategy: SyncDownStrategy,
        task: SyncTask,
        syncStatus: DbSyncStatus,
        onPageSyncedHandler: PageSyncHandler?,
    ): DbSyncStatus? {
        val lastChange = lmtLock.withLock {
            try {
                getLmtForEndpoint(task.type)
            } catch (e: Exception) {
                if (e.isBenignException()) {
                    throw e
                }

                this@SyncCoordinator.logError("Failed to fetch from LMT!", e)
                null
            }
        }

        if (lastChange == null) {
            this@SyncCoordinator.logCrit("No last timestamp found for type '${task.type}'!")
            processStatusChange(task, FAILURE)
            return null
        }

        if (lastChange <= syncStatus.lastSyncedDown) {
            return syncStatus
        }

        return try {
            strategy.syncDown(syncStatus, onPageSyncedHandler ?: { _, _ ->})

            val updatedStatus = syncStatus.copy(lastSyncedDown = lastChange)
            syncStatusDao.updateLastSyncedDown(lastChange, updatedStatus.id)

            broadcastPassiveSyncEvent(strategy.syncType)

            updatedStatus
        } catch (e: Throwable) {
            this@SyncCoordinator.logError("Failed to sync down ${task.type}!", e)
            processStatusChange(task, FAILURE)

            // If we're first syncing, then it's pretty important that we get everything.
            // So just stop the sync early if something went wrong.
            if (!hasFirstSynced()) {
                abortSync()
            }

            null
        }
    }

    private suspend fun endSync() {
        activeJob = null

        Database.forceSave()

        syncLifecycleChangeHandler?.invoke(false)

        MarkListenedService.retryFailedListens()
    }

    internal fun abortSync() {
        if (activeJob == null) return

        logWarn("Aborting active sync")
        syncAborting = true

        while (taskQueue.size > 0) {
            val task = taskQueue.startTask() ?: break
            processStatusChange(task, FAILURE)
        }

        activeJob = null
        numFalseStarts = 0

        syncAborting = false
    }

    private suspend fun getLmtForEndpoint(entity: TaskQueueEntity): Instant {
        // This will not be found if the sync was not already running.
        // It will also not be found if the sync WAS already running, but new things were added to it afterwards
        currentLmt[entity.apiName]?.let { return it }

        logDebug("Fetching LMT for all queued items that do not have one")

        // Now that we know we have at least one thing missing from LMT, find everything missing and fetch them at once.
        val missingTypes = taskQueue
            .syncDownTaskTypes
            .map { it.apiName }
            .filter { currentLmt[it] == null }

        val params = mapOf("entity-types" to missingTypes)
        val timestamps: LastModifiedTimeResponse = Api.get("sync/last-modified", params)
        currentLmt.putAll(timestamps.lastModifiedTimestamps)

        return currentLmt.getValue(entity.apiName)
    }

    private fun processStatusChange(task: SyncTask, status: SyncResultStatus, syncStatus: DbSyncStatus? = null) {
        if (status == FAILURE) {
            logDebug("Processing status change of ${task.type} to $status")
        }

        if (task.syncDirection == BOTH && status == SUCCESS && syncStatus != null) {
            syncStatusDao.updateLastFullSync(now(), syncStatus.id)
        }

        val statusesToChange = if (status == FAILURE) {
            taskQueue.failTask(task)
        } else {
            if (status == SUCCESS) {
                taskQueue.completeTask(task)
            }
            setOf(task.type)
        }

        if (status == FAILURE || status == SUCCESS) {
            statusesToChange.forEach { changingStatus ->
                changingStatus.apiName.let { currentLmt.remove(it) }
            }
        }

        val iterator = syncResultHandlers.listIterator()
        iterator.forEach { (conditions, handler) ->
            statusesToChange.forEach {
                conditions.transitionStatus(it as SyncableEntity, status)
            }

            if (conditions.isComplete) {
                iterator.remove()

                if (!hasFirstSynced() && conditions.wasSuccessful) {
                    Settings.setBoolean(SettingType.HAS_FIRST_SYNCED, true)
                }

                // We do not want someone who is calling this code to have a very beefy callback that
                // bottlenecks the entire sync process. We want it to do its own thing completely unrelated to us.
                coroutineScope.launch {
                    handler(conditions)
                }
            }
        }
    }

    internal fun reset() {
        lastSyncStartTime = null
    }

    fun getLastSyncString(): String {
        val oldestSuccessfulSync = syncStatusDao
            .findAll()
            .many()
            .minOfOrNull { it.lastSuccessfulFullSync }
            ?: run {
                // This should only be true if you're logged out, or haven't first synced.
                // And I can't imagine any client is going to let you view it there.
                logError("No prior sync performed when getting lastSyncString!")
                return "Never synced"
            }

        return if (oldestSuccessfulSync.epochSeconds == 0L) {
            // This could happen if we added a new syncable entity and a user looks at this
            // string before that entity finishes. It's kinda jank, but it's so niche I don't care.
            "Never fully synced"
        } else {
            oldestSuccessfulSync.toTimeAgoString()
        }
    }

    fun registerPassiveSyncHandler(handler: PassiveSyncChangeHandler): Int {
        val id = (passiveSyncHandlers.keys.maxOrNull() ?: 0) + 1
        passiveSyncHandlers[id] = handler
        return id
    }
    fun unregisterEventHandler(handlerId: Int) {
        passiveSyncHandlers.remove(handlerId)
    }

    private fun broadcastPassiveSyncEvent(type: SyncableEntity) {
        doAsyncIfProduction {
            passiveSyncHandlers.values.forEach { it(type) }
        }
    }
}

@Serializable
internal data class LastModifiedTimeResponse(
    val lastModifiedTimestamps: Map<String, Instant>
)

enum class SyncResultStatus {
    SUCCESS, PENDING, FAILURE, NOT_STARTED
}

open class SyncResults(entities: Set<SyncableEntity>) {
    protected val progress = entities.associateWith { NOT_STARTED }.toMutableMap()

    // This is internal because it should never be exposed outside the library in an incomplete state
    internal val isComplete get() = progress.values.all { it == SUCCESS || it == FAILURE }
    open val wasSuccessful get() = progress.values.all { it == SUCCESS }

    open val successfulEntities get() = progress.filter { (_, status) -> status == SUCCESS }
    open val failedEntities get() = progress.filter { (_, status) -> status == FAILURE }

    internal fun transitionStatus(entity: SyncableEntity, newStatus: SyncResultStatus) {
        if (!progress.contains(entity)) {
            return
        }

        val existingStatus = progress.getValue(entity)

        if (existingStatus == NOT_STARTED && newStatus == PENDING) {
            progress[entity] = PENDING
        } else if (
            (existingStatus == NOT_STARTED || existingStatus == PENDING)
            && (newStatus == SUCCESS || newStatus == FAILURE)
        ) {
            progress[entity] = newStatus
        }
    }
}

class AbortedSyncResult(entities: Set<SyncableEntity>) : SyncResults(entities) {
    override val wasSuccessful: Boolean get() = false
    override val successfulEntities: Map<SyncableEntity, SyncResultStatus> get() = emptyMap()
    override val failedEntities: Map<SyncableEntity, SyncResultStatus> get() = progress
}
