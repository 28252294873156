package net.gorillagroove.reporting

import net.gorillagroove.util.GGLog.logDebug
import net.gorillagroove.util.GGLog.logWarn

typealias DialogEventHandler = (eventData: DialogEventData) -> Unit

@Suppress("VARIABLE_IN_SINGLETON_WITHOUT_THREAD_LOCAL")
object DialogEventBus {
    private var dialogEventHandler: DialogEventHandler? = null

    fun registerDialogEventHandler(dialogEventHandler: DialogEventHandler?) {
        this.dialogEventHandler = dialogEventHandler
    }

    internal fun broadcast(dialogEventData: DialogEventData) {
        dialogEventHandler?.let { eventHandler ->
            logDebug("Broadcasting dialog event with title: '${dialogEventData.title}'")
            eventHandler(dialogEventData)
        } ?: logWarn("No DialogEventBus handler registered")
    }
}

data class DialogEventData(
    val title: String? = null,
    val message: String? = null,
    val yesText: String? = null,
    val noText: String? = null,
    val blockingMode: Boolean = true,
    val yesAction: () -> Unit = {},
    val noAction: () -> Unit = {},
)
