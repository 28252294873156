import components.SocketUtil
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import net.gorillagroove.sync.SyncCoordinator
import net.gorillagroove.track.OfflineModeService
import kotlin.time.Duration.Companion.minutes
import kotlin.time.Duration.Companion.seconds

object AuthUtil {
    private var setupRun = false

    fun setupAuthenticatedUser() {
        if (!setupRun) {
            SocketUtil.init()
            MediaSessionService.setupMediaSession()

            setupRun = true
        }

        SocketUtil.connect()

        mainScope.launch {
            delay(4.seconds)
            SyncCoordinator.sync(throttle = 1.minutes)

            OfflineModeService.downloadAlwaysOfflineTracks()
        }
    }

    fun beforeLogout() {
        SocketUtil.disconnect()
    }
}
