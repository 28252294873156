package components

import PageRouter
import ViewMode
import kotlinx.browser.document
import kotlinx.coroutines.launch
import kotlinx.html.div
import kotlinx.html.dom.append
import kotlinx.html.dom.create
import kotlinx.html.id
import kotlinx.html.js.div
import mainScope
import net.gorillagroove.authentication.AuthService
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement

object SiteLayout {
    private val mainContent get() = document.getElementById("main-content-wrapper") as HTMLElement

    val documentOriginalTitle = document.title

    // I feel dumb, but I cannot figure out how to reference an object's function from outside of it.
    // The code just does not import / compile correctly.
    val renderRef = ::render

    private fun render(): HTMLDivElement {
        // Not logged in. Redirect
        if (!AuthService.isAuthenticated()) {
            PageRouter.navigateTo("/login")
            return document.create.div {  }
        }

        mainScope.launch {
            renderMainContent(null, PageRouter.currentViewMode)
        }

        return document.create.div {
            id = "site-layout"

            div {
                id = "mid-row"

                LeftNav()

                div {
                    id = "main-content-wrapper"
                }
            }

            Footer()
        }
    }

    fun renderMainContent(previousMode: ViewMode?, newMode: ViewMode) {
        if (previousMode != null && previousMode.isTrackView && newMode.isTrackView) {
            TrackTable.updateViewMode()
            LibraryHeader.updateFromViewTypeChange()
        } else {
            mainContent.innerHTML = ""
            if (newMode.isTrackView) {
                mainContent.append {
                    LibraryHeader()
                    TrackTable()
                }
            } else if (newMode == ViewMode.SETTINGS) {
                mainContent.append {
                    SettingsPage()
                }
            } else if (newMode == ViewMode.SPOTIFY_SEARCH) {
                mainContent.append {
                    SpotifySearchPage()
                }
            } else if (newMode == ViewMode.TRACK_HISTORY) {
                mainContent.append {
                    TrackHistoryPage()
                }
            } else if (newMode == ViewMode.DEVICE_MANAGEMENT) {
                mainContent.append {
                    DeviceManagementPage()
                }
            } else if (newMode == ViewMode.DOWNLOAD_SERVER) {
                mainContent.append {
                    DownloadServerPage()
                }
            } else if (newMode == ViewMode.PLAY_SESSION) {
                mainContent.append {
                    PlaySessionPage()
                }
            }
        }
    }
}
