package components

import addEventListener
import hide
import kotlinx.browser.document
import kotlinx.html.*
import kotlinx.html.dom.append
import kotlinx.html.js.*
import kotlinx.html.li
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.HTMLUListElement
import query
import show

class Multiselect<T>(
    private val data: List<T>,
    private val categoryName: String,
    private val displayFunction: (T) -> String,
) {
    private val selectId = nextId++
    private val self get() = document.getElementById("multiselect-$selectId") as HTMLElement?

    private val selectedIndexes = mutableSetOf<Int>()

    private val inputContainer get() = self!!.query<HTMLElement>(".multiselect-input")
    private val input get() = inputContainer.query<HTMLInputElement>("input")
    private val selectedItemsDisplay get() = self!!.query<HTMLElement>(".multiselect-selected-items")

    private val dropdownContainer get() = self!!.query<HTMLElement>(".multiselect-dropdown")

    private val clearIcon get() = self!!.query<HTMLElement>(".fa-xmark")
    private val caretIcon get() = self!!.query<HTMLElement>(".fa-caret-down")

    private var searchTerm = ""

    fun render(attachmentPoint: FlowContent, classes: String = "") = attachmentPoint.div("multiselect $classes") {
        id = "multiselect-$selectId"

        onClickFunction = { it.stopPropagation() }

        div("multiselect-input") {
            input(InputType.text) {
                onInputFunction = {
                    searchTerm = (it.currentTarget as HTMLInputElement).value
                    renderSelectItems()
                }

                onFocusFunction = {
                    open()
                    renderSelectItems()
                }
            }
            i("fa-solid fa-xmark d-none") {
                onClickFunction = {
                    searchTerm = ""
                    input.value = ""
                    renderSelectItems()
                }
            }

            i("fa-solid fa-caret-down")

            div("multiselect-selected-items full-center") {
                + "None selected"
            }
        }

        div("multiselect-dropdown d-none") {
            ul { }
        }
    }

    private fun renderSelectItems() {
        val self = self ?: return

        val list = self.query<HTMLUListElement>("ul")
        list.innerHTML = ""

        if (searchTerm.isNotBlank()) {
            clearIcon.show()
        } else {
            clearIcon.hide()
        }

        list.append {
            data.forEachIndexed { index, datum ->
                if (searchTerm.isNotBlank() && !displayFunction(datum).contains(searchTerm, ignoreCase = true)) {
                    return@forEachIndexed
                }

                li {
                    label {
                        input(InputType.checkBox) {
                            checked = selectedIndexes.contains(index)

                            onChangeFunction = { event ->
                                val checked = (event.currentTarget!! as HTMLInputElement).checked
                                if (checked) {
                                    selectedIndexes.add(index)
                                } else {
                                    selectedIndexes.remove(index)
                                }
                            }
                        }
                        span { +displayFunction(datum) }
                    }
                }
            }
        }
    }

    fun open() {
        if (self == null) {
            return
        }

        setInputopen()
        renderSelectItems()
        self?.query<HTMLInputElement>("input")?.focus()
    }

    private fun setInputopen() {
        if (self == null) {
            return
        }

        caretIcon.hide()
        dropdownContainer.show()
        selectedItemsDisplay.hide()
        input.value = searchTerm
        openedDropdown = this@Multiselect
    }

    private fun close() {
        if (self == null) {
            return
        }

        caretIcon.show()
        clearIcon.hide()
        dropdownContainer.hide()
        if (selectedIndexes.isNotEmpty()) {
            input.value = ""
        }
        selectedItemsDisplay.show()

        val text = if (selectedIndexes.isEmpty()) {
            "None selected"
        } else if (selectedIndexes.size == 1) {
            displayFunction(data[selectedIndexes.first()])
        } else {
            "${selectedIndexes.size} $categoryName selected"
        }

        selectedItemsDisplay.innerText = text
    }

    fun getSelectedItems(): List<T> {
        return selectedIndexes.map { data[it] }
    }

    companion object {
        private var nextId = 0

        private var openedDropdown: Multiselect<*>? = null

        fun close() {
            openedDropdown?.close()
            openedDropdown = null
        }

        init {
            document.body!!.addEventListener("click") {
                close()
            }
        }
    }
}
