package components.contextmenu

import Dialog
import PageRouter
import Toast
import ViewMode
import components.*
import kotlinx.browser.window
import kotlinx.coroutines.launch
import mainScope
import net.gorillagroove.discovery.ImportService
import net.gorillagroove.playlist.LoadedPlaylistTrack
import net.gorillagroove.playlist.PlaylistService
import net.gorillagroove.review.ReviewQueueService
import net.gorillagroove.track.*
import net.gorillagroove.user.UserService
import net.gorillagroove.util.Formatter
import net.gorillagroove.util.GGLog
import net.gorillagroove.util.GGLog.logError
import net.gorillagroove.util.collapseBoolean
import org.w3c.dom.events.MouseEvent
import util.ByteUtil.asByteArray
import util.ByteUtil.download

object TrackContextMenu {
    private fun getSelectedTracks() = TrackTable.getSelectedTracks().map { it.asTrack() }
    private fun getSelectedTrackSortables() = TrackTable.getSelectedTracks().map { it }

    private var tracks: List<Track> = emptyList()
    private var allOwned: Boolean = false
    private var allNotOwned: Boolean = false
    private var inReview: Boolean? = false
    private var fromLeftNav: Boolean = false

    class TrackMenuOption(
        override val name: String,
        override val action: () -> Unit,
        val shouldShow: () -> Boolean = { true },
    ) : ContextMenuOption(name, action)

    private val options = listOf(
        TrackMenuOption(
            name = "Play Now",
            action = {
                // This operation is kind of undefined on the NOW_PLAYING screen.... It's a bit of an open
                // question on what it should do. But everybody agrees that it shouldn't trim out the rest of
                // the music that is already playing. The simplest acceptable solution is to just treat
                // "Play Now" on this screen as if you double-clicked the entry instead. This then raises the
                // question of "if I've selected multiple things, what thing did I double-click?". And I am just
                // going to arbitrarily pick the first one.
                if (PageRouter.currentViewMode == ViewMode.NOW_PLAYING && !fromLeftNav) {
                    val firstTrack = TrackTable.getSelectedTracks().first() as NowPlayingTrack
                    NowPlayingService.playFromNowPlayingId(firstTrack.nowPlayingTrackId)
                } else {
                    NowPlayingService.setNowPlayingTracks(tracks, playFromIndex = 0)
                }
            }
        ),
        TrackMenuOption(
            name = "Play Next",
            action = { NowPlayingService.addTracksNext(tracks) }
        ),
        TrackMenuOption(
            name = "Play Last",
            action = { NowPlayingService.addTracksLast(tracks) }
        ),
        TrackMenuOption(
            name = "Import to Library",
            action = {
                mainScope.launch {
                    try {
                        ImportService.importUserTracks(tracks.mapNotNull { it.apiId })
                        Toast.success("Import successful")
                    } catch (e: Exception) {
                        Toast.error("Failed to import track")
                        GGLog.logError("Failed to import track!", e)
                    }
                }
            },
            // CHeck ownership and not User screen so that you can technically import something on a shared playlist.
            shouldShow = { allNotOwned }
        ),

        TrackMenuOption(
            name = "Get Link",
            action = {
                val track = getSelectedTracks().first()
                mainScope.launch {
                    val link = try {
                        TrackService.forceLinkRegeneration(track.apiId!!)
                    } catch (e: Exception) {
                        Toast.error("Failed to get shareable track link")
                        return@launch
                    }
                    window.navigator.clipboard.writeText(window.location.origin + link)
                    Toast.info("Link copied to clipboard")
                }
            },
        ),
        TrackMenuOption(
            name = "Approve",
            action = {
                mainScope.launch {
                    ReviewQueueService.frontendApproveTrack(tracks)
                }
            },
            shouldShow = { inReview == true && allOwned }
        ),
        TrackMenuOption(
            name = "Reject",
            action = {
                mainScope.launch {
                    ReviewQueueService.frontendRejectTrack(tracks)
                }
            },
            shouldShow = { inReview == true && allOwned }
        ),
        TrackMenuOption(
            name = "Remove from Playlist",
            action = {
                val playlistTracks = getSelectedTrackSortables().map { it as LoadedPlaylistTrack }

                mainScope.launch {
                    try {
                        PlaylistService.removeTracks(playlistTracks.map { it.playlistTrack.id })
                    } catch (e: Exception) {
                        Toast.error("Failed to remove from playlist")
                        return@launch
                    }

                    val trackName = if (playlistTracks.size == 1) {
                        "'${playlistTracks.first().track.name}'"
                    } else {
                        "${playlistTracks.size} tracks"
                    }

                    Toast.success("Removed $trackName from playlist")

                    playlistTracks.forEach {
                        TrackTable.removeTrack(it)
                    }
                }
            },
            shouldShow = { inReview == false && PageRouter.currentViewMode == ViewMode.PLAYLISTS && !fromLeftNav }
        ),
        TrackMenuOption(
            name = "Add to Playlist",
            action = {
                Dialog.show(AddToPlaylist(getSelectedTracks()))
            },
            shouldShow = { PageRouter.currentViewMode != ViewMode.PLAYLISTS && inReview == false && allOwned && !fromLeftNav }
        ),
        TrackMenuOption(
            name = "Trim",
            action = {
                Dialog.show(TrackTrimModal(tracks.first()))
            },
            shouldShow = { inReview == false && allOwned && tracks.size == 1 && !fromLeftNav }
        ),
        TrackMenuOption(
            name = "Volume",
            action = { Dialog.show(TrackVolumeModal(tracks)) },
            shouldShow = { inReview == false && allOwned && !fromLeftNav }
        ),
        TrackMenuOption(
            name = "Properties",
            action = { Dialog.show(TrackPropertiesModal(tracks).render()) },
            shouldShow = { inReview == false && allOwned && !fromLeftNav }
        ),
        TrackMenuOption(
            name = "Recommend",
            action = { Dialog.show(RecommendTracks(tracks)) },
            shouldShow = { inReview == false }
        ),
        TrackMenuOption(
            name = "Download",
            action = {
                Dialog.show(SelectDialog(
                    message = "Download Tracks",
                    acceptText = "Download",
                    options = listOf(
                        AudioFormat.MP3.name to AudioFormat.MP3.name,
                        AudioFormat.OGG.name to AudioFormat.OGG.name,
                    ),
                    defaultValue = AudioFormat.MP3.name,
                    label = "Audio format",
                    onAccept = { formatString ->
                        val format = AudioFormat.valueOf(formatString)
                        mainScope.launch {
                            val response = TrackService.downloadTracks(tracks, format)
                            response.data.download(response.filename)
                        }
                    }
                ))
            },
            shouldShow = { inReview == false && allOwned && !fromLeftNav }
        ),
        TrackMenuOption(
            name = "Request Metadata",
            action = {
                Dialog.show(MetadataUpdateRequestMenu(tracks))
            },
            shouldShow = { inReview == false && allOwned && !fromLeftNav }
        ),
        TrackMenuOption(
            name = "Replace Audio",
            action = {
                Dialog.promptFileUpload { files ->
                    val file = files[0]

                    ImportService.replaceTrackAudio(
                        trackData = file.asByteArray(),
                        filename = file.name,
                        replacedTrackId = tracks.first().apiId!!
                    )
                }
            },
            shouldShow = { inReview == false && allOwned && tracks.size == 1 && !fromLeftNav }
        ),
        TrackMenuOption(
            name = "Remove",
            action = { NowPlayingService.removeTracksByIndex(TrackTable.getSelectedIndexes()) },
            shouldShow = { PageRouter.currentViewMode == ViewMode.NOW_PLAYING && !fromLeftNav }
        ),
        TrackMenuOption(
            name = "Remove Later Tracks",
            action = { NowPlayingService.removeAllAfterIndex(TrackTable.getSelectedIndexes().first()) },
            shouldShow = { PageRouter.currentViewMode == ViewMode.NOW_PLAYING && TrackTable.getSelectedIndexes().size == 1 && !fromLeftNav }
        ),
        TrackMenuOption(
            name = "Delete",
            action = {
                mainScope.launch {
                    val deleteMessage = if (tracks.size == 1) {
                        "'${Formatter.getPlayingTrackDisplayString(tracks.first())}'"
                    } else {
                        "${tracks.size} tracks"
                    }

                    val plurality = if (tracks.size == 1) "" else "s"

                    Dialog.show(
                        GenericActionDialog(
                            message = "Are you sure you want to delete $deleteMessage?",
                            rejectText = "I guess not",
                            acceptText = "Yeet",
                            destructive = true,
                            onAccept = {
                                try {
                                    TrackService.deleteTrack(tracks)
                                    Toast.success("Track${plurality} successfully deleted")
                                } catch (e: Exception) {
                                    Toast.error("Failed to delete track${plurality}")
                                    GGLog.logError("Failed to delete tracks!", e)

                                    // Rethrow so that the ActionDialog knows it failed
                                    throw e
                                }
                            },
                        )
                    )
                }
            },
            shouldShow = { inReview == false && allOwned && !fromLeftNav }
        ),
        TrackMenuOption(
            name = "Add To Review",
            action = {
                val track = tracks.firstOrNull() ?: return@TrackMenuOption

                mainScope.launch {
                    try {
                        ReviewQueueService.recommendTracks(
                            trackIds = listOf(track.apiId!!),
                            userIds = listOf(UserService.requireCurrentUserId()),
                            note = null,
                        )
                        Toast.success("'${track.name}' was put into review")
                    } catch (e: Exception) {
                        Toast.error("Failed to add track for review")
                    }
                }
            },
            shouldShow = { fromLeftNav && allNotOwned }
        ),
    )

    fun open(event: MouseEvent, fromLeftNav: Boolean = false, trackOverride: List<Track>? = null) {
        tracks = trackOverride ?: getSelectedTracks()

        inReview = tracks.collapseBoolean { it.inReview }
        allOwned = true
        allNotOwned = true
        this.fromLeftNav = fromLeftNav

        tracks.forEach {
            if (it.userId == UserService.requireCurrentUserId()) {
                allNotOwned = false
            } else {
                allOwned = false
            }
        }

        val filteredOptions = options.filter { it.shouldShow() }

        ContextMenu.open(event, filteredOptions)
    }

    fun handlePlayNow() {

    }
}

open class ContextMenuOption(open val name: String, open val action: () -> Unit)

suspend fun ReviewQueueService.frontendApproveTrack(tracks: Collection<Track>) {
    approveReviewTracks(tracks)

    val message = if (tracks.size == 1) "'${tracks.first().name}'" else "${tracks.size} tracks"

    Toast.success("$message approved")

    LeftNav.updateReviewCount()

    // When we approve a track, it stays around in the NowPlaying. So we only want
    // to remove it if it was on the REVIEW_QUEUE view.
    if (PageRouter.currentViewMode == ViewMode.REVIEW_QUEUE) {
        tracks.forEach { track ->
            TrackTable.removeTrack(track)
        }
    }
}

suspend fun ReviewQueueService.frontendRejectTrack(tracks: Collection<Track>) {
    rejectReviewTracks(tracks)

    val message = if (tracks.size == 1) "'${tracks.first().name}'" else "${tracks.size} tracks"
    Toast.info("$message rejected")

    // When rejecting a track, we want to remove it from all views.
    // Though this really just means NowPlaying and ReviewQueue as it's not visible any other way.
    tracks.forEach { track ->
        TrackTable.removeTrack(track)
    }

    LeftNav.updateReviewCount()
}
