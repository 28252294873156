@file:Suppress("FunctionName")

package components

import hide
import kotlinx.browser.document
import kotlinx.coroutines.launch
import kotlinx.datetime.*
import kotlinx.html.*
import kotlinx.html.dom.append
import mainScope
import net.gorillagroove.api.UserId
import net.gorillagroove.track.TrackHistory
import net.gorillagroove.track.TrackHistoryService
import net.gorillagroove.user.UserService
import net.gorillagroove.util.Formatter
import net.gorillagroove.util.GGLog
import net.gorillagroove.util.GGLog.logError
import net.gorillagroove.util.TimeUtil.current
import onChangeSuspend
import onClickSuspend
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.HTMLSelectElement
import queryId
import show
import kotlin.text.Typography.nbsp
import kotlin.time.Duration.Companion.days

private val loadingSpinner get() = document.queryId<HTMLElement>("history-loader")

fun TagConsumer<*>.TrackHistoryPage() = div("full-height") {
    id = "track-history-screen"

    TrackHistoryPage.reset()

    HistoryHeader()

    div {
        id = "history-table-wrapper"

        LoadingSpinner(id = "history-loader")

        table("page-table") {
            thead {
                id = "track-history-table-head"
            }

            tbody {
                id = "track-history-table-body"
            }
        }
    }

    mainScope.launch {
        TrackHistoryPage.fullRender()
    }
}

private fun TagConsumer<*>.HistoryHeader() = div {
    id = "track-history-header"

    div("history-input-wrapper d-flex") {
        label {
            div("mb-4") { + "User" }
            select {
                id = "user-select"

                option {
                    + ""
                    value = ""
                }

                UserService.findAll().forEach { user ->

                    option {
                        + user.name

                        value = user.id.value.toString()
                        selected = user.id == TrackHistoryPage.currentUserId
                    }
                }

                onChangeSuspend = { event ->
                    val select = (event.target as HTMLSelectElement)
                    val userId = select.value.takeIf { it.isNotBlank() }?.let { UserId(it.toLong()) }

                    TrackHistoryPage.currentUserId = userId
                    TrackHistoryPage.fullRender()
                }
            }
        }

        label {
            div("mb-4") { + "Start Date" }
            input(InputType.date) {
                id = "start-date"

                value = TrackHistoryPage.startDate.toString()

                onChangeSuspend = {
                    TrackHistoryPage.startDate = (it.target as HTMLInputElement).value.toLocalDate()
                    TrackHistoryPage.fullRender()
                }
            }
        }

        label {
            div("mb-4") { + "End Date" }
            input(InputType.date) {
                id = "end-date"

                value = TrackHistoryPage.endDate.toString()

                onChangeSuspend = {
                    TrackHistoryPage.endDate = (it.target as HTMLInputElement).value.toLocalDate()
                    TrackHistoryPage.fullRender()
                }
            }
        }
    }
}

private val trackTableHead get() = document.queryId("track-history-table-head") as HTMLElement
private val trackTableBody get() = document.queryId("track-history-table-body") as HTMLElement

private fun renderHead() {
    val head = trackTableHead
    head.innerHTML = ""
    head.append {
        tr {
            if (TrackHistoryPage.currentUserId == null) {
                th {
                    span { +"User" }
                }
            }

            th {
                span { +"Name" }
            }
            th {
                span { +"Artist" }
            }
            th {
                span { +"Album" }
            }
            th {
                span { +"Listened Date" }
            }
            th {
                span { +"Device" }
            }
            th {
                // The scrollbars on macs can cover the button if we don't make this wider. It probably
                // looks better this way anyway. Sorry for using an inline style, Audrey.
                style = "width:40px"

                span {
                    // Apparently if you don't have it in both places, it looks weird. Tables are so weird, man.
                    style = "width:40px"

                    // nbsp or else it won't take up all the vertical space
                    +nbsp.toString()
                }
            }
        }
    }
}

private fun renderBody(history: List<TrackHistory>) {
    val userIdNames = if (TrackHistoryPage.currentUserId == null) {
        UserService.findAll().associate { it.id to it.name }
    } else {
        emptyMap()
    }

    val body = trackTableBody
    body.innerHTML = ""
    body.append {
        history.forEach { item ->
            tr {
                attributes["track-history-id"] = item.id.value.toString()

                if (TrackHistoryPage.currentUserId == null) {
                    td {
                        + (userIdNames[item.track.userId] ?: "")
                    }
                }

                td { + Formatter.getTrackNameDisplayString(item.track) }
                td { + Formatter.getTrackArtistDisplayString(item.track) }
                td { + item.track.album }
                td { + Formatter.formatToUserDateFormat(item.listenedDate) }
                td { + (item.deviceName ?: "") }
                td {
                    if (item.track.isOwnTrack()) {
                        button(classes = "icon action-button") {
                            i("fa-solid fa-xmark")

                            onClickSuspend = onClickSuspend@{
                                try {
                                    TrackHistoryService.deleteHistory(item.id)
                                } catch (e: Exception) {
                                    GGLog.logError("Failed to delete history item!", e)
                                    Toast.error("Failed to delete history")
                                    return@onClickSuspend
                                }

                                trackTableBody.querySelector("tr[track-history-id=\"${item.id.value}\"]")?.remove()
                            }
                        }
                    }
                }
            }
        }
    }
}

private object TrackHistoryPage {
    private var lastUserId: UserId? = null
    var currentUserId: UserId? = UserService.getCurrentUserId()

    lateinit var startDate: LocalDate
    lateinit var endDate: LocalDate

    private var needsHeadRender = true

    suspend fun fullRender() {
        loadingSpinner.show()
        val history = getHistory()

        // The head of the table only changes if we toggle between looking at all users or a single user.
        // Do a small optimization and only render it if we know this has changed.
        val renderHead = needsHeadRender
                || (lastUserId == null && currentUserId != null)
                || (lastUserId != null && currentUserId == null)

        if (renderHead) {
            renderHead()
        }

        renderBody(history)

        loadingSpinner.hide()

        lastUserId = currentUserId
    }

    private suspend fun getHistory(): List<TrackHistory> {
        val startDate = document.queryId<HTMLInputElement>("start-date").value
        val endDate = document.queryId<HTMLInputElement>("end-date").value

        val start = startDate.toLocalDate().atStartOfDayIn(TimeZone.currentSystemDefault())
        val end = endDate.toLocalDate().atStartOfDayIn(TimeZone.currentSystemDefault()).plus(1.days)

        return TrackHistoryService.getHistory(
            startDate = start,
            endDate = end,
            userId = currentUserId,
        )
    }

    fun reset() {
        startDate = LocalDate.current().minus(60, DateTimeUnit.DAY)
        endDate = LocalDate.current()

        needsHeadRender = true
    }
}
