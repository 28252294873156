@file:Suppress("FunctionName")

package components

import Dialog
import Toast
import kotlinx.browser.document
import kotlinx.datetime.Clock.System.now
import kotlinx.html.*
import kotlinx.html.dom.create
import kotlinx.html.js.div
import net.gorillagroove.review.ReviewQueueService
import net.gorillagroove.track.Track
import net.gorillagroove.user.UserService
import net.gorillagroove.util.GGLog
import net.gorillagroove.util.GGLog.logError
import net.gorillagroove.util.takeIfNotBlank
import org.w3c.dom.HTMLInputElement
import queryId

fun RecommendTracks(tracks: List<Track>) = document.create.div {
    val trackName = if (tracks.size == 1) "'${tracks.first().name}'" else "${tracks.size} tracks"

    // You can recommend anybody's tracks to anybody else, including yourself.
    // But it is invalid to recommend a track to someone who already has that track.
    val usersToExclude = tracks.map { it.userId }.toSet()

    val usersToShow = UserService.findExcludingIds(usersToExclude, excludeInactive = true)

    val select = Multiselect(data = usersToShow, "users") { it.name }

    id = "recommend-tracks-modal"

    h3 {
        + "Recommend Tracks"
    }

    div {
        label("d-block text-left mb-6") {
            id = "recommend-tracks-dropdown"

            + "Pick some users"

            select.render(this, classes = "mr-12 mt-6")
        }

        label("d-block text-left mt-12 mb-6") {
            + "Send a note?"

            textArea(classes = "d-block") {
                id = "recommend-note"
            }
        }

        div("space-around") {
            ActionButton("recommend-tracks-button", "Send", classes = "ml-12 mt-12") {
                val users = select.getSelectedItems()
                if (users.isEmpty()) {
                    return@ActionButton
                }

                val userName = if (users.size == 1) "'${users.first().name}'" else "${users.size} users"

                val note = document.queryId<HTMLInputElement>("recommend-note").value.takeIfNotBlank()

                try {
                    ReviewQueueService.recommendTracks(tracks.mapNotNull { it.apiId }, users.map { it.id }, note = note)
                } catch (e: Exception) {
                    Toast.error("Failed to recommend $trackName to $userName")
                    GGLog.logError("Failed to recommend tracks!", e)

                    return@ActionButton
                }

                Toast.success("Recommended $trackName to $userName")
                Dialog.remove()
            }
        }
    }
}
