@file:Suppress("FunctionName")

package components

import Toast
import components.components.SearchInput
import hide
import kotlinx.browser.document
import kotlinx.coroutines.launch
import kotlinx.html.*
import kotlinx.html.dom.append
import kotlinx.html.js.onClickFunction
import mainScope
import net.gorillagroove.discovery.*
import net.gorillagroove.util.GGLog
import net.gorillagroove.util.GGLog.logError
import onClickSuspend
import org.w3c.dom.HTMLAudioElement
import org.w3c.dom.HTMLElement
import org.w3c.dom.asList
import query
import queryId
import show
import kotlin.text.Typography.nbsp
import kotlin.time.Duration.Companion.milliseconds

private val loadingSpinner get() = document.queryId<HTMLElement>("spotify-loader")
private val spotifyAudio get() = document.queryId<HTMLAudioElement>("spotify-preview-audio")

fun TagConsumer<*>.SpotifySearchPage() = div("full-height") {
    id = "spotify-search-page"

    SpotifyHeader()

    audio {
        id = "spotify-preview-audio"
    }

    div {
        id = "spotify-table-wrapper"

        LoadingSpinner(id = "spotify-loader")

        table {
            thead {
                tr {
                    th {
                        span { + nbsp.toString() }
                    }
                    th {
                        span { +"Name"}
                    }
                    th {
                        span { +"Artist" }
                    }
                    th {
                        span { +"Album" }
                    }
                    th {
                        span { +"Year" }
                    }
                    th {
                        span { + nbsp.toString() }
                    }
                }
            }
            tbody {
                id = "spotify-search-table-body"
            }
        }
    }

    // We may already have state here from visiting the page a prior time
    mainScope.launch {
        SpotifySearchPage.render()
    }
}

private val tableBody get() = document.queryId<HTMLElement>("spotify-search-table-body")

private fun TagConsumer<*>.SpotifyHeader() = div {
    id = "spotify-search-header"

    div("history-input-wrapper d-flex") {
        // I put the debounce a bit higher than normal (300 millis) because the Spotify API does have a limit
        // on the number of requests we can make in a given timeframe. I forget how many, exactly. But I'd like
        // to cut down on the number of wasted requests, just in case.
        SearchInput(SpotifySearchPage::searchTerm, 300.milliseconds) {
            mainScope.launch {
                SpotifySearchPage.searchSpotify()
            }
        }
    }
}

private object SpotifySearchPage {
    var searchResults: List<MetadataResponseDTO> = emptyList()

    var searchTerm: String = ""

    suspend fun searchSpotify() {
        val term = searchTerm
        if (term.length > 1) {
            loadingSpinner.show()

            searchResults = try {
                SpotifySearchService.searchByArtist(term).items
            } catch (e: Exception) {
                logError("Failed to fetch Spotify results for artist '$term'!", e)
                Toast.error("Failed to search Spotify")
                loadingSpinner.hide()
                return
            }

            loadingSpinner.hide()
            render()
        }
    }

    private fun resetAllIcons() {
        tableBody.querySelectorAll("i.fa-pause").asList().forEach { icon ->
            icon as HTMLElement
            icon.classList.remove("fa-pause")
            icon.classList.add("fa-play")
        }
    }

    private suspend fun startDownload(item: MetadataResponseDTO) {
        val spinner = document.queryId<HTMLElement>("loading-spinner-${item.sourceId}")
        val button = document.queryId<HTMLElement>("search-button-${item.sourceId}")

        button.hide()
        spinner.show()

        val videoMatch = try {
            YoutubeService.findVideoForArtistWithLength(
                artist = item.artist,
                length = item.length,
                trackName = item.name,
            )
        } catch (e: Exception) {
            Toast.error("Failed to search for matching video")
            GGLog.logError("Failed to find YouTube video for track '${item.artist} - ${item.name}'!", e)

            button.show()
            spinner.hide()
            return
        }

        val properties = videoMatch.videoProperties ?: run {
            spinner.hide()
            Toast.error("No suitable YouTube video found")
            return
        }

        val request = TrackImportRequest(
            url = properties.videoUrl,
            name = item.name,
            artist = item.artist,
            album = item.album,
            releaseYear = item.releaseYear,
            trackNumber = item.trackNumber,
            artUrl = item.albumArtLink,
        )

        try {
            ImportService.downloadFromUrl(request)
            Toast.info("Import started")
        } catch (e: Exception) {
            Toast.error("Failed to start import")
            GGLog.logError("Failed to start track import!", e)

            button.show()
        }

        spinner.hide()
    }

    fun render() {
        val body = tableBody
        body.innerHTML = ""
        body.append {
            searchResults.forEach { result ->
                tr {
                    td {
                        button {
                            val iconClass = if (result.previewUrl != null) "fa-play" else "fa-ban"
                            i("fa-solid $iconClass")

                            onClickFunction = onClick@{ event ->
                                if (result.previewUrl == null) {
                                    return@onClick
                                }
                                resetAllIcons()

                                val icon = (event.currentTarget as HTMLElement).query<HTMLElement>("i")

                                val isPlaying = spotifyAudio.src == result.previewUrl
                                if (isPlaying) {
                                    spotifyAudio.pause()
                                    spotifyAudio.src = ""

                                    icon.classList.add("fa-play")
                                    icon.classList.remove("fa-pause")
                                } else {
                                    spotifyAudio.src = result.previewUrl!!
                                    spotifyAudio.play()

                                    icon.classList.remove("fa-play")
                                    icon.classList.add("fa-pause")
                                }
                            }
                        }
                    }
                    td { + result.name }
                    td { + result.artist }
                    td { + result.album }
                    td { + result.releaseYear.toString() }
                    td("text-center") {
                        LoadingSpinner(id = "loading-spinner-${result.sourceId}")

                        button(classes = "flat slim") {
                            id = "search-button-${result.sourceId}"

                            + "Import"

                            onClickSuspend = { startDownload(result) }
                        }
                    }
                }
            }
        }
    }
}
