@file:Suppress("FunctionName")

package components

import hide
import invisible
import kotlinx.browser.document
import kotlinx.html.*
import onClickSuspend
import org.w3c.dom.HTMLElement
import org.w3c.dom.events.Event
import show
import visible

fun FlowOrInteractiveOrPhrasingContent.ActionButton(
    id: String,
    text: String,
    type: ButtonType = ButtonType.submit,
    actionType: ActionButtonType = ActionButtonType.PRIMARY,
    classes: String = "",
    onClick: (suspend (Event) -> Unit)? = null,
) {
    button(type = type, classes = "${actionType.className} $classes p-relative") {
        this.id = id

        span {
            + text
        }

        LoadingSpinner(classes = "nav-text-color full-center")

        if (onClick != null) {
            onClickSuspend = {
                actionButtonChangeState(id, isUpdating = true)
                onClick(it)
                actionButtonChangeState(id, isUpdating = false)
            }
        }
    }
}

fun actionButtonChangeState(id: String, isUpdating: Boolean) {
    val button = document.getElementById(id) as HTMLElement? ?: return
    val text = button.querySelector("span") as HTMLElement
    val spinner = button.querySelector(".loading-spinner") as HTMLElement

    if (isUpdating) {
        text.invisible()
        spinner.show()
    } else {
        text.visible()
        spinner.hide()
    }
}

fun isActionButtonUpdating(id: String): Boolean {
    val button = document.getElementById(id) as HTMLElement? ?: return false
    val text = button.querySelector("span") as HTMLElement

    return text.classList.contains("invisible")
}

enum class ActionButtonType(val className: String) {
    PRIMARY("primary"), DESTRUCTIVE("danger"), BENIGN("flat")
}
