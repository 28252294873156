@file:Suppress("FunctionName")

package components

import Toast
import hide
import kotlinx.browser.document
import kotlinx.coroutines.launch
import kotlinx.html.*
import kotlinx.html.dom.append
import mainScope
import net.gorillagroove.track.NowListeningState
import net.gorillagroove.track.PlaySessionService
import net.gorillagroove.track.RemoteNowListeningState
import net.gorillagroove.util.GGLog
import net.gorillagroove.util.GGLog.logError
import onClickSuspend
import org.w3c.dom.HTMLElement
import queryId
import show
import kotlin.text.Typography.nbsp

private val loadingSpinner get() = document.queryId<HTMLElement>("play-session-loader")

fun TagConsumer<*>.PlaySessionPage() = div("full-height") {
    id = "play-sessions"

    div {
        id = "device-table-wrapper"

        LoadingSpinner(id = "play-session-loader")

        table("page-table") {
            thead {
                tr {
                    th {
                        style = "width:30px"
                        span { + "$nbsp" }
                    }
                    th {
                        span { +"Device Name" }
                    }
                    th {
                        span { +"Current Track" }
                    }
                    th {
                        span { +"Last Played" }
                    }

                    th {
                        style = "width:40px"
                        span {
                            style = "width:40px"

                            + "$nbsp"
                        }
                    }
                }
            }

            tbody {
                id = "play-session-table-body"
            }
        }
    }

    mainScope.launch {
        PlaySessions.getSessions()
    }
}

private object PlaySessions {
    private var sessions = emptyList<RemoteNowListeningState>()

    suspend fun getSessions() {
        loadingSpinner.show()

        try {
            val result = PlaySessionService.getPlaySessions()
            sessions = result.otherSessions
        } catch (e: Exception) {
            Toast.error("Failed to load devices")
            GGLog.logError("Failed to load devices!", e)

            loadingSpinner.hide()

            return
        }

        val body = document.queryId<HTMLElement>("play-session-table-body")
        body.innerHTML = ""

        sessions.forEach { state ->
            body.append {
                tr {
                    td("text-center") {
                        if (state.deviceType.isMobile) {
                            i("fa-solid fa-mobile")
                        } else {
                            i("fa-solid fa-laptop")
                        }
                    }
                    td { + state.deviceName }
                    td { + state.trackString }
                    td { + state.timeAgoString() }
                    td(classes = "text-center") {
                        span {
                            button(classes = "icon action-button") {
                                tooltip = "Copy session"

                                onClickSuspend = { copySession(state) }

                                i("fa-solid fa-copy")
                            }
                        }
                    }
                }
            }
        }

        Tooltip.registerAll(body)

        loadingSpinner.hide()
    }

    private suspend fun copySession(state: NowListeningState) {
        PlaySessionService.setPlayStateFromPlaySession(state, startPlayback = true)
    }
}
