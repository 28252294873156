package net.gorillagroove.authentication

import kotlinx.coroutines.*
import kotlinx.serialization.Serializable
import net.gorillagroove.api.*
import net.gorillagroove.authentication.VersionService.LAST_POSTED_VERSION_KEY
import net.gorillagroove.db.Database.failedListenDao
import net.gorillagroove.db.Database.logLineDao
import net.gorillagroove.db.Database.playlistDao
import net.gorillagroove.db.Database.playlistTrackDao
import net.gorillagroove.db.Database.playlistUserDao
import net.gorillagroove.db.Database.reviewSourceDao
import net.gorillagroove.db.Database.syncStatusDao
import net.gorillagroove.db.Database.trackDao
import net.gorillagroove.db.Database.userDao
import net.gorillagroove.db.Database.userFavoriteDao
import net.gorillagroove.db.Database.userPermissionDao
import net.gorillagroove.db.Database.userSettingDao
import net.gorillagroove.hardware.DeviceUtil
import net.gorillagroove.hardware.DeviceType
import net.gorillagroove.localstorage.CurrentUserStore
import net.gorillagroove.localstorage.LocalStorage
import net.gorillagroove.reporting.ProblemReportService.LAST_AUTOMATED_REPORT_KEY
import net.gorillagroove.reporting.ProblemReportService.LAST_MANUAL_REPORT_KEY
import net.gorillagroove.sync.SyncCoordinator
import net.gorillagroove.track.NowPlayingService
import net.gorillagroove.track.OfflineModeService
import net.gorillagroove.track.PlatformTrackCacheService
import net.gorillagroove.track.PlaySessionService.LOCAL_PLAYBACK_KEY
import net.gorillagroove.util.GGLog.logDebug
import net.gorillagroove.util.GGLog.logError
import net.gorillagroove.util.GGLog.logInfo
import net.gorillagroove.util.SettingType
import net.gorillagroove.util.Settings
import kotlin.coroutines.cancellation.CancellationException
import kotlin.time.Duration.Companion.milliseconds
import kotlin.time.Duration.Companion.seconds

object AuthService {
    @Throws(ForbiddenRequestException::class, BadRequestException::class, CancellationException::class, Throwable::class)
    suspend fun login(email: String, password: String) {
        val request = LoginRequest(
            email = email.trim(),
            password = password.trim(),
            deviceId = DeviceUtil.getDeviceId(),
            preferredDeviceName = DeviceUtil.getDeviceName()?.trim(),
            version = VersionService.currentDeviceVersion,
            deviceType = DeviceUtil.getDeviceType()
        )
        val response: LoginResponse = Api.post("authentication/login", request)

        VersionService.setLastPostedVersion()
        CurrentUserStore.setInfo(response)
    }

    fun logout(): Job {
        logInfo("User is logging out")

        ApiSocket.disconnect()
        OfflineModeService.abortDownload()
        SyncCoordinator.abortSync()
        NowPlayingService.stop()

        LocalStorage.delete(SyncCoordinator.HAS_FIRST_SYNCED_KEY)
        Settings.setBoolean(SettingType.HAS_FIRST_SYNCED, false)

        LocalStorage.delete(LAST_AUTOMATED_REPORT_KEY)
        LocalStorage.delete(LAST_MANUAL_REPORT_KEY)
        LocalStorage.delete(LAST_POSTED_VERSION_KEY)

        // Because we are sending the network request async and then clearing out the auth token right after,
        // make sure to grab the auth token first.
        val authToken = authToken
		val job = CoroutineScope(Dispatchers.Default).launch {
            // This whole thing is pretty hacky. I delay deleting the DB because the network request
            // reads from settings in order to get data, that we are then going to wipe out.
            launch {
                delay(250.milliseconds)

                logDebug("Deleting database except for logs")
                deleteUserDatabaseData()
                // We potentially just deleted a bunch of data, so do a VACUUM.
                // It seems like this likes to fail from other SQL running when called on the frontend.
                logLineDao.vacuum()
            }
			// We don't really want to block the logout. If the network request hangs, they still should be able to log out.
			// So launch it in a new scope and return the Job in case anybody actually cares to wait for it
            if (authToken != null) {
                try {
                    Api.post<Unit>("authentication/logout", authToken = authToken)
                } catch (e: Exception) {
                    this@AuthService.logError("Failed to send logout request")
                }
            }

            PlatformTrackCacheService.deleteAllData()
		}

        logDebug("Clearing logged in user info")
		CurrentUserStore.clearInfo()

        // Need to clear this after we are already logged out. It has a check to avoid writing it again if we are
        LocalStorage.delete(LOCAL_PLAYBACK_KEY)

		return job
    }

    // NGL this is not really a super "smart" way to do this, because it's now a point of maintenance.
    // The idea is that I want to delete everything EXCEPT for the log table. Logs should persist.
    // But now every time I add a new table I have to basically remember to add it here, and it's error-prone.
    // Oh well. At least it's easy? For now?
    private fun deleteUserDatabaseData() {
        failedListenDao.deleteAll()
        playlistTrackDao.deleteAll()
        playlistUserDao.deleteAll()
        playlistDao.deleteAll()
        trackDao.deleteAll()
        syncStatusDao.deleteAll()
        reviewSourceDao.deleteAll()
        // There are some settings that apply when not logged in, and it's probably better if we preserve them.
        userSettingDao.deleteAlmostAll(listOf(
            SettingType.OVERRIDDEN_HOST,
            SettingType.OVERRIDDEN_HOST_USE_HTTPS,
            SettingType.THEME_MODE,
        ).map { it.dbKey })
        userFavoriteDao.deleteAll()
        userPermissionDao.deleteAll()
        userDao.deleteAll()
    }

    internal val authToken: String? get() = CurrentUserStore.getInfo()?.token

    fun isAuthenticated() = authToken != null
}

@Serializable
internal data class LoginResponse(
    val id: UserId,
    val token: String,
    val email: String,
    val username: String,
)

@Serializable
internal data class LoginRequest(
    val email: String,
    val password: String,
    val deviceId: String,
    val preferredDeviceName: String?,
    val version: String,
    val deviceType: DeviceType
)
