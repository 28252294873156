package net.gorillagroove.favorite

import kotlinx.serialization.Serializable
import net.gorillagroove.api.Api
import net.gorillagroove.db.Database
import net.gorillagroove.db.Database.userFavoriteDao
import net.gorillagroove.db.many
import net.gorillagroove.db.one
import net.gorillagroove.sync.UserFavoriteType
import net.gorillagroove.sync.strategies.UserFavoriteResponse
import net.gorillagroove.util.GGLog.logError
import net.gorillagroove.util.GGLog.logInfo

object FavoriteService {
    @Throws(Throwable::class)
    suspend fun createFavorite(type: UserFavoriteType, value: String): UserFavorite {
        logInfo("Creating favorite ${type.name} with value $value")
        val request = UserFavoriteStringRequest(type, value)

        val response = try {
            Api.post<UserFavoriteResponse>("user-favorite", request)
        } catch (e: Exception) {
            logError("Failed to create favorite of type $type with value $value!", e)
            throw e
        }

        val dbRecord = response.asDbRecord()
        userFavoriteDao.upsert(dbRecord)

        Database.forceSave()

        return dbRecord.toUserFavorite()
    }

    @Throws(Throwable::class)
    suspend fun deleteFavorite(type: UserFavoriteType, value: String) {
        val record = userFavoriteDao.findByTypeAndValue(type.toRawType(), value).one()
        try {
            Api.delete<Unit>("user-favorite/${record.id.value}")
        } catch (e: Exception) {
            logError("Failed to delete favorite of type $type with value $value!", e)
            throw e
        }

        userFavoriteDao.delete(record.id)

        Database.forceSave()
    }

    fun getFavorites(type: UserFavoriteType): List<UserFavorite> {
        return userFavoriteDao.getByType(type.toRawType())
            .many()
            .map { it.toUserFavorite() }
            .sortedWith(compareBy(String.CASE_INSENSITIVE_ORDER) { it.value })
    }
}

@Serializable
internal data class UserFavoriteStringRequest(
    val type: UserFavoriteType,
    val value: String,
)
