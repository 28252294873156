package components

import Dialog
import LibraryViewMode
import PageRouter
import Toast
import components.contextmenu.TrackContextMenu
import ViewMode
import addEventListener
import components.contextmenu.ContextMenu
import components.contextmenu.GroupKeyContextMenu
import components.contextmenu.openTrackColumnContextMenu
import hide
import isRightClick
import kotlinx.browser.document
import kotlinx.coroutines.Job
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlinx.datetime.Clock.System.now
import kotlinx.datetime.Instant
import kotlinx.html.*
import kotlinx.html.dom.append
import kotlinx.html.dom.create
import kotlinx.html.form
import kotlinx.html.js.*
import kotlinx.html.tr
import mainScope
import net.gorillagroove.api.PlaylistId
import net.gorillagroove.api.ReviewSourceId
import net.gorillagroove.api.UserId
import net.gorillagroove.favorite.FavoriteService
import net.gorillagroove.playlist.LoadedPlaylistTrack
import net.gorillagroove.playlist.PlaylistService
import net.gorillagroove.review.ReviewQueueService
import net.gorillagroove.sync.UserFavoriteType
import net.gorillagroove.track.*
import net.gorillagroove.track.NowPlayingEventType.*
import net.gorillagroove.user.UserService
import net.gorillagroove.util.GGLog
import net.gorillagroove.util.GGLog.logDebug
import net.gorillagroove.util.GGLog.logError
import net.gorillagroove.util.GGLog.logInfo
import net.gorillagroove.util.findIndex
import onClickSuspend
import org.w3c.dom.*
import org.w3c.dom.events.Event
import org.w3c.dom.events.KeyboardEvent
import org.w3c.dom.events.MouseEvent
import org.w3c.dom.events.MouseEventInit
import query
import queryId
import setHidden
import show
import kotlin.text.Typography.nbsp
import kotlin.time.Duration.Companion.milliseconds
import kotlin.time.measureTime


@Suppress("FunctionName")
fun TagConsumer<*>.TrackTable() = div { id = "track-table"
    table {
        thead {

        }

        tbody {
        }
    }

    LoadingSpinner(id = "track-table-loader")

    mainScope.launch {
        TrackTable.fullRender()
    }
}

private val trackTable get() = document.getElementById("track-table") as HTMLElement?
private val trackTableHead: Element get() = trackTable?.querySelector("thead") as HTMLElement
private val trackTableBody get() = trackTable?.querySelector("tbody") as HTMLElement
private val loadingSpinner get() = document.getElementById("track-table-loader") as HTMLElement?

object TrackTable {
    private var sorts = getDefaultSortForViewMode()

    private var tracks = mutableListOf<TrackSortable>()
    // The first int is a unique identifier, second int is the index
    private var trackIndexes = mutableMapOf<Int, Int>()

    // Anything collapsable. Artists, albums, review sources...
    private var visibleGroupKeys = listOf<String>()
    private var visibleFavorites = setOf<String>()
    private var expandedGroupKey: String? = null

    private val selectedIndexes = mutableSetOf<Int>()
    private var selectedGroupKey: String? = null

    private val viewMode get() = PageRouter.currentViewMode
    private val libraryViewMode get() = PageRouter.currentLibraryViewMode

    private lateinit var columnDefinition: List<Pair<TrackColumn, TrackColumnUserPreferences>>

    private val userId: UserId get() = PageRouter.getQueryParam("USER_ID")
        ?.toLong()
        ?.let { UserId(it) }
        ?: UserService.getCurrentUserId()!!

    init {
        NowPlayingService.registerEventHandler(TrackTable::handleNowPlayingChangeEvent)
        TrackService.registerEventHandler(TrackTable::handleTrackChange)

        document.documentElement!!.addEventListener("mouseup") { handleResizeMouseUp(it as MouseEvent) }

        document.documentElement!!.addEventListener("keydown") { handleKeyPress(it as KeyboardEvent) }
    }

    private fun isLoaded() = trackTable != null

    private fun sort(column: TrackColumn, appendSort: Boolean = false) {
        if (appendSort) {
            val sortIndex = sorts.findIndex { (type, _) -> type == column }
            if (sortIndex != null) {
                val currentDirection = sorts[sortIndex].direction
                val defaultDirection = column.defaultSortDirection

                if (currentDirection == defaultDirection) {
                    sorts[sortIndex] = TrackSortItem(column, defaultDirection.reversed())
                } else {
                    sorts.removeAt(sortIndex)
                }
            } else {
                sorts.add(TrackSortItem(column, column.defaultSortDirection))
            }
        } else {
            var (sortType, sortDirection) = sorts.first()
            if (sortType == column) {
                sortDirection = sortDirection.reversed()
            } else {
                sortType = column
                sortDirection = column.defaultSortDirection
            }

            sorts = mutableListOf(TrackSortItem(sortType, sortDirection))
        }

        if (viewMode == ViewMode.MY_LIBRARY || viewMode == ViewMode.USERS) {
            TrackSort.lastSort = sorts
        }

        selectedIndexes.clear()

        // If we have a group already expanded, delete just the track rows and render just the track rows.
        // This keeps the expanded section open and does the minimal amount of work.
        // Otherwise, render the entire table again as it'll be faster than finding and deleting individual nodes.
        expandedGroupKey?.let { groupKey ->
            mainScope.launch {
                calculateVisibleTracks()

                renderHead()

                deleteTrackRows()
                val row = document.getElementById("group-row-$groupKey") as HTMLTableRowElement
                renderTracks(row)
            }
        } ?: run {
            fullRender()
        }
    }

    // This is kind of weird (like a lot of things in this file), but track indexes need to be dynamic.
    // This is because a track can disappear from the screen when it gets deleted, and then all indexes
    // from that point on need to be adjusted to make up for this.
    // If I ever support adding tracks dynamically, this just needs to work in reverse.
    private fun incrementTrackIndexMapping(startingIndex: Int, decrementInstead: Boolean = false) {
        trackIndexes.iterator().forEach { (uniqueId, index) ->
            if (decrementInstead) {
                if (index > startingIndex) {
                    trackIndexes[uniqueId] = index - 1
                }
            } else {
                if (index >= startingIndex) {
                    trackIndexes[uniqueId] = index + 1
                }
            }
        }

        val newIndexes = selectedIndexes.map { selectedIndex ->
            if (decrementInstead) {
                if (selectedIndex > startingIndex) {
                    selectedIndex - 1
                } else selectedIndex
            } else {
                if (selectedIndex >= startingIndex) {
                    selectedIndex + 1
                } else selectedIndex
            }
        }

        selectedIndexes.clear()
        selectedIndexes.addAll(newIndexes)
    }

    private fun getColumnOrder(): List<Pair<TrackColumn, TrackColumnUserPreferences>> {
        val columns = TrackSort.columnOptions.filter { it.second.enabled }
            // We don't want to give users the ability to customize these special columns. So remove them from any saved state and re-add them
            .filterNot { it.first == TrackColumn.SORT_IDENTIFIER || it.first == TrackColumn.CLIENT_CUSTOM }
            .toMutableList()

        if (!viewMode.hidesSortId) {
            val sortColumn = TrackColumn.SORT_IDENTIFIER to TrackColumnUserPreferences(enabled = true, width = TrackColumn.SORT_IDENTIFIER.defaultWidth)
            columns.add(0, sortColumn)
        }

        if (viewMode.hidesSectionChevron == false || (viewMode.hidesSectionChevron == null && !libraryViewMode.hidesSectionChevron)) {
            val chevronColumn = TrackColumn.CLIENT_CUSTOM to TrackColumnUserPreferences(enabled = true, width = TrackColumn.CLIENT_CUSTOM.defaultWidth)
            columns.add(0, chevronColumn)
        }

        return columns
    }

    private fun persistInlineEdit(track: TrackSortable, column: TrackColumn) {
        val el = document.getElementById("track-inline-edit-input") as HTMLInputElement
        val cell = el.closest(".editing") as HTMLElement

        val value = el.value
        el.remove()

        cell.innerText = value
        cell.classList.remove("editing")

        mainScope.launch {
            try {
                MetadataService.updateSingleItem(track.asTrack(), column.metadataProperty!!, value)
            } catch (e: Exception) {
                GGLog.logError("Failed to update metadata!", e)
                Toast.error("Failed to update metadata")
            }
        }
    }

    private var startEditJob: Job? = null
    private var lastSelectionTime = Instant.DISTANT_PAST
    private var firstSelectedIndex: Int? = null
    private var lastSelectedIndex: Int? = null

    private fun selectRow(track: TrackSortable, event: MouseEvent) {
        val index = trackIndexes.getValue(track.uniqueIdentifier)

        deselectHighlightedGroupRow()

        startEditJob?.cancel()

        val isSelected = selectedIndexes.contains(index)
        val row = event.currentTarget as HTMLElement
        val cell = event.target as HTMLElement

        fun deselectAllOtherRows() {
            deselectAllTrackRows()

            selectedIndexes.add(index)
            row.classList.add("selected")

            firstSelectedIndex = index
            lastSelectedIndex = index
        }

        if (event.ctrlKey) {
            // Stuff gets weird when ctrl is held so this doesn't close. But it really probably should.
            ContextMenu.closeExistingMenu()

            if (isSelected) {
                selectedIndexes.remove(index)
                row.classList.remove("selected")

                if (selectedIndexes.isEmpty()) {
                    firstSelectedIndex = null
                    lastSelectedIndex = null
                }
            } else {
                selectedIndexes.add(index)
                row.classList.add("selected")

                if (selectedIndexes.size == 1) {
                    firstSelectedIndex = index
                }
            }

            LibraryHeader.updateTrackTotals(tracks, selectedIndexes)
            lastSelectedIndex = index

            return
        }

        if (event.shiftKey && firstSelectedIndex != null) {
            handleShiftSelect(index)

            return
        }

        val onlyRowSelected = selectedIndexes.size == 1

        deselectAllOtherRows()

        // We should only go into inline edit mode if the track we selected was the ONLY selected track
        // at the start. If we had 3 tracks selected but then clicked one of them, the click should only
        // deselect the other 2 rows without going into edit mode.
        if (isSelected && onlyRowSelected) {
            if ((now() - lastSelectionTime).inWholeMilliseconds < 300) {
                handleDoubleClickPlayRequest(index)
            } else if (track.asTrack().userId == UserService.requireCurrentUserId()) {
                val column = columnDefinition[cell.attributes["column-index"]!!.value.toInt()].first
                if (column.updatable) {
                    startEditJob = mainScope.launch {
                        delay(300)

                        val text = cell.innerText
                        cell.classList.add("editing")
                        cell.innerText = ""
                        cell.append {
                            form {
                                onSubmitFunction = { event ->
                                    event.preventDefault()
                                    persistInlineEdit(track, column)
                                }
                                input {
                                    id = "track-inline-edit-input"
                                    value = text
                                    onClickFunction = { it.stopPropagation() }
                                    onBlurFunction = { persistInlineEdit(track, column) }
                                }
                            }
                        }

                        // The "autofocus" property isn't reliable, so do it after the append
                        val el = document.getElementById("track-inline-edit-input") as HTMLInputElement
                        el.focus()
                        // The cursor, when focusing, appears to go at the start. Saw this dumb hack on SO to get it to go to the end
                        val value = el.value
                        el.value = ""
                        el.value = value
                    }
                }
            }
        }

        lastSelectionTime = now()

        LibraryHeader.updateTrackTotals(tracks, selectedIndexes)
    }

    private fun handleShiftSelect(index: Int) {
        val visualIndexOffset = if (expandedGroupKey == null) {
            0
        } else {
            // +2 because we account for the current expanded row, and the invisibility spacer.
            // FIXME The invisibility spacer SHOULDN'T be at the top probably. But it's only used
            //  during track render (not group key render) right now so I guess it's ok though I hate it.
            visibleGroupKeys.findIndex { it == expandedGroupKey }!! + 2
        }

        // Now we have to select the rows visually, where we have to account for the group keys
        // making all of the expanded rows be offset
        val firstVisualIndex = firstSelectedIndex!! + visualIndexOffset
        val lastVisualIndex = index + visualIndexOffset

        val visualIndexesToSelect = if (firstVisualIndex < lastVisualIndex) {
            (firstVisualIndex..lastVisualIndex)
        } else {
            (lastVisualIndex..firstVisualIndex)
        }

        deselectAllTrackRows()

        selectedIndexes.addAll(
            if (firstSelectedIndex!! < index) {
                (firstSelectedIndex!!..index)
            } else {
                (index..firstSelectedIndex!!)
            }
        )

        val rows = trackTableBody.childNodes.asList()
        visualIndexesToSelect.forEach { selectionIndex ->
            (rows[selectionIndex] as HTMLElement).classList.add("selected")
        }

        LibraryHeader.updateTrackTotals(tracks, selectedIndexes)

        lastSelectedIndex = index
    }

    private fun handleDoubleClickPlayRequest(index: Int) {
        if (viewMode == ViewMode.NOW_PLAYING) {
            // On the NowPlaying screen, we are supposed to just switch the current track instead of change the list of tracks.
            // We do not use NowPlayingService.playFromIndex() because the list could be filtered here and the index may
            // not line up with the actual NowPlayingTrack.
            val nowPlayingTrack = tracks[index] as NowPlayingTrack
            NowPlayingService.playFromNowPlayingId(nowPlayingTrack.nowPlayingTrackId)
        } else {
            NowPlayingService.setNowPlayingTracks(tracks.map { it.asTrack() }, playFromIndex = index)
        }
    }

    private fun deselectAllTrackRows() {
        selectedIndexes.clear()
        val selectedRows = trackTableBody.querySelectorAll(".selected").asList()
        selectedRows.forEach { (it as Element).classList.remove("selected") }
    }

    private fun handleTrackContextMenu(track: TrackSortable, event: MouseEvent) {
        val index = trackIndexes.getValue(track.uniqueIdentifier)
        deselectHighlightedGroupRow()

        // Browsers now seem to treat ctrl + click as a right click. This is not
        // ideal when we want ctrl + click to select tracks without deselecting
        // the previous one. So we need to override this behavior.
        if (event.ctrlKey) {
            event.preventDefault()
            selectRow(track, event)
        } else {
            val isSelected = selectedIndexes.contains(index)
            if (!isSelected) {
                selectRow(track, event)
            }

            TrackContextMenu.open(event)
        }
    }

    private fun deselectHighlightedGroupRow() {
        val groupKey = selectedGroupKey ?: return

        val existingRow = document.getElementById("group-row-$groupKey") as HTMLTableRowElement?
        existingRow?.classList?.remove("selected")
    }

    private fun handleGroupKeyContextMenu(groupKey: String, event: MouseEvent) {
        // There aren't any actions right now that are supported on any other view
        if (PageRouter.currentViewMode != ViewMode.MY_LIBRARY) {
            return
        }

        deselectAllTrackRows()
        deselectHighlightedGroupRow()

        // Prevent ctrl + click from being a right click
        if (event.ctrlKey) {
            event.preventDefault()
            return
        }

        val row = event.currentTarget as HTMLElement
        row.classList.add("selected")

        selectedGroupKey = groupKey

        GroupKeyContextMenu.open(event, visibleFavorites)
    }

    private fun movePlayedTrackIntoView() {
        val table = trackTable ?: return

        val selectedRow = table.querySelector(".active") as HTMLElement?
            ?: return

        if (selectedRow.offsetTop - table.offsetHeight + 80 > table.scrollTop) {
            table.scrollTop = selectedRow.offsetTop - (table.offsetHeight / 2.0)
        } else if (selectedRow.offsetTop - selectedRow.offsetHeight + 13 < table.scrollTop) {
            table.scrollTop = selectedRow.offsetTop - selectedRow.offsetHeight.toDouble()
        }
    }

    private fun resetScroll() {
        trackTable?.scrollTop = 0.0
    }

    private var groupKeySearch = ""
    private var trackSearch = ""

    fun handleSearchTermChange(newTerm: String, preserveExpansion: Boolean) {
        val hasGroupKeys = if (viewMode.hidesLibraryViewMode) {
            viewMode.hidesSectionChevron == false
        } else {
            !libraryViewMode.hidesSectionChevron
        }

        if (hasGroupKeys && expandedGroupKey == null) {
            groupKeySearch = newTerm
        } else {
            trackSearch = newTerm
        }

        fullRender(preserveExpansion = preserveExpansion)
    }

    fun fullRender(preserveExpansion: Boolean = false) = mainScope.launch {
        loadingSpinner?.show()

        selectedIndexes.clear()
        firstSelectedIndex = null
        lastSelectedIndex = null

        tracks = mutableListOf()
        trackIndexes = mutableMapOf()
        visibleGroupKeys = emptyList()

        if (!preserveExpansion) {
            expandedGroupKey = null
        }

        columnDefinition = getColumnOrder()

        calculateVisibleRows()

        renderHead()
        renderTable()
        expandRelevantGroupSection()

        if (viewMode == ViewMode.NOW_PLAYING) {
            mainScope.launch {
                movePlayedTrackIntoView()
            }
        }

        loadingSpinner?.hide()
    }

    private suspend fun calculateVisibleRows() {
        calculateVisibleGroupKeys()
        calculateVisibleTracks()
    }

    private suspend fun calculateVisibleGroupKeys() {
        if (viewMode == ViewMode.REVIEW_QUEUE) {
            val session = ReviewQueueService.startOrGetSession()
            visibleGroupKeys = session.sourcesNeedingReview.map { it.id.value.toInt().toString() }
        } else {
            val mode = if (viewMode.forcesTrackView) LibraryViewMode.TRACK else libraryViewMode

            when (mode) {
                LibraryViewMode.ARTIST -> {
                    visibleGroupKeys = TrackService.getDistinctArtists(
                        userId = userId,
                        searchFilter = groupKeySearch,
                        isHidden = if (LibraryHeader.showHiddenTracks) null else false,
                    )
                }
                LibraryViewMode.ALBUM -> {
                    visibleGroupKeys = TrackService.getDistinctAlbums(
                        userId = userId,
                        searchFilter = groupKeySearch,
                        isHidden = if (LibraryHeader.showHiddenTracks) null else false,
                    ).map { it.name }
                }
                LibraryViewMode.TRACK -> {
                    visibleGroupKeys = emptyList()
                }
            }
        }

        // If the group key is expanded, then we do not want to remove it as the implication is we're searching tracks.
        // This is pretty inefficient. It's probably faster to check if the filter would have excluded the original
        // groupKey rather than doing .contains on a list. And it's slower to sort the entire array than it would be
        // to do a binary search to find the correct location in this sorted list. But w/e.
        expandedGroupKey?.let { key ->
            if (!visibleGroupKeys.contains(key)) {
                visibleGroupKeys = (visibleGroupKeys + key).sorted()
            }
        }

        calculateFavorites()
    }

    fun calculateFavorites() {
        val mode = if (viewMode.forcesTrackView) LibraryViewMode.TRACK else libraryViewMode
        // Don't show our own favorites when viewing another user. I personally find it very confusing
        // as it can make you think that you're looking at your own stuff and wonder why shit is missing.
        visibleFavorites = if (viewMode == ViewMode.USERS) {
            emptySet()
        } else {
            when (mode) {
                LibraryViewMode.TRACK -> emptySet()
                LibraryViewMode.ARTIST -> FavoriteService.getFavorites(UserFavoriteType.ARTIST).map { it.value }.toSet()
                LibraryViewMode.ALBUM -> FavoriteService.getFavorites(UserFavoriteType.ALBUM).map { it.value }.toSet()
            }
        }

        // Split them up so that we do favorites first
        val (favorites, nonFavorites) = visibleGroupKeys.partition { visibleFavorites.contains(it) }
        visibleGroupKeys = favorites + nonFavorites
    }

    private suspend fun calculateVisibleTracks() {
        val time = measureTime {
            tracks = if ((libraryViewMode == LibraryViewMode.TRACK || viewMode.forcesTrackView) && viewMode != ViewMode.REVIEW_QUEUE) {
                when (viewMode) {
                    ViewMode.MY_LIBRARY -> TrackService.searchBy(
                        genericFilter = trackSearch,
                        excludedPlaylistId = LibraryHeader.excludedPlaylist,
                        isHidden = if (LibraryHeader.showHiddenTracks) null else false,
                        sort = sorts,
                    )

                    ViewMode.NOW_PLAYING -> NowPlayingService.getSortedNowPlayingTracks(
                        genericFilter = trackSearch,
                        sort = sorts,
                    )

                    ViewMode.USERS -> {
                        TrackService.searchBy(
                            userId = userId,
                            genericFilter = trackSearch,
                            sort = sorts,
                        )
                    }

                    ViewMode.PLAYLISTS -> {
                        val playlistId = PageRouter.getQueryParam("PLAYLIST_ID")?.toLong()
                        if (playlistId != null) {
                            PlaylistService.getTracksForPlaylist(
                                playlistId = PlaylistId(playlistId),
                                genericFilter = trackSearch,
                                sort = sorts
                            )
                        } else emptyList()
                    }

                    else -> emptyList()
                }
            } else {
                val groupKey = expandedGroupKey

                if (groupKey == null) {
                    emptyList()
                } else {
                    if (viewMode == ViewMode.REVIEW_QUEUE) {
                        val session = ReviewQueueService.startOrGetSession()
                        val id = ReviewSourceId(groupKey.toLong())

                        session.setActiveSource(id)

                        session.getSortedVisibleTracks(sort = sorts)
                    } else {
                        TrackService.searchBy(
                            userId = userId,
                            artistFilter = if (libraryViewMode == LibraryViewMode.ARTIST) groupKey else null,
                            albumFilter = if (libraryViewMode == LibraryViewMode.ALBUM) groupKey else null,
                            genericFilter = trackSearch,
                            sort = sorts,
                        )
                    }
                }
            }.toMutableList()
        }

        var index = 0
        trackIndexes = tracks.associate { it.uniqueIdentifier to index++ }.toMutableMap()

        logDebug("Tracks loaded in: $time")

        LibraryHeader.updateTrackTotals(tracks, selectedIndexes)
    }

    private var draggedColumn: TrackColumn? = null

    private fun startColumnDrag(column: TrackColumn) {
        draggedColumn = column
        // Make the drop zones "visible" / active. They aren't active normally because they cover up the resizing handles.
        trackTableHead.querySelectorAll(".drag-drop-zone")
            .asList()
            .forEach { (it as HTMLElement).show() }
    }

    private fun endColumnDrag() {
        draggedColumn = null
        trackTableHead.querySelectorAll(".drag-drop-zone")
            .asList()
            .forEach { (it as HTMLElement).hide() }
    }

    // The way everything is set up, this means that you can't drag a column into the first position.
    // I am, for now, ok with this. You can just drag it into the 2nd position then drag the first column after it.
    // Not ideal, but it could take many years before anybody even notices my cut corners....
    private fun moveColumn(afterColumn: TrackColumn) {
        val column = draggedColumn ?: throw IllegalStateException("No draggedColumn found when moving table headers!")

        GGLog.logInfo("Reordering ${column.displayName} after ${afterColumn.displayName}")

        val newDefinition = columnDefinition.toMutableList()

        val currentIndex = columnDefinition.indexOfFirst { it.first == column }
        val data = newDefinition.removeAt(currentIndex)

        val targetIndex = newDefinition.indexOfFirst { it.first == afterColumn } + 1

        newDefinition.add(targetIndex, data)

        TrackSort.columnOptions = newDefinition
        columnDefinition = newDefinition

        renderHead()
        renderTable()
    }

    private fun renderHead() {
        trackTableHead.innerHTML = ""

        trackTableHead.append {
            tr {
                onContextMenuFunction = { event ->
                    openTrackColumnContextMenu(event as MouseEvent)
                }

                columnDefinition.forEachIndexed { index, (column, options) ->
                    th {
                        id = "table-header-$index"
                        style = "width: ${options.width}px"

                        span(classes = "table-header-inner") {
                            draggable = Draggable.htmlTrue
                            onClickFunction = { event ->
                                event as MouseEvent

                                // I can't seem to figure out how to make it so dragging the drag handle
                                // onto the column then releasing the click doesn't trigger this. Just doing
                                // stopPropagation() isn't enough I guess. So this is a hack. Woo.
                                if (resizedColumn == null) {
                                    // We use this for the expand-o chevron. We don't want clicking it to do anything.
                                    if (column != TrackColumn.CLIENT_CUSTOM) {
                                        sort(column, appendSort = event.shiftKey)
                                    }
                                }
                            }

                            onDragStartFunction = {
                                startColumnDrag(column)
                            }

                            onDragEndFunction = {
                                endColumnDrag()
                            }

                            span(classes = "table-header-text") {
                                if (column == TrackColumn.CLIENT_CUSTOM) {
                                    + (nbsp + "")
                                } else {
                                    +column.displayName
                                }
                            }

                            span {
                                val sortIndex = sorts.findIndex { (sortType, _) -> sortType == column }
                                if (sortIndex != null) {
                                    span(classes = "sort-container") {
                                        val sortDirection = sorts[sortIndex].direction
                                        if (sortDirection == SortDirection.DESC) {
                                            i(classes = "fa-solid fa-caret-down")
                                        } else {
                                            i(classes = "fa-solid fa-caret-up")
                                        }

                                        if (sorts.size > 1) {
                                            span(classes = "priority") {
                                                +(sortIndex + 1).toString()
                                            }
                                        }
                                    }
                                }

                                span(classes = "drag-drop-zone d-none") {
                                    fun changeIndicatorVisibility(event: Event, isVisible: Boolean) {
                                        val self = event.currentTarget as HTMLElement
                                        val indicator = self.querySelector(".drag-drop-indicator") as HTMLElement
                                        indicator.setHidden(!isVisible)
                                    }

                                    onDragOverFunction = {
                                        it.preventDefault()
                                    }
                                    onDragEnterFunction = { changeIndicatorVisibility(it, isVisible = true) }
                                    onDragLeaveFunction = { changeIndicatorVisibility(it, isVisible = false) }
                                    onDropFunction = { event ->
                                        changeIndicatorVisibility(event, isVisible = false)

                                        moveColumn(column)
                                    }

                                    span("drag-drop-indicator d-none")
                                }

                                // Don't allow resizing the chevron / client_custom column
                                if (column != TrackColumn.CLIENT_CUSTOM) {
                                    span(classes = "drag-handle") {
                                        draggable = Draggable.htmlTrue

                                        onClickFunction = { it.stopPropagation() }
                                        onMouseDownFunction = { event ->
                                            event as MouseEvent

                                            if (!event.isRightClick()) {
                                                resizeStartX = event.clientX
                                                resizedColumn = column
                                            }
                                        }

                                        onDragStartFunction = { event ->
                                            event.preventDefault()
                                            event.stopPropagation()
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    fun renderTable() {
        trackTableBody.innerHTML = ""

        // This is not exactly the most ideal. But because we render the table chunks at a time, if we do not
        // do this it makes it look like the table took a long time to start rendering. The ideal solution is a more
        // robust rendering methodology that renders what you are looking at first.
        resetScroll()

        trackTableBody.append {
            tr {
                id = "track-table-spacer"
                style = "height:0"
            }
        }

        if (viewMode == ViewMode.REVIEW_QUEUE) {
            renderCollapsableRows()
        } else {
            if (viewMode.forcesTrackView || libraryViewMode == LibraryViewMode.TRACK) {
                renderTracks()
            } else {
                renderCollapsableRows()
            }
        }
    }

    private fun renderCollapsableRows() {
        visibleGroupKeys.forEach { key ->
            trackTableBody.append {
                renderCollapsibleRow(key, isFavorite = visibleFavorites.contains(key))
            }
        }

        expandedGroupKey?.let { key ->
            val row = document.getElementById("group-row-$key") as HTMLTableRowElement
            renderTracks(row)
        }
    }

    private fun TagConsumer<HTMLElement>.renderCollapsibleRow(groupKey: String, isFavorite: Boolean) {
        tr(classes = "expandable-row") {
            id = "group-row-$groupKey"

            val groupKeyDisplay = if (viewMode == ViewMode.REVIEW_QUEUE) {
                val id = ReviewSourceId(groupKey.toLong())
                getReviewSourceSectionHeader(id)
            } else if (groupKey.isEmpty()) {
                when (libraryViewMode) {
                    LibraryViewMode.ARTIST -> "(No Artist)"
                    LibraryViewMode.ALBUM -> "(No Album)"
                    else -> groupKey
                }
            } else {
                groupKey
            }

            onClickSuspend = {
                selectedIndexes.clear()

                val row = document.getElementById("group-row-$groupKey") as HTMLTableRowElement

                val isExpanded = expandedGroupKey == groupKey

                deselectHighlightedGroupRow()
                closeExpandedSections()

                if (!isExpanded) {
                    expandedGroupKey = groupKey
                    calculateVisibleTracks()

                    val expandableCellIcon = row.querySelector(".expandable-row-chevron i")!! as HTMLElement

                    expandableCellIcon.classList.remove("fa-chevron-right")
                    expandableCellIcon.classList.add("fa-chevron-down")

                    renderTracks(row)
                }
            }

            onContextMenuFunction = {
                handleGroupKeyContextMenu(groupKey, it as MouseEvent)
            }

            td("expandable-row-chevron") {
                i("fa-solid fa-chevron-right")
            }
            td("group-key-name") {
                // Remove the -1 because the caret at the far left needs to not be counted
                colSpan = (columnDefinition.size - 1).toString()

                if (isFavorite) {
                    i("fa-solid fa-star mr-4")
                }

                + groupKeyDisplay
            }
        }
    }

    private fun closeExpandedSections() {
        expandedGroupKey = null
        trackSearch = ""

        // I'm not sure if I will keep this forever. But in my own usage of this weird sort, I sometimes ran into
        // situations where I was expecting closing the tab with no search term to do stuff. I didn't want to have
        // to go into the search bar, enter a character, then delete it, in order for stuff to come back.
        // The search can still be odd anyway (there is no way around it with current UI), but this one flow seems
        // potentially common and confusing enough to special case it to act differently than non-blank terms.
        if (groupKeySearch.isNotBlank() && LibraryHeader.searchTerm.isBlank()) {
            groupKeySearch = ""
            fullRender()
        } else {
            val groupRow = trackTableBody.querySelector(".expanded") as HTMLTableRowElement?
                ?: return
            val expandableCellIcon = groupRow.querySelector(".expandable-row-chevron i")!! as HTMLElement

            groupRow.classList.remove("expanded")
            expandableCellIcon.classList.remove("fa-chevron-down")
            expandableCellIcon.classList.add("fa-chevron-right")

            deleteTrackRows()
        }
    }

    // If you navigate to the review queue screen while reviewing music, it should auto-expand the right menu.
    // Likewise, if you finish reviewing a section and it starts auto-playing the next section, we should expand that, too.
    private suspend fun expandRelevantGroupSection() {
        // TODO Currently only have plans for Review Queue type. But it could be nice if a view remembers the expanded
        //  group key when you navigate away and back. Like, you have a specific artist expanded, then you go to "history"
        //  then come back, and you'd want that tab to be open again. Currently it doesn't do this.
        if (viewMode != ViewMode.REVIEW_QUEUE) {
            return
        }

        val currentTrack = NowPlayingService.currentTrack ?: return

        val currentSource = if (!currentTrack.inReview) {
            return
        } else {
            // This can get weird with how flexible that GG is with things. For 95% of normal use, this will be ok.
            // But if someone starts reviewing this source, then they go to a different source and add music to their
            // current now playing, you end up with two sources and the current track may not be on the active source.
            // I think it's still ok. But there is technically a decision here on what is "correct".
            ReviewQueueService.startOrGetSession().activeSource ?: return
        }

        expandedGroupKey = currentSource.id.value.toString()
        calculateVisibleTracks()

        val row = document.queryId<HTMLTableRowElement>("group-row-${currentSource.id.value}")
        renderTracks(row)
    }

    private var renderJob: Job? = null

    private fun renderTracks(groupParent: HTMLTableRowElement? = null) {
        // If we render all the tracks at once, then the page can freeze, and it's an ugly experience.
        // An ideal solution would be totally virtualizing the table. And I might do it eventually. But
        // for now, an easier solution is to just render the track table in chunks so that the browser
        // has time to do its thing in between render groups.
        val rowHeight = 26
        val renderBatch = 100

        renderJob?.cancel()
        renderJob = null

        groupParent?.classList?.add("expanded")

        renderJob = mainScope.launch {
            var renderedTracks = 0
            val trackSpacer = document.getElementById("track-table-spacer") as HTMLElement

            tracks.chunked(renderBatch).forEach { trackChunk ->
                trackChunk.forEach { track ->
                    val index = renderedTracks

                    val newRow = createTrackRow(track)

                    if (groupParent != null) {
                        val insertIndex = groupParent.rowIndex + index
                        val nodeToInsertBefore = trackTableBody.childNodes[insertIndex]
                        trackTableBody.insertBefore(newRow, nodeToInsertBefore)
                    } else {
                        trackTableBody.insertBefore(newRow, trackSpacer)
                    }

                    renderedTracks++
                }

                // There is a spacer at the bottom of the table so that the scrollbar does not adjust
                // height while we are batch rendering. This just makes the illusion that everything
                // loaded immediately a bit better.
                val spacerHeight = (tracks.size - renderedTracks) * rowHeight

                if (groupParent == null) {
                    trackSpacer.style.height = "${spacerHeight}px"
                }

                delay(200.milliseconds)
            }
        }
    }

    private fun createTrackRow(track: TrackSortable): HTMLElement {
        val isPlaying = if (track is NowPlayingTrack) {
            track.nowPlayingTrackId == NowPlayingService.currentNowPlayingTrack?.nowPlayingTrackId
        } else {
            track.asTrack().id == NowPlayingService.currentTrack?.id
        }

        val classList = if (isPlaying) "active" else ""

        return document.create.tr("track-row $classList") {
            id = track.uniqueTableRowId

            attributes["track-id"] = track.asTrack().id.value.toString()

            onClickFunction = { event ->
                selectRow(track, event as MouseEvent)
            }
            onContextMenuFunction = { event ->
                handleTrackContextMenu(track, event as MouseEvent)
            }

            columnDefinition.forEachIndexed { index, order ->
                td {
                    attributes["column-index"] = index.toString()

                    + order.first.getFormattedProperty(track)
                }
            }
        }
    }

    private fun deleteTrackRows() {
        trackTableBody.querySelectorAll(".track-row")
            .asList()
            .forEach { row -> (row as HTMLElement).remove() }
    }

    private fun handleNowPlayingChangeEvent(event: NowPlayingEvent) {
        if (!isLoaded()) {
            return
        }

        fun removeActive() {
            trackTableBody.querySelectorAll("tr.active").asList().forEach { row ->
                (row as Element).classList.remove("active")
            }
        }

        when (event.type) {
            ALL_TRACKS_CHANGED, CURRENT_TRACK_CHANGED, PLAY_SESSION_COPIED -> {
                if (viewMode == ViewMode.NOW_PLAYING && (event.type == ALL_TRACKS_CHANGED || event.type == PLAY_SESSION_COPIED)) {
                    fullRender()
                } else {
                    removeActive()

                    val track = NowPlayingService.currentNowPlayingTrack ?: return

                    getAllRowsForNowPlayingTrack(track).forEach { row ->
                        row.classList.add("active")
                    }
                }
            }
            PLAYBACK_STOPPED -> {
                removeActive()
            }
            TRACKS_ADDED_NEXT -> {
                if (PageRouter.currentViewMode == ViewMode.NOW_PLAYING) {
                    val startingIndex = if (trackSearch.isBlank()) {
                        NowPlayingService.currentIndex + 1
                    } else {
                        // So, this is kind of complicated... but if we have a search filter applied, then that means that
                        // the currently played track might not actually be in the presented NowPlayingTrack list. So what
                        // we need to do instead, is find the track that is nearest to the actual playing track in the filtered list.
                        // Since we are putting the track AFTER this, it is ok to just check tracks that are prior to it.
                        val possibleTracks = NowPlayingService.currentNowPlayingTracks.slice(0..NowPlayingService.currentIndex)

                        val filteredIds = tracks.map { (it as NowPlayingTrack).nowPlayingTrackId }.toSet()

                        var foundIndex = 0
                        // Search in reverse because then the first one we find is the closest one, and we can just stop.
                        // However, we can't just do reverse() on the list because then the indexes are wrong.
                        for (i in (possibleTracks.size - 1) downTo 0) {
                            val checkId = possibleTracks[i].nowPlayingTrackId
                            if (filteredIds.contains(checkId)) {
                                foundIndex = tracks.indexOfFirst { track ->
                                    (track as NowPlayingTrack).nowPlayingTrackId == checkId
                                } + 1

                                break
                            }
                        }
                        foundIndex
                    }

                    addTracksAtIndex(event.tracks, startingIndex)

                    // Now that we added the tracks, we have to go fix all the displayed indexes....
                    val children = trackTableBody.querySelectorAll(".track-row").asList()
                    children.slice(startingIndex ..< children.size).forEachIndexed { adjustmentIndex, row ->
                        val indexCell = (row as HTMLElement).querySelector("[column-index=\"0\"]") as HTMLElement
                        indexCell.innerText = (startingIndex + adjustmentIndex + 1).toString()
                    }
                }
            }
            TRACKS_ADDED_LAST -> {
                if (PageRouter.currentViewMode == ViewMode.NOW_PLAYING) {
                    addTracksAtIndex(event.tracks, insertIndex = tracks.size)
                }
            }
            TRACKS_REMOVED -> {
                if (PageRouter.currentViewMode == ViewMode.NOW_PLAYING) {
                    event as TrackRemovedEvent

                    val tracks = event.tracks
                    val indexes = event.removedIndexes

                    adjustTrackRemovalIndexes(indexes)

                    val deletedIds = tracks.map { it.uniqueIdentifier }.toSet()
                    TrackTable.tracks.removeAll { deletedIds.contains(it.uniqueIdentifier) }

                    tracks.forEach { track ->
                        // There should only be one as it's "NowPlayingTracks" which are unique on this view.
                        // But loop anyway for future safety I guess.
                        getAllRowsForNowPlayingTrack(track).forEach { it.remove() }
                    }
                }
            }
            else -> {}
        }
    }

    private fun addTracksAtIndex(newTracks: List<NowPlayingTrack>, insertIndex: Int) {
        val trackSpacer = document.getElementById("track-table-spacer") as HTMLElement

        var currentRowAddIndex = insertIndex
        val addToEnd = insertIndex == tracks.size

        // Update the table
        newTracks.forEach { track ->
            val newRow = createTrackRow(track)

            if (addToEnd) {
                trackTableBody.insertBefore(newRow, trackSpacer)
            } else {
                val nodeToInsertBefore = trackTableBody.childNodes[currentRowAddIndex]
                trackTableBody.insertBefore(newRow, nodeToInsertBefore)
            }

            currentRowAddIndex++
        }

        // Now update our tracks
        var currentAddIndex = insertIndex
        newTracks.forEach { track ->
            incrementTrackIndexMapping(currentAddIndex)
            tracks.add(currentAddIndex, track)
            trackIndexes[track.uniqueIdentifier] = currentAddIndex
            currentAddIndex++
        }

        firstSelectedIndex?.let { selectedIndex ->
            if (selectedIndex > insertIndex) {
                firstSelectedIndex = selectedIndex + newTracks.size
            }
        }
    }

    fun updateViewMode() {
        expandedGroupKey = null
        selectedIndexes.clear()

        sorts = getDefaultSortForViewMode()

        handleSearchTermChange(LibraryHeader.searchTerm, true)
    }

    fun updateLibraryViewMode() {
        expandedGroupKey = null

        sorts = getDefaultSortForViewMode()
        handleSearchTermChange(LibraryHeader.searchTerm, true)
    }

    private fun getDefaultSortForViewMode(): MutableList<TrackSortItem> {
        val sorts = if (viewMode == ViewMode.PLAYLISTS || viewMode == ViewMode.NOW_PLAYING) {
            mutableListOf(TrackSortItem(TrackColumn.SORT_IDENTIFIER, SortDirection.ASC))
        } else if (!viewMode.hidesLibraryViewMode && libraryViewMode == LibraryViewMode.ALBUM) {
            mutableListOf(TrackSortItem(TrackColumn.ALBUM, SortDirection.ASC))
        } else {
            TrackSort.lastSort.toMutableList()
        }

        return sorts
    }

    private var resizeStartX: Int = 0
    private var resizedColumn: TrackColumn? = null

    private fun handleResizeMouseUp(event: MouseEvent) {
        resizedColumn ?: return

        val diff = event.clientX - resizeStartX

        val columnIndex = columnDefinition.findIndex { it.first == resizedColumn }!!
        val options = columnDefinition[columnIndex].second

        options.width += diff
        if (options.width < 40) {
            options.width = 40
        }

        val element = document.getElementById("table-header-$columnIndex") as HTMLElement
        element.style.width = "${options.width}px"

        TrackSort.columnOptions = columnDefinition

        // FIXME Dumb hack to keep the drag from causing a click.
        mainScope.launch {
            delay(100)
            resizedColumn = null
        }
    }

    fun getSelectedTracks(): List<TrackSortable> {
        // Sort them so that the order is consistent. Otherwise, the order of actions like "play next"
        // depend on the order that you selected stuff. Which COULD be cool. But the UI gives no
        // indication that this is how it would work, so it's highly confusing
        return selectedIndexes.sorted().map { tracks[it] }
    }
    fun getSelectedGroupKey(): String? {
        return selectedGroupKey
    }
    fun getSelectedIndexes(): Set<Int> {
        return selectedIndexes
    }

    private fun getReviewSourceSectionHeader(id: ReviewSourceId): String {
        val session = ReviewQueueService.startOrGetSession()

        val queueName = session.allReviewSources.getValue(id).displayName
        val queueCount = session.reviewSourceToTrackCount[id] ?: 0

        return if (queueCount == 0) {
            ""
        } else {
            "$queueName (${queueCount})"
        }
    }

    fun removeTrack(track: TrackSortable) {
        // FIXME This is not ideal, as it is super review_queue specific and it doesn't NECESSARILY have to be.
        //  The problem we have right now is that any other expandable section (artists / albums) will not
        //  remove the section header if the last track is removed. But this is a bug that is pretty unlikely
        //  to be seen by people. So we will see when it bugs me enough to address it
        if (viewMode == ViewMode.REVIEW_QUEUE) {
            val element = trackTableBody.querySelector("#${track.uniqueTableRowId}") ?: return
            val trackId = track.asTrack().id.value

            val reviewSourceId = track.asTrack().reviewSourceId
                ?: throw IllegalStateException("Track $trackId did not have a review source while removing itself!")

            // The section header can be missing if we remove multiple tracks at once via a bulk review queue action.
            // This is because the first item that comes through removes it and then the next items won't have a header.
            // It's very jank. I hate this entire function / file, tbh.
            val sectionHeader = trackTableBody.querySelector("#group-row-${reviewSourceId.value}")

            if (sectionHeader != null) {
                val newSectionText = getReviewSourceSectionHeader(reviewSourceId)
                if (newSectionText.isEmpty()) {
                    sectionHeader.remove()
                } else {
                    sectionHeader.querySelector(".group-key-name")!!.textContent = newSectionText
                }
            }

            element.remove()

            val session = ReviewQueueService.startOrGetSession()
            session.activeSource?.let { activeSource ->
                if (activeSource.id.value.toString() != expandedGroupKey) {
                    mainScope.launch {
                        expandRelevantGroupSection()
                    }
                }
            }
        } else if (viewMode == ViewMode.PLAYLISTS && track is LoadedPlaylistTrack) {
            val element = trackTableBody.querySelector("#${track.uniqueTableRowId}") ?: return
            element.remove()
        } else {
            getAllRowsForTrack(track.asTrack()).forEach { it.remove() }
        }
    }

    private fun getAllRowsForTrack(track: Track): List<HTMLElement> {
        // These viewModes all can only have a track represented on them a single time. So use the
        // faster ID selection method for getting the tracks.
        return if (viewMode == ViewMode.MY_LIBRARY || viewMode == ViewMode.USERS || viewMode == ViewMode.REVIEW_QUEUE) {
            val element = document.getElementById(track.uniqueTableRowId) ?: return emptyList()
            listOf(element as HTMLElement)
        } else {
            // For every other mode, a track can possibly be in the list multiple times, such as with NowPlaying
            // or a Playlist. So we need to get all Track rows by attribute so we can update them all accordingly.
            val id = track.id.value.toString()
            trackTableBody.querySelectorAll("tr[track-id=\"$id\"]")
                .asList()
                .map { (it as HTMLElement) }
        }
    }

    // This is different for the other function in that this wants to find the unique row.
    // I feel ashamed for the copy paste here but not enough to do something about it.
    private fun getAllRowsForNowPlayingTrack(track: NowPlayingTrack): List<HTMLElement> {
        return when (viewMode) {
            // The only difference here is which bucket does NOW_PLAYING fall into.
            // For this function, we want to only get the exact matching row for the NowPlayingTrack.
            // For the other, similar function, we want to get all rows that match the Track itself.
            ViewMode.MY_LIBRARY, ViewMode.USERS, ViewMode.REVIEW_QUEUE, ViewMode.NOW_PLAYING -> {
                val element = document.getElementById(track.uniqueTableRowId) ?: return emptyList()
                listOf(element as HTMLElement)
            }
            else -> {
                // For every other mode, a track can possibly be in the list multiple times, such as with NowPlaying
                // or a Playlist. So we need to get all Track rows by attribute so we can update them all accordingly.
                val id = track.track.id.value.toString()
                trackTableBody.querySelectorAll("tr[track-id=\"$id\"]")
                    .asList()
                    .map { (it as HTMLElement) }
            }
        }
    }

    private fun handleTrackChange(event: TrackChangeEvent) {
        if (trackTable == null) {
            return
        }

        // We don't currently support adding new tracks to any views. The old frontend supported this and
        // it was pretty buggy. Bugs can be fixed, but even still, then you run into the situation of
        // randomly changing a UI while a user is using it, which can cause them to click wrong things
        // and who knows what else. Probably I will just add a "reload" option to this event eventually.
        if (event.changeType == ChangeType.ADDED) {
            return
        }

        if (event.changeType == ChangeType.UPDATED) {
            // The thing on the event are actual "tracks", and we may be displaying a special, sexier, type
            // of track, like a NowPlayingTrack or a PlaylistTrack. So before we re-render, we need to get
            // references to the more specific type or else some fields like the SORT_IDENTIFIER will be wrong.
            val betterTracks = when (viewMode) {
                ViewMode.NOW_PLAYING -> {
                    val trackIds = event.tracks.map { it.id }.toSet()
                    NowPlayingService.currentNowPlayingTracks.filter { trackIds.contains(it.track.id) }
                }

                ViewMode.PLAYLISTS -> {
                    val trackIds = event.tracks.map { it.id }.toSet()
                    val playlistTracks = PlaylistService.getTracksForPlaylist(
                        playlistId = PlaylistId(PageRouter.getQueryParam("PLAYLIST_ID")!!.toLong())
                    )
                    playlistTracks.filter { trackIds.contains(it.track.id) }
                }

                else -> event.tracks
            }

            betterTracks.forEach { track ->
                val rows = getAllRowsForTrack(track.asTrack())

                rows.forEach { row ->
                    val newRow = createTrackRow(track)

                    row.innerHTML = newRow.innerHTML
                }
            }

            // Now, finally, update our track references themselves
            val uniqueIds = betterTracks.associateBy { it.uniqueIdentifier }
            tracks.forEachIndexed { index, trackSortable ->
                if (uniqueIds.contains(trackSortable.uniqueIdentifier)) {
                    tracks[index] = uniqueIds.getValue(trackSortable.uniqueIdentifier)
                }
            }
        }

        // With any updates done, remove track references that were deleted. Otherwise, a user
        // double-clicking to play stuff will attempt to play deleted things.
        if (event.changeType == ChangeType.DELETED) {
            // Remove the actual rendered table rows from the view
            event.tracks.forEach { removeTrack(it) }

            // We are given tracks in their purest Track form from this event, but we could be looking at a screen
            // that is showing NowPlaying tracks or PlaylistTracks. So one deleted track could end up corresponding
            // to many indexes. We need to find them all first.
            val indexes = event.tracks.flatMap { removedTrack ->
                tracks.mapIndexedNotNull { index, trackSortable ->
                    if (trackSortable.asTrack().id == removedTrack.id) {
                        index
                    } else {
                        null
                    }
                }
            }

            // The listener for NowPlayingTracks will handle this
            if (PageRouter.currentViewMode != ViewMode.NOW_PLAYING) {
                adjustTrackRemovalIndexes(indexes)
            }

            val deletedIds = event.tracks.map { it.id }.toSet()
            tracks.removeAll { deletedIds.contains(it.asTrack().id) }
        }
    }

    private fun adjustTrackRemovalIndexes(indexes: List<Int>) {
        val deletedIndexes = indexes.sortedDescending()
        deletedIndexes.forEach { index ->
            selectedIndexes.remove(index)
            firstSelectedIndex?.let { selectedIndex ->
                if (selectedIndex == index) {
                    firstSelectedIndex = null
                    lastSelectedIndex = null
                } else if (selectedIndex > index) {
                    firstSelectedIndex = selectedIndex - 1
                }
            }
        }

        // Adjust all the indexes for tracks because we deleted something that may have been in front of them.
        deletedIndexes.forEach { deletedIndex ->
            incrementTrackIndexMapping(deletedIndex, decrementInstead = true)
        }
    }

    private fun handleKeyPress(event: KeyboardEvent) {
        // So this is about to get a little messy, because we have a global key listener for this "page", and yet there are a lot
        // of other pages where you may be using key events. We need to filter out all the various places that a user may be using
        // their keyboard to do something else with up, down, and enter, and not respond
        if (!PageRouter.currentViewMode.isTrackView) {
            return
        }
        if (tracks.isEmpty()) {
            return
        }

        // Don't trigger this if the user is in a form as it can cause unintended side-effects
        document.activeElement?.let { activeEl ->
            if (activeEl is HTMLInputElement || activeEl is HTMLTextAreaElement) {
                return
            }
        }
        if (Dialog.isShowing()) {
            return
        }

        val startingIndex = lastSelectedIndex

        fun scrollIntoView(trackIndex: Int) {
            val rowHeight = document.query<HTMLElement>("#track-table .track-row").offsetHeight

            val elementTop = trackIndex * rowHeight
            val elementBottom = elementTop + rowHeight

            val scrollingElement = document.queryId<HTMLElement>("track-table")

            val visibleArea = scrollingElement.offsetHeight - rowHeight - 20
            val existingScroll = scrollingElement.scrollTop
            val existingScrollBottom = existingScroll + visibleArea

            if (existingScroll > elementTop) {
                scrollingElement.scrollTop = elementTop.toDouble()
            } else if (elementBottom > existingScrollBottom) {
                scrollingElement.scrollTop = elementBottom.toDouble() - visibleArea
            }
        }

        fun triggerClick(trackIndex: Int) {
            val mouseEvent = MouseEvent("click", MouseEventInit(shiftKey = event.shiftKey))
            val rows = document.querySelectorAll(".track-row")

            val element = rows[trackIndex] as HTMLElement
            element.dispatchEvent(mouseEvent)

            scrollIntoView(trackIndex)
        }

        when (event.keyCode) {
            13 -> { // Enter
                if (startingIndex != null) {
                    val tracks = getSelectedTracks()
                    if (tracks.size == 1) {
                        handleDoubleClickPlayRequest(startingIndex)
                    } else {
                        if (PageRouter.currentViewMode == ViewMode.NOW_PLAYING) {
                            val firstTrack = tracks.first() as NowPlayingTrack
                            NowPlayingService.playFromNowPlayingId(firstTrack.nowPlayingTrackId)
                        } else {
                            NowPlayingService.setNowPlayingTracks(tracks.map { it.asTrack() }, playFromIndex = 0)
                        }
                    }
                }
            }
            38 -> { // Up
                event.preventDefault()

                if (startingIndex == null) {
                    return
                }
                // Avoid "double-clicking" by pushing up while it's already at the top
                if (startingIndex == 0 && selectedIndexes.size == 1) {
                    return
                }

                val newEndRow = if (startingIndex > 1) {
                    startingIndex - 1
                } else {
                    0
                }

                triggerClick(newEndRow)
            }
            40 -> { // Down
                event.preventDefault()

                val lastIndex = tracks.size - 1

                // Avoid "double-clicking" by pushing up while it's already at the top
                if (startingIndex == lastIndex && selectedIndexes.size == 1) {
                    return
                }

                val newEndRow = if (startingIndex == null) {
                    firstSelectedIndex = 0
                    lastSelectedIndex = 0

                    0
                } else if (startingIndex < lastIndex) {
                    startingIndex + 1
                } else {
                    lastIndex
                }

                triggerClick(newEndRow)
            }
        }
    }
}

private val TrackSortable.uniqueTableRowId get() = if (this is NowPlayingTrack && PageRouter.currentViewMode == ViewMode.NOW_PLAYING) {
    "table-row-${this.nowPlayingTrackId.value}"
} else if (this is LoadedPlaylistTrack) {
    "table-row-${this.playlistTrack.id.value}"
} else {
    "table-row-${this.asTrack().id.value}"
}
