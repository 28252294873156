package net.gorillagroove.authentication

import kotlinx.serialization.Serializable
import net.gorillagroove.api.Api

object PasswordResetService {
    @Throws(Throwable::class)
    suspend fun resetPassword(email: String) {
        val request = PasswordResetRequest(email.trim())
        Api.post<Unit>("password-reset", request)
    }

    @Throws(Throwable::class)
    suspend fun getPasswordChange(key: String): PasswordResetGetResponse {
        return Api.get<PasswordResetGetResponse>("password-reset/unique-key/$key")
    }

    @Throws(Throwable::class)
    suspend fun changePassword(password: String, key: String) {
        val request = PasswordChangeRequest(newPassword = password, uniqueKey = key)
        Api.put<Unit>("password-reset", request)
    }
}

@Serializable
internal data class PasswordResetRequest(val email: String)

@Serializable
data class PasswordResetGetResponse(val username: String)

@Serializable
internal data class PasswordChangeRequest(val newPassword: String, val uniqueKey: String)


