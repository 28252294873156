import kotlinx.browser.document
import kotlinx.coroutines.Job
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlinx.html.button
import kotlinx.html.div
import kotlinx.html.dom.create
import kotlinx.html.i
import kotlinx.html.id
import kotlinx.html.js.onClickFunction
import net.gorillagroove.reporting.DialogEventBus
import net.gorillagroove.reporting.DialogEventData
import net.gorillagroove.util.GGLog.logDebug
import net.gorillagroove.util.GGLog.logWarn
import org.w3c.dom.HTMLElement
import kotlin.time.Duration.Companion.minutes

object DialogNonBlocking {
    private var dialogsOpened = 0

    private var nextDialogId = 0

    // For now, I am only allowing a single dialog to show. The only thing that this is currently used for is resuming
    // playback from other devices. If we get an additional request to restore playback while we already have one, then
    // we don't want to display another one.
    // TODO whenever we do increase this beyond 1, we will need a way of replacing previous dialogs of the same type.
    //  That isn't currently a supported thing and dialogs don't even have types right now.
    //  The way that the autoCloseDialogJob works also will have to change, probably.
    private const val SUPPORTED_DIALOGS = 1

    private var autoCloseDialogJob: Job? = null

    fun init() {
        document.body!!.append(
            document.create.div {
                id = "dialog-non-blocking-container"
            }
        )

        DialogEventBus.registerDialogEventHandler { event ->
            if (event.blockingMode) {
                return@registerDialogEventHandler
            }

            showEventModal(event)
        }
    }

    private fun getContainer(): HTMLElement {
        return document.getElementById("dialog-non-blocking-container") as HTMLElement
    }

    private fun getCurrentItemContainer(modalId: Int): HTMLElement? {
        return getContainer().querySelector("#dialog-non-blocking-item-container-$modalId") as HTMLElement?
    }

    private fun createItemContainer(modalId: Int): HTMLElement {
        return document.create.div("dialog-non-blocking-item-container") {
            id = "dialog-non-blocking-item-container-$modalId"

            button(classes = "icon close-button") {
                i("fa-solid fa-xmark")

                onClickFunction = { remove(dialogsOpened) }
            }
        }
    }

    fun show(element: HTMLElement) {
        if (dialogsOpened + 1 > SUPPORTED_DIALOGS) {
            logWarn("Too many non-blocking dialogs attempted to be opened. Closing them")
            removeAll()
        }

        dialogsOpened++

        val itemContainer = createItemContainer(++nextDialogId)
        itemContainer.append(element)

        getContainer().append(itemContainer)
    }

    fun show(createElementHandler: (modalId: Int) -> HTMLElement) {
        if (dialogsOpened + 1 > SUPPORTED_DIALOGS) {
            logWarn("Too many non-blocking dialogs attempted to be opened. Closing them")
            removeAll()
        }

        dialogsOpened++

        val id = ++nextDialogId
        val itemContainer = createItemContainer(id)

        createElementHandler(id)

        itemContainer.append(createElementHandler(id))

        getContainer().append(itemContainer)
    }

    fun remove(id: Int) {
        getCurrentItemContainer(id)?.let { dialog ->
            logDebug("Removing non-blocking dialog by id: $id")

            dialog.remove()
            dialogsOpened--
        }
    }

    private fun removeAll() {
        getContainer().removeChildren()
        dialogsOpened = 0
    }

    private fun showEventModal(event: DialogEventData) {
        show { id ->
            // If we give someone a non-blocking dialog from an event, and they don't respond to it within
            // a certain amount of time, then just close it. Don't need to leave them open for a long time.
            // May have to tweak this when it's used for more than playback resume.
            autoCloseDialogJob?.cancel()
            autoCloseDialogJob = mainScope.launch {
                delay(5.minutes)
                logDebug("Removing non-blocking dialog if it's still around with ID $id")
                remove(id)
            }

            document.create.div {
                div("text-left") {
                    + (event.message ?: "")
                }

                div("space-between mt-12") {
                    button(classes = "flat") {
                        + (event.noText ?: "")
                        onClickFunction = {
                            event.noAction()
                            remove(id)
                        }
                    }

                    button(classes = "primary") {
                        + (event.yesText ?: "")
                        onClickFunction = {
                            event.yesAction()
                            remove(id)
                        }
                    }
                }
            }
        }
    }
}
