package net.gorillagroove.track

import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import net.gorillagroove.api.*
import net.gorillagroove.authentication.AuthService
import net.gorillagroove.hardware.DeviceType
import net.gorillagroove.localstorage.LocalStorage
import net.gorillagroove.reporting.DialogEventBus
import net.gorillagroove.reporting.DialogEventData
import net.gorillagroove.user.Device
import net.gorillagroove.user.DeviceResponse
import net.gorillagroove.user.permission.PermissionService
import net.gorillagroove.user.permission.UserPermissionType
import net.gorillagroove.util.Formatter
import net.gorillagroove.util.Formatter.toTimeAgoString
import net.gorillagroove.util.GGLog.logCrit
import net.gorillagroove.util.GGLog.logDebug
import net.gorillagroove.util.GGLog.logError
import net.gorillagroove.util.GGLog.logInfo
import net.gorillagroove.util.GGLog.logWarn
import net.gorillagroove.util.SettingType
import net.gorillagroove.util.Settings.getBoolean
import net.gorillagroove.util.Settings.getInt
import net.gorillagroove.util.Settings.setBoolean
import net.gorillagroove.util.Settings.setInt
import net.gorillagroove.util.TimeUtil.now
import net.gorillagroove.util.debounce
import kotlin.time.Duration
import kotlin.time.Duration.Companion.minutes
import kotlin.time.Duration.Companion.seconds

object PlaySessionService {
    private val scope = CoroutineScope(Dispatchers.Default)
    const val LOCAL_PLAYBACK_KEY = "local_playback_state"

    var playbackResumeAccountEnabled: Boolean
        get() = getBoolean(SettingType.PLAYBACK_RESUME_ACCOUNT_ENABLED, true)
        set(value) = setBoolean(SettingType.PLAYBACK_RESUME_ACCOUNT_ENABLED, value)

    var playbackResumeFromDevice: Boolean
        get() = getBoolean(SettingType.PLAYBACK_RESUME_FROM_THIS_DEVICE, true)
        set(value) = setBoolean(SettingType.PLAYBACK_RESUME_FROM_THIS_DEVICE, value)

    var playbackResumeToDevice: Boolean
        get() = getBoolean(SettingType.PLAYBACK_RESUME_TO_THIS_DEVICE, true)
        set(value) = setBoolean(SettingType.PLAYBACK_RESUME_TO_THIS_DEVICE, value)

    var playbackResumeAutomaticResume: Boolean
        get() = getBoolean(SettingType.PLAYBACK_RESUME_AUTOMATIC_RESUME, false)
        set(value) = setBoolean(SettingType.PLAYBACK_RESUME_AUTOMATIC_RESUME, value)

    var playbackResumeOfferCutoff: Duration
        get() = getInt(SettingType.PLAYBACK_RESUME_OFFER_CUTOFF, 15).minutes
        set(value) = setInt(SettingType.PLAYBACK_RESUME_OFFER_CUTOFF, value.inWholeMinutes.toInt())

    // Swift treats Durations strangely so they are not great for a public API for iOS.
    // It would be one thing if it was just like, Nanoseconds. But as this guy ALSO noticed, it appears
    // to, for some reason, be HALF-nanoseconds. And that is just too strange for me to accept.
    // https://slack-chats.kotlinlang.org/t/16408598/hello-i-noticed-that-kotlin-time-duration-is-inconsistently-
    var playbackResumeOfferCutoffMinutes: Int
        get() = playbackResumeOfferCutoff.inWholeMinutes.toInt()
        set(value) { playbackResumeOfferCutoff = value.minutes }

    private var lastResumeOfferedLastPlayback: Long
        get() = LocalStorage.readLong("auto_resume_last_playback", 0)
        set(value) { LocalStorage.writeLong("auto_resume_last_playback", value) }

    @Throws(Throwable::class)
    suspend fun getPlaySessions(): PlaySessionResponseData {
        val data = try {
            Api.get<PlaySessionGetResponse>("play-session")
        } catch (e: Exception) {
            logError("Failed to get PlaySessions!", e)
            throw e
        }

        val (currentSession, otherSessions) = data.items.partition { it.deviceId == data.currentDevice.id }

        if (currentSession.size > 1) {
            logCrit("Found more than 1 'currentSession' for PlaySession response! $currentSession")
        }

        return PlaySessionResponseData(
            currentDevice = data.currentDevice.toDevice(),
            currentSession = currentSession.firstOrNull(),
            otherSessions = otherSessions,
        )
    }

    @Throws(Throwable::class)
    suspend fun getRecentPlaySession(): RemoteNowListeningState? {
        // The API will always give us the most recent play session that meets the following criteria:
        // 1) It was not made from this device. If we get a session here, we didn't start it.
        // 2) It was not made on a device that opted out of play sessions
        // 3) It had at least one track on it. It's weird, but you could theoretically have music on Device A,
        //    then go listen to stuff on Device B. Then you clear out your NowPlaying on Device B and open up
        //    Device C. It will offer to resume playback from A, not B. Probably this will never happen....
        val state = Api.get<PlaySessionResponse>("play-session/recent").state ?: return null

        // If you're ALREADY playing GG from another device and you open up this one, there's a much higher
        // probability that you do not want to automatically switch. At least in my own usage, if I am opening
        // up a second GG instance, it's because I'm just wanting to check something or I just switch tabs
        // absent-mindedly. If you are actually wanting to auto-switch, probably pause your old session first.
        // However, I'm still doing this check in the client because this is opinionated and might change.
        if (state.isPlaying) {
            logDebug("Not returning recent session as it was actively playing")
            return null
        }

        val difference = now() - Instant.fromEpochMilliseconds(state.lastPlayback)
        return if (difference > playbackResumeOfferCutoff) {
            logDebug("Not returning recent session as it was too old. Cutoff: ${playbackResumeOfferCutoff.inWholeMinutes}. Amount: $difference")
            null
        } else state
    }

    @Throws(Throwable::class)
    suspend fun setPlayStateFromPlaySession(state: NowListeningState, startPlayback: Boolean) {
        NowPlayingService.pause()

        if (state is RemoteNowListeningState) {
            logInfo("Setting play state from play session from device: ${state.deviceId} - ${state.deviceName}")
        } else {
            logInfo("Setting play state from locally stored session")
        }

        val uniqueIds = state.nowPlaying.toSet()

        val tracks = TrackService.findByApiIds(uniqueIds).associateBy { it.apiId!! }

        var indexAdjustment = 0

        val missingTrackIds = mutableListOf<TrackApiId>()
        val orderedTracks = state.nowPlaying.mapIndexedNotNull { index, trackApiId ->
            return@mapIndexedNotNull tracks[trackApiId] ?: run {
                // It's possible that a track that is currently in this session no longer exists.
                // If that's true, we want to adjust the index or else we won't actually end up
                // playing the track that is intended to be played. We'd be off. However, this
                // only applies to tracks BEFORE the index. Anything after can be harmlessly dropped.
                if (index < state.trackIndex) {
                    indexAdjustment++
                }
                missingTrackIds.add(trackApiId)
                null
            }
        }

        val trackIndex = if (missingTrackIds.isNotEmpty()) {
            logWarn("A play session is being restored, but some track IDs were missing. Index is adjusted by $indexAdjustment. Tracks missing: $missingTrackIds")
            val intendedIndex = (state.trackIndex - indexAdjustment)
            if (orderedTracks.isEmpty()) {
                -1
            } else if (intendedIndex >= orderedTracks.size) {
                orderedTracks.size - 1
            } else {
                intendedIndex
            }
        } else {
            state.trackIndex
        }

        NowPlayingService.setFromPlaySession(state, trackIndex, orderedTracks, startPlayback)
    }

    fun handlePlaySessionResumeBroadcast(): Job? {
        // Check if we opted out at the account-level and never want to resume playback
        if (!playbackResumeAccountEnabled) {
            logDebug("Not resuming playback as it was disabled at the account level")
            return null
        }

        // Check if we opted out at the device-level instead
        if (!playbackResumeToDevice) {
            logDebug("Not resuming playback as it was disabled at the device level")
            return null
        }

        return scope.launch {
            val session = try {
                getRecentPlaySession() ?: return@launch
            } catch (e: Exception) {
                logError("Failed to get recent play session", e)
                return@launch
            }

            // We have seen this play session before. Do not offer to resume it again.
            if (session.lastPlayback == lastResumeOfferedLastPlayback) {
                return@launch
            }

            lastResumeOfferedLastPlayback = session.lastPlayback

            if (playbackResumeAutomaticResume) {
                logInfo("Automatically resuming playback")
                setPlayStateFromPlaySession(session, startPlayback = false)
                return@launch
            }

            val modal = DialogEventData(
                message = "Pick up listening where you left off on device: '${session.deviceName}'?",
                yesText = "Let's jam",
                noText = "No thanks",
                blockingMode = false,
                yesAction = {
                    scope.launch {
                        try {
                            setPlayStateFromPlaySession(session, startPlayback = true)
                        } catch (e: Exception) {
                            logError("Failed to resume playback from session", e)
                        }
                    }
                },
            )

            DialogEventBus.broadcast(modal)
        }
    }

    fun saveLocalPlaybackStateAsync() = debounce(this, 2.seconds) {
        scope.launch { saveLocalPlaybackState() }
    }

    fun saveLocalPlaybackState() {
        if (!AuthService.isAuthenticated()) {
            return
        }

        val state = LocalPlaySessionState(
            nowPlaying = NowPlayingService.currentNowPlayingTracks.mapNotNull { it.track.apiId },
            trackIndex = NowPlayingService.currentIndex,
            currentTime = NowPlayingService.currentTime,
        )

        // I use LocalStorage here instead of a DB setting entirely because of the frontend.
        // This needs to be written to somewhat regularly, and the frontend's database persistence
        // is lackluster. The DB connection is reset every time we save it, so we buffer the saves.
        // And can occasionally cause a failed DB read if it happens at a bad time.
        // It is also impossible to persist DB state on the frontend AS the tab is closed since we
        // write the DB to IndexedDb and it has an asynchronous API that can't be waited on.
        // That is the ideal time TO persist state, and we can do it via LocalStorage.
        LocalStorage.writeString(LOCAL_PLAYBACK_KEY, Json.encodeToString(state))
    }

    suspend fun loadLocalPlaybackState() {
        val stateJson = LocalStorage.readString(LOCAL_PLAYBACK_KEY) ?: return
        val state = try {
            Json.decodeFromString<LocalPlaySessionState>(stateJson)
        } catch (e: Exception) {
            logCrit("Failed to decode LocalPlaybackStateResumeData! Json: $stateJson", e)
            return
        }

        setPlayStateFromPlaySession(state, startPlayback = false)
    }
}

@Serializable
internal data class PlaySessionResponse(val state: RemoteNowListeningState?)

@Serializable
internal data class PlaySessionGetResponse(
    val currentDevice: DeviceResponse,
    val items: List<RemoteNowListeningState>,
)

data class PlaySessionResponseData(
    val currentDevice: Device,
    val currentSession: RemoteNowListeningState?,
    val otherSessions: List<RemoteNowListeningState>,
)

interface NowListeningState {
    val nowPlaying: List<TrackApiId>
    val trackIndex: Int
    val currentTime: Double
}

@Serializable
data class RemoteNowListeningState(
    val deviceId: DeviceId,
    val deviceName: String,
    val deviceType: DeviceType,
    val userId: UserId,
    override val currentTime: Double,
    val trackData: PlayingTrackData?,
    override val trackIndex: Int,
    val isShuffling: Boolean,
    val isRepeating: Boolean,
    val isPlaying: Boolean,
    override val nowPlaying: List<TrackApiId>,
    val lastPlayback: Long, // Millis. Should be more or less continually updated while playing and stops when they stop.
    val lastTimeUpdate: Long, // Millis. The last time the SERVER received a message from the client.
) : NowListeningState {
    fun timeAgoString(): String {
        return Instant.fromEpochMilliseconds(lastPlayback).toTimeAgoString()
    }

    // For iOS convenience as its "doCopy" function on data classes doesn't have defaults.
    // One day it PROBABLY will have defaults when Swift export is better, and then this could go away.
    @Suppress("unused")
    fun withNewDeviceName(newName: String): RemoteNowListeningState {
        return this.copy(deviceName = newName)
    }

    @Suppress("unused")
    val trackString: String get() {
        val track = trackData ?: return "Nothing"
        return Formatter.getPlayingTrackDisplayString(
            name = track.name ?: "",
            artist = track.artist ?: "",
            featuring = track.featuring ?: ""
        )
    }
}

@Serializable
internal data class LocalPlaySessionState(
    // API IDs because we could have tracks from other users in here.
    // The tracks will have to be restored async, but it is what it is.
    // This will not work if I ever support local-only tracks....
    // But who knows if I ACTUALLY will. Deal with it later
    override val nowPlaying: List<TrackApiId>,
    override val trackIndex: Int,
    override val currentTime: Double,
) : NowListeningState
