package components.contextmenu

import LibraryViewMode
import PageRouter
import Toast
import components.TrackTable
import kotlinx.coroutines.launch
import mainScope
import net.gorillagroove.favorite.FavoriteService
import net.gorillagroove.sync.UserFavoriteType
import net.gorillagroove.util.GGLog.logError
import org.w3c.dom.events.MouseEvent

object GroupKeyContextMenu {
    private val type get() = when (PageRouter.currentLibraryViewMode) {
        LibraryViewMode.TRACK -> throw IllegalArgumentException("Track favorite types not supported!")
        LibraryViewMode.ARTIST -> UserFavoriteType.ARTIST
        LibraryViewMode.ALBUM -> UserFavoriteType.ALBUM
    }

    private val favoriteItem = ContextMenuOption("Add Favorite") {
        mainScope.launch {
            val mode = PageRouter.currentLibraryViewMode

            try {
                FavoriteService.createFavorite(type, TrackTable.getSelectedGroupKey()!!)
            } catch (e: Exception) {
                Toast.error("Failed to create favorite")
                logError("Failed to create favorite!", e)

                return@launch
            }

            if (PageRouter.currentViewMode == ViewMode.MY_LIBRARY && PageRouter.currentLibraryViewMode == mode) {
                TrackTable.calculateFavorites()
                TrackTable.renderTable()
            }
        }
    }

    private val unFavoriteItem = ContextMenuOption("Remove Favorite") {
        mainScope.launch {
            val mode = PageRouter.currentLibraryViewMode

            try {
                FavoriteService.deleteFavorite(type, TrackTable.getSelectedGroupKey()!!)
            } catch (e: Exception) {
                Toast.error("Failed to delete favorite")
                logError("Failed to delete favorite!", e)

                return@launch
            }

            if (PageRouter.currentViewMode == ViewMode.MY_LIBRARY && PageRouter.currentLibraryViewMode == mode) {
                TrackTable.calculateFavorites()
                TrackTable.renderTable()
            }
        }
    }

    fun open(event: MouseEvent, existingFavorites: Set<String>) {
        val groupKey = TrackTable.getSelectedGroupKey() ?: run {
            logError("No selected group key found when opening GroupKeyContextMenu!")
            return
        }

        val isFavorite = existingFavorites.contains(groupKey)

        val option = if (isFavorite) unFavoriteItem else favoriteItem

        ContextMenu.open(event, listOf(option))
    }
}
