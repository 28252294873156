package net.gorillagroove.sync.strategies

import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import net.gorillagroove.api.*
import net.gorillagroove.db.*
import net.gorillagroove.db.Database.playlistTrackDao
import net.gorillagroove.db.Database.reviewSourceDao
import net.gorillagroove.db.Database.trackDao
import net.gorillagroove.hardware.PlatformDeviceUtil
import net.gorillagroove.review.ReviewQueueService
import net.gorillagroove.track.Track
import net.gorillagroove.track.toTrack
import net.gorillagroove.sync.*
import net.gorillagroove.track.*
import net.gorillagroove.user.UserService
import net.gorillagroove.util.GGLog.logCrit
import net.gorillagroove.util.GGLog.logDebug
import net.gorillagroove.util.GGLog.logInfo

object TrackSyncStrategy : SyncDownStrategy {
    override val syncType: SyncableEntity = SyncableEntity.TRACK

    override suspend fun syncDown(syncStatus: DbSyncStatus, onPageSyncedHandler: PageSyncHandler) {
        val savedReviewSources = reviewSourceDao.findAll().many().map { it.id }.toMutableSet()

        fetchSyncEntities<TrackResponse>(syncType, syncStatus, onPageSyncedHandler) { changeSet ->
            val reviewSourcesOnTracks = changeSet.newAndModified.mapNotNull { it.reviewSourceId }.toSet()
            val missingReviewSources = reviewSourcesOnTracks - savedReviewSources

            // A ReviewSource might not be synced if it has been deleted. However, the Track will still
            // have a FK to it, and we will want to display this information for where the Track came from.
            // So, go get any missing ReviewSources specifically, prior to saving the Tracks.
            if (missingReviewSources.isNotEmpty()) {
                logInfo("Missing review sources on Tracks: ${missingReviewSources.map { it.value }}")
                ReviewSourceSyncStrategy.syncById(missingReviewSources)
                savedReviewSources.addAll(missingReviewSources)
            }

            val newBroadcasts = mutableListOf<Track>()
            val updatedBroadcasts = mutableListOf<Track>()
            val deletedBroadcasts = mutableListOf<Track>()

            val cacheDataToRemove = mutableMapOf<TrackId, Set<TrackLinkType>>()

            logDebug("About to start DB transaction")
            Database.db.transaction {
                val trackApiIdToLocalId = TrackService.findLocalIdForApiId(changeSet.newAndModified.map { it.id })

                changeSet.new.forEach {
                    val track = it.asTrack(trackApiIdToLocalId)
                    TrackService.save(track)
                    newBroadcasts.add(track.toTrack())
                }
                changeSet.modified.forEach {
                    updateTrack(it)?.let { (track, cachesToRemove) ->
                        cacheDataToRemove[track.id] = cachesToRemove
                        updatedBroadcasts.add(track.toTrack())
                    }
                }
                changeSet.removed.forEach {
                    val existingTrack = trackDao.findByApiId(TrackApiId(it)).oneOrNull()
                    if (existingTrack != null) {
                        logDebug("Deleting Track with API ID: $it")
                        playlistTrackDao.deleteByTrack(existingTrack.id)
                        trackDao.deleteByApiId(existingTrack.apiId)
                        deletedBroadcasts.add(existingTrack.toTrack())
                    } else {
                        // This can happen easily if you deleted it on-device.
                        logDebug("Should have deleted track with API ID: $it but it was not found")
                    }
                }

                logDebug("About to end DB transaction")
            }
            logDebug("Ended DB transaction")

            cacheDataToRemove.forEach { (trackId, linkTypes) ->
                linkTypes.forEach { linkType ->
                    TrackCacheService.deleteCacheOnDisk(trackId, linkType)
                }
            }

            // FIXME This lets us know if something was done to put or delete a track from review, but this does
            //  not let us know if we approved a track that is in review on another device.
            val inReviewTrackChanged = (newBroadcasts + updatedBroadcasts + deletedBroadcasts).any { it.inReview }
            if (inReviewTrackChanged) {
                ReviewQueueService.invalidateSession()
            }

            if (newBroadcasts.isNotEmpty()) {
                TrackService.broadcastTrackChange(newBroadcasts, ChangeType.ADDED)
            }
            if (updatedBroadcasts.isNotEmpty()) {
                TrackService.broadcastTrackChange(updatedBroadcasts, ChangeType.UPDATED)
            }
            if (deletedBroadcasts.isNotEmpty()) {
                TrackService.broadcastTrackChange(deletedBroadcasts, ChangeType.DELETED)
            }
        }
    }

    private fun updateTrack(trackResponse: TrackResponse): Pair<DbTrack, Set<TrackLinkType>>? {
        val existingTrack = trackDao.findByApiId(trackResponse.id).oneOrNull() ?: run {
            // It's possible for this to happen during routine operation.
            // You need to have the following scenario happen:
            // 1) Be added to an existing playlist by someone else that already has Tracks on it.
            // 2) One of those Tracks needs to have been recently updated.
            // When this happens, the API will be wrong when it tries to batch things into NEW or MODIFIED
            // sections. I'm not sure if it's even feasible for the API to handle this correctly, tbh.
            // But it doesn't. So we get access to Tracks before we have synced the PlaylistUser that tells us
            // about the playlist. This is ok to ignore, as when we sync the PlaylistUser (presumably, immediately
            // after this happened), we will do a full sync of everything on the Playlist
            val message = "Unable to update existing track with ID ${trackResponse.id.value}"
            if (trackResponse.userId == UserService.requireCurrentUserId()) {
                // Crit if it's our own user. This is STILL unexpected.
                logCrit(message)
            } else {
                logInfo(message)
            }
            return null
        }

        val cacheTypesToDelete = mutableSetOf<TrackLinkType>()

        // If the Track has had its audio / art data updated more recently than our cache, then it means
        // we need to ditch our data as it's no longer valid.
        val audioCachedAt = if (existingTrack.audioCachedAt != null && trackResponse.audioUpdatedAt > existingTrack.audioCachedAt) {
            cacheTypesToDelete.add(PlatformDeviceUtil.getDefaultAudioFormat().toTrackLinkType())
            null
        } else {
            existingTrack.audioCachedAt
        }
        val artCachedAt = if (existingTrack.artCachedAt != null && trackResponse.artUpdatedAt > existingTrack.artCachedAt) {
            cacheTypesToDelete.add(TrackLinkType.ART_PNG)
            null
        } else {
            existingTrack.artCachedAt
        }
        val thumbnailCachedAt = if (existingTrack.thumbnailCachedAt != null && trackResponse.artUpdatedAt > existingTrack.thumbnailCachedAt) {
            cacheTypesToDelete.add(TrackLinkType.THUMBNAIL_PNG)
            null
        } else {
            existingTrack.thumbnailCachedAt
        }

        val updatedTrack = existingTrack.copy(
            name = trackResponse.name,
            artist = trackResponse.artist,
            featuring = trackResponse.featuring,
            album = trackResponse.album,
            trackNumber = trackResponse.trackNumber,
            length = trackResponse.length,
            releaseYear = trackResponse.releaseYear,
            genre = trackResponse.genre,
            playCount = trackResponse.playCount,
            isPrivate = trackResponse.private,
            isHidden = trackResponse.hidden,
            addedToLibrary = trackResponse.addedToLibrary,
            lastPlayed = trackResponse.lastPlayed,
            lastReviewed = trackResponse.lastReviewed,
            inReview = trackResponse.inReview,
            note = trackResponse.note,
            offlineAvailability = trackResponse.offlineAvailability,
            filesizeAudioOgg = trackResponse.filesizeAudioOgg,
            filesizeAudioMp3 = trackResponse.filesizeAudioMp3,
            filesizeArtPng = trackResponse.filesizeArtPng,
            filesizeThumbnailPng = trackResponse.filesizeThumbnail64x64Png,
            reviewSourceId = trackResponse.reviewSourceId,
            audioCachedAt = audioCachedAt,
            artCachedAt = artCachedAt,
            thumbnailCachedAt = thumbnailCachedAt,
        )
        trackDao.upsert(updatedTrack)
        return updatedTrack to cacheTypesToDelete
    }
}

@Serializable
data class TrackResponse(
    val id: TrackApiId,
    val userId: UserId,
    val name: String,
    val artist: String,
    val featuring: String,
    val album: String,
    val trackNumber: Int?,
    val length: Int,
    val releaseYear: Int?,
    val genre: String,
    val playCount: Int,
    val private: Boolean,
    val inReview: Boolean,
    val recommendedByUserId: UserId?,
    val hidden: Boolean,
    val lastPlayed: Instant?,
    val addedToLibrary: Instant?,
    val note: String,
    val audioUpdatedAt: Instant,
    val artUpdatedAt: Instant,
    val offlineAvailability: RawOfflineAvailabilityType,
    val filesizeAudioOgg: Int,
    val filesizeAudioMp3: Int,
    val filesizeArtPng: Int,
    val filesizeThumbnail64x64Png: Int,
    val reviewSourceId: ReviewSourceId?,
    val lastReviewed: Instant?,
) {
    fun asTrack(
        apiTrackIdsToLocalIds: Map<TrackApiId, TrackId> = TrackService.findLocalIdForApiId(setOf(id))
    ) = DbTrack(
        id = apiTrackIdsToLocalIds[id] ?: TrackId(0),
        apiId = id,
        userId = userId,
        name = name,
        artist = artist,
        featuring = featuring,
        album = album,
        trackNumber = trackNumber,
        length = length,
        releaseYear = releaseYear,
        genre = genre,
        playCount = playCount,
        isPrivate = private,
        inReview = inReview,
        recommendedBy = recommendedByUserId,
        isHidden = hidden,
        lastPlayed = lastPlayed,
        addedToLibrary = addedToLibrary,
        note = note,
        offlineAvailability = offlineAvailability,
        filesizeAudioOgg = filesizeAudioOgg,
        filesizeAudioMp3 = filesizeAudioMp3,
        filesizeArtPng = filesizeArtPng,
        filesizeThumbnailPng = filesizeThumbnail64x64Png,
        reviewSourceId = reviewSourceId,
        lastReviewed = lastReviewed,
        artCachedAt = null,
        audioCachedAt = null,
        thumbnailCachedAt = null,
        startedOnDevice = null,
    )
}
