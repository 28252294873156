package net.gorillagroove.user

import kotlinx.datetime.Clock.System.now
import net.gorillagroove.api.UserId
import net.gorillagroove.db.Database.userDao
import net.gorillagroove.db.many
import net.gorillagroove.db.oneOrNull
import net.gorillagroove.localstorage.CurrentUserStore
import net.gorillagroove.localstorage.NotSignedInException

object UserService {
    fun findById(id: UserId): User? = userDao.findById(id).oneOrNull()?.toUser()

    fun findByIds(ids: List<UserId>): List<User> {
        if (ids.isEmpty()) {
            return emptyList()
        }
        return userDao.findByIds(ids).many().toUsers()
    }

    fun findExcludingIds(ids: Collection<UserId>, excludeInactive: Boolean = false): List<User> {
        val now = now()
        return userDao.findExcludingByIds(ids).many()
            .toUsers()
            .filter { !excludeInactive || it.hasRecentActivity(now) }
            .sortedWith(compareBy(String.CASE_INSENSITIVE_ORDER) { it.name })
    }

    fun findAll(): List<User> {
        return userDao.findAll().many().toUsers()
    }

    fun findOtherUsers(): List<User> {
        val selfId = getCurrentUserId()
        return userDao.findAll().many()
            .filter { it.id != selfId }
            .sortedWith(compareBy(String.CASE_INSENSITIVE_ORDER) { it.name })
            .toUsers()
    }

    fun getCurrentUserId(): UserId? {
        return CurrentUserStore.getInfo()?.id
    }

    fun requireCurrentUserId(): UserId {
        return getCurrentUserId() ?: throw NotSignedInException()
    }

    fun getCurrentUser(): User? {
        return getCurrentUserId()?.let { findById(it) }
    }

    fun requireCurrentUser(): User {
        val id = requireCurrentUserId()
        return findById(id) ?: throw IllegalStateException("No user found with ID ${id.value}!")
    }
}
