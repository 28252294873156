package net.gorillagroove.user

import kotlinx.serialization.Serializable
import net.gorillagroove.api.Api

object AccountService {
    @Throws(Throwable::class)
    suspend fun createAccount(username: String, email: String, password: String, inviteKey: String) {
        Api.post<Unit>("user/public", CreateUserRequest(username, email, password, inviteKey))
    }

    @Throws(Throwable::class)
    suspend fun invite(): String {
        return Api.post<CreateUserInviteLinkResponse>("user-invite-link").link
    }

    @Throws(Throwable::class)
    suspend fun getInvitation(identifier: String): GetUserInviteLinkResponse {
        return Api.get<GetUserInviteLinkResponse>("user-invite-link/public/$identifier")
    }
}

@Serializable
internal data class CreateUserInviteLinkResponse(val link: String)

@Serializable
data class GetUserInviteLinkResponse(val invitingUserName: String, val alreadyUsed: Boolean)

@Serializable
data class CreateUserRequest(val username: String, val email: String, val password: String, val inviteLinkIdentifier: String)
