package net.gorillagroove.track

import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import kotlinx.datetime.Clock.System.now
import kotlinx.datetime.Instant
import net.gorillagroove.GGCommonInternal
import net.gorillagroove.track.NowPlayingEventType.*
import net.gorillagroove.util.*
import net.gorillagroove.util.GGLog.logCrit
import net.gorillagroove.util.GGLog.logDebug
import net.gorillagroove.util.GGLog.logError
import net.gorillagroove.util.GGLog.logInfo
import net.gorillagroove.util.GGLog.logWarn
import net.gorillagroove.util.Lock
import net.gorillagroove.util.SettingType
import net.gorillagroove.util.use
import kotlin.jvm.JvmInline

typealias NowPlayingEventHandler = (eventType: NowPlayingEvent) -> Unit

@Suppress("VARIABLE_IN_SINGLETON_WITHOUT_THREAD_LOCAL")
object NowPlayingService {
    private val lock: Lock = Lock()

    private var nextNowPlayingTrackId: Int = 1

    private var _isShuffling: Boolean = Settings.getBoolean(SettingType.SHUFFLE, false)
    var isShuffling
        get() = _isShuffling
        set(value) = lock.use {
            if (value) enableShuffle() else disableShuffle()

            // Set this after the shuffle enable / disable functions as they rely on the OLD value to do stuff.
            // Not the most intelligent thing I've ever done. This file is a bit of a mess.
            _isShuffling = value
            Settings.setBoolean(SettingType.SHUFFLE, value)

            reassignOrders()

            emitEvent(NowPlayingEvent(TRACK_ORDER_SHUFFLED))
        }

    val isShuffleCustomized get() = shuffleLean != 0.0 ||
            shuffleMinimumPlayCount > 0 ||
            shuffleMaximumPlayCount < MAXIMUM_ALLOWED_SHUFFLE_PLAY_COUNT_LIMIT

    var shuffleLean: Double
        get() = Settings.getDouble(SettingType.SHUFFLE_LEAN, 0.0)
        set(value) = Settings.setDouble(SettingType.SHUFFLE_LEAN, value)

    const val MAXIMUM_ALLOWED_SHUFFLE_PLAY_COUNT_LIMIT = Int.MAX_VALUE

    var shuffleMinimumPlayCount: Int
        get() = Settings.getInt(SettingType.SHUFFLE_MINIMUM_PLAY_COUNT, 0)
        set(value) = lock.use {
            if (shuffleMaximumPlayCount < value) {
                shuffleMaximumPlayCount = value
            }
            Settings.setInt(SettingType.SHUFFLE_MINIMUM_PLAY_COUNT, value)
        }

    var shuffleMaximumPlayCount: Int
        get() = Settings.getInt(SettingType.SHUFFLE_MAXIMUM_PLAY_COUNT, MAXIMUM_ALLOWED_SHUFFLE_PLAY_COUNT_LIMIT)
        set(value) = lock.use {
            if (shuffleMinimumPlayCount > value) {
                shuffleMinimumPlayCount = value
            }
            Settings.setInt(SettingType.SHUFFLE_MAXIMUM_PLAY_COUNT, value)
        }

    var isRepeating: Boolean
        get() = Settings.getBoolean(SettingType.REPEAT, false)
        set(value) = lock.use {
            Settings.setBoolean(SettingType.REPEAT, value)
        }

    private var lastPlayedTimestamp = 0L

    fun getLastPlayedTimestamp(): Long {
        return if (isPlaying) {
            now().toEpochMilliseconds()
        } else {
            lastPlayedTimestamp
        }
    }

    var isPlaying = false
        private set(value) = lock.use {
            // If we either are starting playback, or just stopped it, update the last timestamp.
            // This is used to know which device played music most recently when we try to resume playback.
            if (field || value) {
                lastPlayedTimestamp = now().toEpochMilliseconds()
            }
            field = value
        }

    var volume
        get() = Settings.getDouble(SettingType.VOLUME, 1.0)
        set(value) {
            Settings.setDouble(SettingType.VOLUME, value)
        }

    var muted
        get() = Settings.getBoolean(SettingType.MUTED, false)
        set(value) {
            Settings.setBoolean(SettingType.MUTED, value)
        }

    var restartOnPlayPrevious: Boolean
        get() = Settings.getBoolean(SettingType.RESTART_TRACK_ON_PLAY_PREVIOUS, false)
        set(value) = Settings.setBoolean(SettingType.RESTART_TRACK_ON_PLAY_PREVIOUS, value)

    var useTimeSkipControls: Boolean
        get() = Settings.getBoolean(SettingType.USE_TIME_SKIP_CONTROLS, true)
        set(value) = Settings.setBoolean(SettingType.USE_TIME_SKIP_CONTROLS, value)

    var useTimeSkipDurationCutoff: Int
        get() = Settings.getInt(SettingType.SKIP_FORWARD_TRACK_DURATION, 30)
        set(value) = Settings.setInt(SettingType.SKIP_FORWARD_TRACK_DURATION, value)

    var timeSkipForwardAmount: Int
        get() = Settings.getInt(SettingType.SKIP_FORWARD_AMOUNT, 15)
        set(value) = Settings.setInt(SettingType.SKIP_FORWARD_AMOUNT, value)

    var timeSkipBackwardAmount: Int
        get() = Settings.getInt(SettingType.SKIP_BACKWARD_AMOUNT, 15)
        set(value) = Settings.setInt(SettingType.SKIP_BACKWARD_AMOUNT, value)

    internal var lastTimeAdjustment: Instant = now()

    var currentTime: Double = 0.0
        set(value) = lock.use {
            field = value
            lastTimeAdjustment = now()
            MarkListenedService.updateCurrentTrackPlayPosition(value)
        }

    val currentStandardTracks = mutableListOf<NowPlayingTrack>()
    val currentShuffledTracks = mutableListOf<NowPlayingTrack>()

    val currentNowPlayingTracks get() = (if (_isShuffling) currentShuffledTracks else currentStandardTracks)

    val tracks: List<Track> get() = currentNowPlayingTracks.map { it.track }

    // This is the correct shuffle index if shuffling and standard index if not
    var currentIndex = -1
        private set

    private val handlers = mutableMapOf<Int, NowPlayingEventHandler>()

    init {
        MarkListenedService.registerTrackChangeListener()

        if (!GGCommonInternal.isIntegrationTesting) {
            NowListeningService.registerForNowPlayingChanges()
        }

        TrackService.registerEventHandler(::nowPlayingTrackChangeHandler)

        registerEventHandler { event ->
            if (event.type == CURRENT_TRACK_CHANGED || event.type == ALL_TRACKS_CHANGED || event.type == PLAYBACK_STARTED) {
                val currentTrack = currentTrack ?: return@registerEventHandler
                currentTrack.startedOnDevice = now()

                // I made this function that only updates one property, because these track references could
                // be very stale by the time we run this function. I don't want to bother refreshing the
                // entire track entity when it really doesn't matter just to update one property.
                TrackService.updateTrackLastStarted(currentTrack)
            }
        }
    }

    internal fun setCurrentIndex(index: Int) {
        if (index < 0) {
            throw IllegalArgumentException("Tried to set 'currentIndex' to a value less than 0! Value: $index")
        } else if (index >= currentNowPlayingTracks.size) {
            throw IllegalArgumentException("Tried to set 'currentIndex' to a value greater than the track list! Value: $index. Track list size: ${currentNowPlayingTracks.size}")
        }

        currentIndex = index
    }

    fun playFromIndex(index: Int) = lock.use {
        setCurrentIndex(index)

        currentIndex = index
        currentTime = 0.0
        isPlaying = true
        emitEvent(NowPlayingEvent(CURRENT_TRACK_CHANGED, listOf(currentNowPlayingTrack!!)))
        emitEvent(NowPlayingEvent(PLAYBACK_STARTED))
    }

    fun playFromNowPlayingId(id: NowPlayingTrackId) {
        val index = currentNowPlayingTracks.indexOfFirst { it.nowPlayingTrackId == id }.takeIf { it >= 0 } ?: run {
            logError("No track found for NowPlayingTrackId: ${id.value}")
            return
        }

        playFromIndex(index)
    }

    fun pause() {
        isPlaying = false

        emitEvent(NowPlayingEvent(PLAYBACK_PAUSED))

        PlaySessionService.saveLocalPlaybackState()
    }

    fun resume() {
        if (currentTrack != null) {
            isPlaying = true
            emitEvent(NowPlayingEvent(PLAYBACK_RESUMED))
        }
    }

    private val currentStandardIndex get() = if (_isShuffling) {
        val currentNowPlayingId = currentNowPlayingTrack?.nowPlayingTrackId

        if (currentNowPlayingId == null) {
            -1
        } else {
            currentStandardTracks
                .indexOfFirst { it.nowPlayingTrackId == currentNowPlayingId }
                .also { if (it == -1) logCrit("Could not find standard shuffle index!") }
        }
    } else {
        currentIndex
    }

    val currentNowPlayingTrack get() = currentNowPlayingTracks.getOrNull(currentIndex)
    val currentTrack: Track? get() = currentNowPlayingTrack?.track

    /**
     * When called with shuffle enabled, this will set the current playing index as 0, with the
     * track in the list at that index being first. This is because shuffle has no order anyway.
     *
     * When called with shuffle turned off, the tracks earlier in the list than the one being
     * played, stay earlier in the list and can be played with playPrevious()
     */
    fun setNowPlayingTracks(tracks: List<Track>, playFromIndex: Int?) = lock.use {
        currentStandardTracks.clear()
        currentShuffledTracks.clear()
        currentTime = 0.0

        if (tracks.isEmpty()) {
            currentIndex = -1
            return@use
        }

        val nowPlayingTracks = tracks.toNowPlayingTracks()
        currentStandardTracks.addAll(nowPlayingTracks)

        if (_isShuffling) {
            val firstTrack = if (playFromIndex == null) null else nowPlayingTracks[playFromIndex]
            val shuffledTracks = TrackShuffler.shuffleTracks(firstTrack, nowPlayingTracks)

            currentShuffledTracks.addAll(shuffledTracks)
        }

        if (playFromIndex != null) {
            // When shuffling, we always begin from index 0, as shuffling doesn't have an inherent order
            currentIndex = if (_isShuffling) 0 else playFromIndex
            isPlaying = true
        } else {
            currentIndex = 0
        }

        reassignOrders()

        emitEvent(NowPlayingEvent(ALL_TRACKS_CHANGED))

        if (playFromIndex != null) {
            emitEvent(NowPlayingEvent(PLAYBACK_STARTED))
        }
    }

    fun getSortedNowPlayingTracks(
        genericFilter: String? = null,
        sort: List<TrackSortItem> = listOf(TrackSortItem(TrackColumn.SORT_IDENTIFIER, SortDirection.ASC)),
    ): List<NowPlayingTrack> {
        val filter = genericFilter?.lowercase()

        val filteredTracks = currentNowPlayingTracks.filter { track ->
            filter == null ||
                    track.track.name.lowercase().contains(filter) ||
                    track.track.artist.lowercase().contains(filter) ||
                    track.track.album.lowercase().contains(filter) ||
                    track.track.genre.lowercase().contains(filter) ||
                    track.track.note.lowercase().contains(filter)
        }
        return TrackSort.sortTracks(filteredTracks, sort)
    }

    private fun enableShuffle() {
        currentShuffledTracks.addAll(TrackShuffler.shuffleTracks(currentNowPlayingTrack, currentStandardTracks))
        currentIndex = if (currentStandardTracks.isEmpty()) -1 else 0
    }

    private fun disableShuffle() {
        currentIndex = currentStandardIndex
        currentShuffledTracks.clear() // Free the pointers. Free the tiny quantity of memory
    }

    fun reshuffle() {
        if (_isShuffling) {
            val newOrder = TrackShuffler.shuffleTracks(currentNowPlayingTrack, currentStandardTracks)
            currentShuffledTracks.clear()
            currentShuffledTracks.addAll(newOrder)
            currentIndex = if (currentStandardTracks.isEmpty()) -1 else 0

            reassignOrders()

            // Always emit an event here, even if we only have a single track (or fewer).
            // Tracks can be removed because of the min / max shuffle setting and clients need to be notified.
            emitEvent(NowPlayingEvent(TRACK_ORDER_SHUFFLED))
        }
    }

    fun addTracksNext(tracks: List<Track>) = lock.use {
        val indexToAdd = if (currentNowPlayingTracks.isEmpty()) 0 else currentIndex + 1
        val nowPlayingTracks = tracks.toNowPlayingTracks()

        val shuffledTracks = if (_isShuffling) TrackShuffler.shuffleTracks(null, nowPlayingTracks) else nowPlayingTracks
        currentNowPlayingTracks.addAll(indexToAdd, shuffledTracks)

        // We want "add next" to also add tracks next if you were to turn shuffle off. A big reason for this, is the
        // fact that someone may have shuffle enabled and not realize it. Then add tracks, and turn shuffle off, and
        // we don't want to disregard their wishes to play the track next just because shuffle was toggled off.
        if (_isShuffling) {
            val standardIndexToAdd = if (currentStandardTracks.isEmpty()) 0 else currentStandardIndex + 1
            currentStandardTracks.addAll(standardIndexToAdd, nowPlayingTracks)
        }

        reassignOrders()

        emitEvent(NowPlayingEvent(TRACKS_ADDED_NEXT, nowPlayingTracks))
    }

    fun addTracksLast(tracks: List<Track>) = lock.use {
        val nowPlayingTracks = tracks.toNowPlayingTracks()
        val shuffledTracks = if (_isShuffling) TrackShuffler.shuffleTracks(null, nowPlayingTracks) else nowPlayingTracks
        currentNowPlayingTracks.addAll(currentNowPlayingTracks.size, shuffledTracks)

        // See comment on "addTracksNext"
        if (_isShuffling) {
            currentStandardTracks.addAll(currentStandardTracks.size, nowPlayingTracks)
        }

        reassignOrders()

        emitEvent(NowPlayingEvent(TRACKS_ADDED_LAST, nowPlayingTracks))
    }

    fun removeTracksByIndex(indexes: Collection<Int>) = lock.use {
        removeTracksByIndexInternal(indexes)
    }

    // To avoid issues with lock.use {} chaining, have the internal function not use a lock and just the public functions use locks instead.
    private fun removeTracksByIndexInternal(indexes: Collection<Int>) {
        logDebug("About to remove tracks. Current played index is: $currentIndex")

        val startingCurrentTrackId = currentNowPlayingTrack?.nowPlayingTrackId

        var previousTracksRemoved = 0
        val removedTracks = mutableListOf<NowPlayingTrack>()
        indexes.sortedDescending().forEach { index ->
            if (index < currentIndex) {
                previousTracksRemoved++
            }
            removedTracks.add(currentNowPlayingTracks.removeAt(index))
        }
        val removedTrackIds = removedTracks.map { it.nowPlayingTrackId }.toSet()

        if (_isShuffling) {
            currentStandardTracks.removeAll { removedTrackIds.contains(it.nowPlayingTrackId) }
        }

        val currentTrack = currentNowPlayingTrack
        if (currentTrack == null) {
            currentIndex = -1
            isPlaying = false
            emitEvent(NowPlayingEvent(PLAYBACK_STOPPED))
        } else {
            currentIndex -= previousTracksRemoved
            if (startingCurrentTrackId != currentNowPlayingTrack?.nowPlayingTrackId) {
                emitEvent(NowPlayingEvent(CURRENT_TRACK_CHANGED, listOf(currentTrack)))
            }
        }

        if (removedTracks.isNotEmpty()) {
            reassignOrders()

            logDebug("Removed track indexes: $removedTracks")
            emitEvent(TrackRemovedEvent(removedTracks, indexes.toList()))
        }
    }

    fun removeTracks(tracks: Collection<NowPlayingTrack>) = lock.use {
        val idsToRemove = tracks.map { it.nowPlayingTrackId }.toSet()

        val indexesToRemove = currentNowPlayingTracks.mapIndexedNotNull { index, nowPlayingTrack ->
            if (idsToRemove.contains(nowPlayingTrack.nowPlayingTrackId)) {
                index
            } else {
                null
            }
        }

        removeTracksByIndexInternal(indexesToRemove)
    }

    fun removeAllAfterIndex(index: Int) {
        val endIndex = tracks.size - 1
        if (endIndex < index) {
            throw IllegalArgumentException("EndIndex '$endIndex' is less than target index of removal '$index'!")
        } else if (endIndex == index) {
            // Do nothing. We were told to remove everything after the current track
        } else {
            val indexes = (index + 1..endIndex).toList()
            removeTracksByIndex(indexes)
        }
    }

    fun stop() {
        currentStandardTracks.clear()
        currentShuffledTracks.clear()
        currentIndex = -1

        emitEvent(NowPlayingEvent(PLAYBACK_STOPPED))
    }

    internal fun removeUncachedTracks() {
        val indexesToRemove = tracks.mapIndexedNotNull { index, track ->
            // This should be pretty safe to do, as we have registered an event handler that should
            // keep our track references up to date as their cache changes. So we should not need
            // to refresh the track data before we filter on our references.
            if (track.audioCachedAt != null) null else index
        }

        removeTracksByIndex(indexesToRemove)
    }

    fun playNext() = lock.use {
        if (currentNowPlayingTracks.isEmpty()) {
            return@use
        }

        if (currentIndex < currentNowPlayingTracks.size - 1) {
            currentIndex++
            isPlaying = true
            emitEvent(NowPlayingEvent(CURRENT_TRACK_CHANGED, listOf(currentNowPlayingTrack!!)))
        } else if (isRepeating && currentIndex == currentNowPlayingTracks.size - 1) {
            currentIndex = 0
            isPlaying = true
            emitEvent(NowPlayingEvent(CURRENT_TRACK_CHANGED, listOf(currentNowPlayingTrack!!)))
        } else {
            currentIndex = -1
            isPlaying = false
            emitEvent(NowPlayingEvent(PLAYBACK_STOPPED))
        }

        currentTime = 0.0
    }

    fun playPrevious() = lock.use {
        if (currentNowPlayingTracks.isEmpty()) {
            return@use
        }

        if (Settings.getBoolean(SettingType.RESTART_TRACK_ON_PLAY_PREVIOUS, false) && currentTime >= 3) {
            // This essentially restarts playback as we did not change the index
            isPlaying = true
            emitEvent(NowPlayingEvent(CURRENT_TRACK_CHANGED, listOf(currentNowPlayingTrack!!)))
        } else if (currentIndex > 0) {
            currentIndex--
            isPlaying = true
            emitEvent(NowPlayingEvent(CURRENT_TRACK_CHANGED, listOf(currentNowPlayingTrack!!)))
        } else if (currentIndex == -1) {
            currentIndex = currentNowPlayingTracks.size - 1
            isPlaying = true
            emitEvent(NowPlayingEvent(CURRENT_TRACK_CHANGED, listOf(currentNowPlayingTrack!!)))
        } else if (isRepeating && currentIndex == 0) {
            currentIndex = currentNowPlayingTracks.size - 1
            isPlaying = true
            emitEvent(NowPlayingEvent(CURRENT_TRACK_CHANGED, listOf(currentNowPlayingTrack!!)))
        } else {
            currentIndex = -1
            isPlaying = false
            emitEvent(NowPlayingEvent(PLAYBACK_STOPPED))
        }

        currentTime = 0.0
    }

    fun moveTrackById(id: NowPlayingTrackId, newIndex: Int) {
        val trackIndex = currentNowPlayingTracks.findIndex { it.nowPlayingTrackId == id } ?: run {
            logCrit("No NowPlayingTrack found with ID: ${id.value}!")
            return
        }

        moveTrack(trackIndex, newIndex)
    }

    // I feel like the range check makes this less easy to read in this instance. Idk maybe it's just me.
    @Suppress("ConvertTwoComparisonsToRangeCheck")
    fun moveTrack(trackIndex: Int, newIndex: Int) = lock.use {
        logInfo("Moving NowPlayingTrack index from $trackIndex to $newIndex")

        val newCurrentIndex = if (trackIndex == this.currentIndex) {
            // If we moved the currently played track, then, obviously, the new index
            // is the one that we just moved it to. You're a smart guy. You didn't need this comment.
            newIndex
        } else if (trackIndex < this.currentIndex && newIndex >= this.currentIndex) {
            // If we moved a track from BEFORE the current index to AFTER it, then we need to bump
            // the index down to make up for this.
            this.currentIndex - 1
        } else if (trackIndex > this.currentIndex && newIndex <= this.currentIndex) {
            // And do the opposite if the opposite happened
            this.currentIndex + 1
        } else {
            // Otherwise, the reorder did not affect the current track's index.
            this.currentIndex
        }

        val track = currentNowPlayingTracks.removeAt(trackIndex)
        currentNowPlayingTracks.add(newIndex, track)

        this.currentIndex = newCurrentIndex

        emitEvent(TrackMovedEvent(track, fromIndex = trackIndex, toIndex = newIndex))
    }

    internal fun setFromPlaySession(state: NowListeningState, tracks: List<Track>, startPlayback: Boolean) = lock.use {
        currentNowPlayingTracks.clear()
        currentShuffledTracks.clear()

        // TODO There's not really any reason the Local version couldn't store these, too.
        //  But it's currently saved separately as it was added to the codebase years ago.
        //  Could and probably should be tweaked eventually but will require migration.
        if (state is RemoteNowListeningState) {
            _isShuffling = state.isShuffling
            isRepeating = state.isRepeating
        }

        val nowPlayingTracks = tracks.toNowPlayingTracks()
        currentStandardTracks.addAll(nowPlayingTracks)

        // If we are shuffling, we need to add it to both the shuffled and non-shuffled collections.
        // This means that if someone un-shuffles, then nothing changes. But it is what it is.
        // If they re-shuffle then it will become a new order again.
        if (_isShuffling) {
            currentShuffledTracks.addAll(nowPlayingTracks)
        }

        reassignOrders()

        currentIndex = state.trackIndex

        currentTime = state.currentTime

        emitEvent(PlaySessionCopiedEvent(nowPlayingTracks, currentTime, startPlayback))
    }

    private fun nowPlayingTrackChangeHandler(event: TrackChangeEvent) {
        when (event.changeType) {
            ChangeType.DELETED -> {
                val indexesToRemove = tracks.indices.filter { index ->
                    val track = tracks[index]
                    event.tracks.forEach { removedTrack ->
                        if (track.id == removedTrack.id) {
                            return@filter true
                        }
                    }
                    return@filter false
                }
                removeTracksByIndex(indexesToRemove)
            }

            ChangeType.UPDATED -> {
                logDebug("Got an update event")
                event.tracks.forEach { updatedTrack ->
                    val track = tracks.find { it.id == updatedTrack.id }
                    // Keep the reference the same since it makes our lives easier. Just update the property.
                    // Now anybody who also got a reference to this same track will have the correct data also.
                    logDebug("Updating track reference for: ${updatedTrack.id.value}")
                    track?.updateFrom(updatedTrack)
                }
            }
            ChangeType.ADDED -> { /* We don't care as a new track won't be in NowPlaying */ }
        }
    }

    fun registerEventHandler(handler: NowPlayingEventHandler): Int {
        // For some ungodly reason, I used to have a "handlerId: Int = 0" that I just incremented, but it
        // was getting messed up by some KotlinJS bug where it would lose its value for no reason. I have
        // pivoted to instead doing this maxOrNull thing and it seems like it's just as good. But this is
        // why it is the way that it is. Maybe when I can update the Kotlin version I can do the other way.
        return lock.use {
            val id = (handlers.keys.maxOrNull() ?: 0) + 1
            handlers[id] = handler
            id
        }
    }
    fun unregisterEventHandler(handlerId: Int) {
        handlers.remove(handlerId)
    }

    private fun emitEvent(event: NowPlayingEvent) {
        if (handlers.isEmpty()) {
            logWarn("No NowPlayingEventHandlers registered!")
        }

        // If we are integration testing, then don't put this inside of a coroutine.
        // The async nature makes things difficult to test, but we need the callbacks to happen
        // inside a coroutine otherwise as anything in the callback that also invokes
        // more NowPlayingService stuff can hit a lock and create a deadlock
        if (GGCommonInternal.isIntegrationTesting) {
            handlers.values.forEach { it(event) }
        } else {
            // Drop into a coroutine so that we are no longer inside any locks while evaluating the callbacks.
            // Otherwise, if a callback is doing something that ALSO hits a lock, we will deadlock.
            CoroutineScope(Dispatchers.Main).launch {
                handlers.values.forEach { it(event) }
            }
        }
    }

    private fun List<Track>.toNowPlayingTracks() = this.map {
        NowPlayingTrack(it, NowPlayingTrackId(nextNowPlayingTrackId++))
    }

    // This could be optimized by providing a starting index and only checking for things later on.
    // But what this is doing is very fast already, so it's probably inconsequential.
    private fun reassignOrders() {
        currentNowPlayingTracks.mapIndexed { index, nowPlayingTrack ->
            nowPlayingTrack.order = index
        }
    }

    // These pass-throughs exists just to keep the other classes internal so our public API is simpler to use.
    @Suppress("unused")
    fun setLocationGatheringHandler(locationGatheringHandler: LocationGatheringHandler?) {
        MarkListenedService.locationGatheringHandler = locationGatheringHandler
    }
    @Suppress("unused")
    fun setBatteryPercentageHandler(batteryPercentageHandler: BatteryPercentageHandler?) {
        MarkListenedService.batteryPercentHandler = batteryPercentageHandler
    }
    @Suppress("unused")
    fun getShuffleDescription(lean: Double, playCountMinimum: Int, playCountMaximum: Int): String {
        return TrackShuffler.generateShuffleDescription(lean, playCountMinimum, playCountMaximum)
    }
}

open class NowPlayingEvent(val type: NowPlayingEventType, val tracks: List<NowPlayingTrack> = emptyList())

class TrackRemovedEvent(
    tracks: List<NowPlayingTrack>,
    val removedIndexes: List<Int>,
) : NowPlayingEvent(TRACKS_REMOVED, tracks)

class TrackMovedEvent(
    track: NowPlayingTrack,
    val fromIndex: Int,
    val toIndex: Int,
) : NowPlayingEvent(TRACK_MOVED, listOf(track))

class PlaySessionCopiedEvent(
    tracks: List<NowPlayingTrack>,
    val currentTime: Double,
    val startPlayback: Boolean,
) : NowPlayingEvent(PLAY_SESSION_COPIED, tracks)

enum class NowPlayingEventType {
    TRACKS_ADDED_NEXT,
    TRACKS_ADDED_LAST,
    TRACKS_REMOVED,
    TRACK_MOVED,
    TRACK_ORDER_SHUFFLED,
    ALL_TRACKS_CHANGED,
    CURRENT_TRACK_CHANGED,
    PLAYBACK_STOPPED,
    PLAYBACK_RESUMED,
    PLAYBACK_STARTED,
    PLAYBACK_PAUSED,
    PLAY_SESSION_COPIED,
}


// We need a unique way to identify a Track, and since the same Track can be in the NowPlaying multiple times, we need a temporary ID.
@JvmInline
value class NowPlayingTrackId(val value: Int)

class NowPlayingTrack(
    val track: Track,
    val nowPlayingTrackId: NowPlayingTrackId,
) : TrackSortable {

    var order: Int = 0
        internal set

    override fun toString(): String {
        return "NowPlayingTrack(trackId=${track.id.value}, nowPlayingTrackId=${nowPlayingTrackId.value}, order=$order)"
    }
}
