package net.gorillagroove.track

import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import net.gorillagroove.hardware.PlatformDeviceUtil
import net.gorillagroove.playlist.LoadedPlaylistTrack
import net.gorillagroove.util.Formatter
import net.gorillagroove.util.GGLog.logCrit
import net.gorillagroove.util.SettingType
import net.gorillagroove.util.Settings
import kotlin.reflect.KMutableProperty1

object TrackSort {
    var lastSort: List<TrackSortItem>
        get() {
            val value = Settings.getString(SettingType.LAST_SORT, "")
            return LastSortWrapper.fromJson(value).sorts.toSortItems()
        }
        set(value) {
            val pairFormat = value.map { it.column to it.direction }
            Settings.setString(SettingType.LAST_SORT, LastSortWrapper(pairFormat).toJson())
        }

    // This doesn't handle the case where additional columns are added (or removed) since this data
    // was persisted. So if we ever make another change to what columns there are options for, this
    // will need to be updated to remove or append those columns to the data on retrieval.
    var columnOptions: List<Pair<TrackColumn, TrackColumnUserPreferences>>
        get() {
            val value = Settings.getString(SettingType.TRACK_COLUMN_OPTIONS, "")
            return TrackColumnOptions.fromJson(value).order
        }
        set(value) = Settings.setString(SettingType.TRACK_COLUMN_OPTIONS, TrackColumnOptions(value).toJson())

    fun resetColumnOptions() {
        Settings.setString(SettingType.TRACK_COLUMN_OPTIONS, "")
    }

    internal fun<T: TrackSortable> sortTracks(
        tracks: List<T>,
        sorts: List<TrackSortItem>
    ): List<T> {
        // We do not actually sort in SQL for a few reasons (which may or may not be actually good reasons):
        // 1) SqlDelight has poor support for dynamic ORDER BY clauses, so they are annoying to write.
        //    You need to use a CASE statement and map some string or int value to every column that you
        //    support sorting on. This is annoying given that we can sort on like 13 different fields, but doable.
        //    However, it gets even more annoying when you realize that we need to support sorting on multiple
        //    fields at once in some cases (sorting by 'artist' needs a secondary 'album' and 'trackNum' sort).
        //    The frontend in particular supports sorting an arbitrary number of columns, which we'd have to limit.
        // 2) In order for the SQL to actually outperform code sorting, it seems like it should need an index to
        //    sort on, or else it's going to do no better. Is it worth making ~13 indexes? We could make indexes
        //    just for the things that are commonly used, but it's a consideration to keep in mind.
        // 3) Not all tracks enter the system from the database. When viewing tracks from another user, we
        //    fetch them in real time from the API. If we just sort the records in-memory, then our sorting
        //    logic can be the same whether we are pulling stuff out of the DB or fetching stuff from the API.
        // 4) We need to sort NowPlayingTracks and PlaylistTracks in addition to the regular Tracks on our own
        //    library. Tracks and PlaylistTracks could be database sorted, but NowPlayingTracks are not persisted
        //    into the database at this time, and thus could not be database sorted even if desired.
        // TL;DR: Sorting in code is convenient until we have performance issues that need an index for sorts.
        return tracks.sortedWith(
            sorts.augmentSort().map { (sortType, sortDirection) ->
                if (sortDirection == SortDirection.ASC) sortType.trackSortComparator else sortType.trackSortComparator.reversed()
            }.reduce { order, nextComparator -> order.then(nextComparator) }
        )
    }
}

// Suppressing this as all of these properties are exposed publicly.
// I'm just too lazy to write sort tests for every single one....
@Suppress("unused")
enum class TrackColumn(
    val displayName: String,
    val metadataProperty: MetadataProperty? = null,
    internal val trackPropertyExtractor: ((TrackSortable) -> Any?)? = null,
    internal val caseInsensitive: Boolean = false,

    internal val propertyDisplayFunction: (Any?) -> String = { it?.toString() ?: "" },
    val defaultSortDirection: SortDirection = SortDirection.ASC,

    // These are specifically for the frontend, as mobile has no current analog to this type of tabular display.
    internal val defaultOrder: Int,
    val defaultWidth: Int = 100,
) {
    CLIENT_CUSTOM("", trackPropertyExtractor = { "" }, defaultOrder = 0, defaultWidth = 30),

    SORT_IDENTIFIER("#", trackPropertyExtractor = { it.uniqueSortIdentifier }, propertyDisplayFunction = { ((it as Int) + 1).toString() }, defaultWidth = 50, defaultOrder = 1),

    NAME("Name", MetadataProperty.NAME, caseInsensitive = true, defaultWidth = 250, defaultOrder = 2),
    ARTIST("Artist", MetadataProperty.ARTIST, caseInsensitive = true, defaultWidth = 250, defaultOrder = 3),
    FEATURING("Featuring", MetadataProperty.FEATURING, caseInsensitive = true, defaultWidth = 150, defaultOrder = 4),
    ALBUM("Album", MetadataProperty.ALBUM, caseInsensitive = true, defaultWidth = 200, defaultOrder = 5),
    GENRE("Genre", MetadataProperty.GENRE, caseInsensitive = true, defaultWidth = 150, defaultOrder = 9),
    NOTE("Note", MetadataProperty.NOTE, caseInsensitive = true, defaultWidth = 150, defaultOrder = 13),

    PLAY_COUNT("Plays", trackPropertyExtractor = { it.asTrack().playCount }, defaultSortDirection = SortDirection.DESC, defaultWidth = 80, defaultOrder = 10),
    LENGTH("Length", trackPropertyExtractor = { it.asTrack().length }, defaultOrder = 7, defaultWidth = 90, propertyDisplayFunction = { time ->
        Formatter.getDurationDisplayFromSeconds(time as Int)
    }),
    TRACK_NUMBER("Track #", MetadataProperty.TRACK_NUMBER, defaultWidth = 90, defaultOrder = 6),
    YEAR("Year", MetadataProperty.RELEASE_YEAR, defaultWidth = 80, defaultOrder = 8),

    DATE_ADDED("Date Added", trackPropertyExtractor = { it.asTrack().addedToLibrary }, defaultSortDirection = SortDirection.DESC, propertyDisplayFunction = ::displayDate, defaultWidth = 130, defaultOrder = 11),
    LAST_PLAYED("Last Played", trackPropertyExtractor = { it.asTrack().lastPlayed }, defaultSortDirection = SortDirection.DESC, propertyDisplayFunction = ::displayDate, defaultWidth = 130, defaultOrder = 12),
    ;

    val updatable get() = metadataProperty?.kProperty is KMutableProperty1<*, *>

    internal val trackSortComparator: Comparator<TrackSortable> = if (caseInsensitive) {
        compareBy(String.CASE_INSENSITIVE_ORDER, selector = { getExtractedValue(it)?.toString()  ?: "" })
    } else {
        compareBy(selector = { getExtractedValue(it) as Comparable<*>? })
    }

    private fun getExtractedValue(track: TrackSortable): Any? {
        if (metadataProperty == null && trackPropertyExtractor == null) {
            throw IllegalStateException("Track property must have a defined metadataProperty or trackPropertyExtractor! Column: ${this.name}")
        }

        return metadataProperty?.kProperty?.get(track.asTrack())
            ?: trackPropertyExtractor?.invoke(track)
    }

    fun getFormattedProperty(trackSortable: TrackSortable): String {
        return propertyDisplayFunction(getExtractedValue(trackSortable))
    }

    fun setTrackProperty(track: TrackSortable, value: String) {
        if (!updatable) {
            throw IllegalStateException("Cannot update track property for non-updatable column type ${this.name}!")
        }

        val kProperty = this.metadataProperty!!.kProperty as KMutableProperty1<Track, Any?>

        val convertedValue = MetadataService.coercePropertyStringToApiFormat(this.metadataProperty, value)

        kProperty.set(track.asTrack(), convertedValue)
    }
}

private fun displayDate(dateProperty: Any?): String {
    return if (dateProperty == null) {
        ""
    } else {
        Formatter.formatToUserDateFormat(dateProperty as Instant)
    }
}

interface TrackSortable {
    val uniqueSortIdentifier: Int get() {
        return when (this) {
            is Track -> id.value.toInt()
            is NowPlayingTrack -> order
            is LoadedPlaylistTrack -> order
            else -> throw IllegalArgumentException("Unexpected track type when sorting by ID! ${this::class}")
        }
    }

    val uniqueIdentifier: Int get() {
        return when (this) {
            is Track -> id.value.toInt()
            is NowPlayingTrack -> nowPlayingTrackId.value
            is LoadedPlaylistTrack -> playlistTrack.id.value.toInt()
            else -> throw IllegalArgumentException("Unexpected track type when getting unique identifier! ${this::class}")
        }
    }

    fun asTrack(): Track {
        return when (this) {
            is Track -> this
            is NowPlayingTrack -> this.track
            is LoadedPlaylistTrack -> this.track
            else -> throw IllegalArgumentException("Class ${this::class} is not TrackSortable!")
        }
    }
}

enum class SortDirection {
    ASC, DESC;

    fun reversed(): SortDirection {
        return if (this == ASC) DESC else ASC
    }
}

@Serializable
private class LastSortWrapper(val sorts: List<Pair<TrackColumn, SortDirection>>) {
    fun toJson(): String = Json.encodeToString(this)

    companion object {
        private fun getDefault(): LastSortWrapper {
            return if (PlatformDeviceUtil.getDeviceType().isMobile) {
                LastSortWrapper(listOf(TrackColumn.NAME to SortDirection.ASC))
            } else {
                LastSortWrapper(listOf(TrackColumn.ARTIST to SortDirection.ASC))
            }
        }

        fun fromJson(json: String): LastSortWrapper {
            if (json.isBlank()) {
                return getDefault()
            }

            return try {
                Json.decodeFromString<LastSortWrapper>(json)
            } catch (e: Exception) {
                logCrit("Failed to deserialize LastSort JSON! $json", e)
                getDefault()
            }
        }
    }
}

@Serializable
private class TrackColumnOptions(
    val order: List<Pair<TrackColumn, TrackColumnUserPreferences>> = getDefault(),
) {
    fun toJson(): String = Json.encodeToString(this)

    companion object {
        private fun getDefault(): List<Pair<TrackColumn, TrackColumnUserPreferences>> {
            return TrackColumn.entries
                .sortedBy { it.defaultOrder }
                .map { column -> column to TrackColumnUserPreferences(enabled = true, width = column.defaultWidth) }
        }

        fun fromJson(json: String): TrackColumnOptions {
            if (json.isBlank()) {
                return TrackColumnOptions()
            }

            return try {
                Json.decodeFromString<TrackColumnOptions>(json)
            } catch (e: Exception) {
                logCrit("Failed to deserialize TrackColumnOptions JSON! $json", e)
                TrackColumnOptions()
            }
        }
    }
}

// There are a couple of sorts that require additional sorts
// be added to them implicitly so that they make more sense.
fun List<TrackSortItem>.augmentSort(): MutableList<TrackSortItem> {
    val sortToUse = this.toMutableList()
    if (this.size == 1) {
        val sortType = this.first().column
        if (sortType == TrackColumn.ARTIST) {
            sortToUse.add(TrackSortItem(TrackColumn.ALBUM, SortDirection.ASC))
            sortToUse.add(TrackSortItem(TrackColumn.TRACK_NUMBER, SortDirection.ASC))
        } else if (sortType == TrackColumn.ALBUM) {
            sortToUse.add(TrackSortItem(TrackColumn.TRACK_NUMBER, SortDirection.ASC))
        }
    }

    return sortToUse
}

@Serializable
data class TrackColumnUserPreferences(var enabled: Boolean = true, var width: Int)

// Swift gives a strange error when trying to pass enums into KotlinPair:
// "Generic class 'KotlinPair' requires that 'SortDirection' be a class type"
// Not sure how I'm supposed to get around this as I want to pass enums. So I guess I will make my own, non-generic version?
class TrackSortItem(val column: TrackColumn, val direction: SortDirection) {
    operator fun component1() = column
    operator fun component2() = direction
}

// This is to help bridge the gap with everything that was using the Pair version of the API before I altered it for Swift.
fun List<Pair<TrackColumn, SortDirection>>.toSortItems(): List<TrackSortItem> {
    return this.map { TrackSortItem(it.first, it.second) }
}
