import components.OnDemandTrackCacheListener
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.MainScope
import net.gorillagroove.GGCommon
import net.gorillagroove.api.Api
import net.gorillagroove.authentication.AuthService
import net.gorillagroove.sync.SyncCoordinator
import net.gorillagroove.track.NowListeningService
import net.gorillagroove.track.OfflineModeService
import net.gorillagroove.track.PlaySessionService
import net.gorillagroove.util.GGLog
import net.gorillagroove.util.GGLog.logInfo
import util.ByteUtil
import kotlin.time.Duration.Companion.hours
import kotlin.time.Duration.Companion.minutes

suspend fun main() {
    GGCommon.initialize("5.5.0")

    if (window.location.host.contains("localhost")) {
        Api.overrideHost(window.location.host, useHttps = window.location.protocol.contains("https"))
    }

    AppColor.assignColors()

    Toast.init()
    Dialog.init()
    DialogNonBlocking.init()

    setupTabLifecycle()
    setupOtherRandomNonsense()

    if (AuthService.isAuthenticated()) {
        AuthUtil.setupAuthenticatedUser()
    }

    document.getElementById("pre-kt-loading-screen")?.remove()

    OfflineModeService.checkWiFiConnection = { true }

    PageRouter.navigateTo(window.location.pathname)

    OnDemandTrackCacheListener.unregister()

    ByteUtil.init()

    NowListeningService.staleDataPruningTimer.start()

    if (AuthService.isAuthenticated()) {
        PlaySessionService.handlePlaySessionResumeBroadcast()
    }
}

private fun setupTabLifecycle() {
    document.addEventListener("visibilitychange") {
        val visible = (js("""document.visibilityState""") as String) == "visible"
        if (visible) {
            GGLog.logInfo("GorillaGroove is now visible")
            if (AuthService.isAuthenticated()) {
                PlaySessionService.handlePlaySessionResumeBroadcast()

                SyncCoordinator.syncAsync(throttle = 1.minutes) { }

                OfflineModeService.downloadAlwaysOfflineTracks()
            }
        } else {
            GGLog.logInfo("GorillaGroove is now invisible")
        }
    }
}

private fun setupOtherRandomNonsense() {
    setInterval(1.hours) {
        if (AuthService.isAuthenticated()) {
            SyncCoordinator.syncAsync(throttle = 30.minutes) { }
        }
    }
}

val mainScope = MainScope()
